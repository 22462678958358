import React from 'react';
import TextFieldInput from '../universal/InputTypeText/TextField';

export const EditUrl = ({ img, placeholder, name, value, onChange, helperText, className, ...props }) => {
  return (
    <div className="soical-sharing">
      <div className="soical-icons">
        <img src={img} alt="" />
      </div>
      <div className="text-field">
        <TextFieldInput
          type="text"
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          className={className || ''}
          helperText={helperText}
          {...props}
          // value={userDetails?.user?.profile_url}
        />
      </div>
    </div>
  );
};
