import { formatDate } from '../../hooks/formatDate';
import { MsgPreviewBoxSkeleton } from '../Skeleton/MsgPreviewBoxSkeleton';
import MsgPreviewBox from '../universal/MsgPreviewBox';
import MsgForm from './MsgForm';

const MsgPreview = ({ isLoading, data, getSellerMessages, updateLatestMessage }) => {
  return (
    <>
      <div className="messagesbox" id="msg-preview-child">
        {data?.messages?.map((msg, i) => {
          return !isLoading ? (
            <MsgPreviewBox
              senderId={msg?.i_sender_id}
              senderName={msg?.sender?.v_fname}
              senderDescription={msg?.l_message}
              // sendTime={moment(msg?.created_at).format('HH:mm')}
              // sendDate={moment(msg?.created_at).format('DD/MM/YY')}
              sendTime={formatDate(msg?.created_at, null, 'HH:mm')}
              sendDate={formatDate(msg?.created_at)}
              key={msg?.id}
            />
          ) : (
            <MsgPreviewBoxSkeleton key={i} />
          );
        })}
      </div>
      <MsgForm data={data} getSellerMessages={getSellerMessages} updateLatestMessage={updateLatestMessage} />
    </>
  );
};

export default MsgPreview;
