const navigator = (searchParams, key, value) => {
  const searchUrl = Object.keys(searchParams)
    .map((keys) => `${keys}=${searchParams[keys]}`)
    .join('&');

  return searchParams[key]
    ? searchUrl.replace(`${key}=${searchParams[key]}`, `${key}=${value}`)
    : `${searchUrl}${searchUrl ? '&' : ''}${key}=${value}`;
};

export default navigator;
