import { useEffect, useState } from 'react';
import { Grid, Slider, Stack, TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import DarkBtn from '../../components/universal/Buttons/DarkBtn';
import { request } from '../../services/request';
import { SimpleSelectBox } from '../../components/InputTypeText/SelectBox';
import { SimpleSelectBoxNew } from '../../components/InputTypeText/SelectBoxNew';

import { userDetailTool } from '../../redux/actions/user';
import notify from '../../utils/notify';

const PricingTool = () => {
  const navigate = useNavigate();
  const { subscriptionDetails } = useSelector((state) => state?.subscription);

  const dispatch = useDispatch();
  const plan = subscriptionDetails?.active_plan;
  const [pricing, SetPricing] = useState([]);
  const { userDetails } = useSelector((state) => state.user);
  const [min, setMin] = useState(null);
  const [max, setMax] = useState(null);
  const [customEducationLevel, setCustomEducationLevel] = useState([]);

  const [selectedValues, setSelectedValues] = useState({
    size: 0,
    hoursToMake: 0,
    editionType: 0,
    editionSize: 0,
    mainMedium: 0,
  });

  const [personalDetail, setPersonalDetail] = useState({
    salesPerYear: 0,
    educationLevel: 0,
    exhibitions: 0,
    exhibitionsAt: 0,
    ftpthobby: 0,
  });

  const [errors, setErrors] = useState({
    size: false,
    salesPerYear: false,
    educationLevel: false,
    exhibitionsAt: false,
    hoursToMake: false,
    editionType: false,
    editionSize: false,
    mainMedium: false,
    ftpthobby: false,
    exhibitions: false,
  });

  const handlePersonalChange = (name, value) => {
    setPersonalDetail((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSelectChange = (name, value) => {
    setSelectedValues((prevState) => ({ ...prevState, [name]: value }));
  };

  useEffect(() => {
    // Update selectedValues when reduxData changes
    if (userDetails && userDetails?.userTool) {
      setPersonalDetail((prevState) => ({
        ...prevState,
        ftpthobby: userDetails.userTool.ftpthobby,
        salesPerYear: userDetails.userTool.salesPerYear,
        exhibitionsAt: userDetails.userTool.exhibitionsAt,
        educationLevel: userDetails.userTool.educationLevel,
        exhibitions: userDetails.userTool.exhibitions,
      }));
    }
  }, [userDetails?.userTool]);
  const getPricing = async (data) => {
    try {
      const res = await request({
        url: '/artist/getCalcValues',
        method: 'GET',
        data,
        token: userDetails?.token,
      });
      SetPricing(res?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPricing();
  }, []);

  const handleCalculation = () => {
    let hasError = false;
    const newErrors = {};

    // Check if any required field is empty
    if (!selectedValues.size) {
      newErrors.size = true;
      hasError = true;
    }
    if (!selectedValues.hoursToMake) {
      newErrors.hoursToMake = true;
      hasError = true;
    }
    if (!selectedValues.editionType) {
      newErrors.editionType = true;
      hasError = true;
    }
    if (!selectedValues.editionSize) {
      newErrors.editionSize = true;
      hasError = true;
    }
    if (!selectedValues.mainMedium) {
      newErrors.mainMedium = true;
      hasError = true;
    }

    if (!personalDetail.salesPerYear) {
      newErrors.salesPerYear = true;
      hasError = true;
    }
    if (!personalDetail.educationLevel) {
      newErrors.educationLevel = true;
      hasError = true;
    }
    if (!personalDetail.exhibitions) {
      newErrors.exhibitions = true;
      hasError = true;
    }
    if (!personalDetail.exhibitionsAt) {
      newErrors.exhibitionsAt = true;
      hasError = true;
    }
    if (!personalDetail.ftpthobby) {
      newErrors.ftpthobby = true;
      hasError = true;
    }

    // Update errors state with new error values
    setErrors(newErrors);

    // If there are errors, return and do not proceed with calculation
    if (hasError) {
      return;
    }

    // If no errors, proceed with the calculation
    const { salesPerYear, educationLevel, exhibitions, exhibitionsAt, ftpthobby } = personalDetail;
    const { editionType, hoursToMake, editionSize, mainMedium, size } = selectedValues;

    const editionTypeData = editionType?.f_label_value;
    const hoursToMakeData = hoursToMake?.f_label_value;
    const editionSizeData = editionSize?.f_label_value;
    const mainMediumData = mainMedium?.f_label_value;
    const sizeData = size?.f_label_value;

    const calculation =
      sizeData *
      educationLevel *
      hoursToMakeData *
      salesPerYear *
      editionTypeData *
      editionSizeData *
      mainMediumData *
      exhibitions *
      ftpthobby *
      exhibitionsAt;

    // Do something with the calculation result
    const Minvalue = calculation * 0.9;
    const Maxvalue = Minvalue * 1.2;

    const MinroundedValue = Math.round(Minvalue / 25) * 25;
    const MaXroundedValue = Math.round(Maxvalue / 25) * 25;

    setMax(MaXroundedValue);
    setMin(MinroundedValue);
  };

  const saveDetail = () => {
    const { salesPerYear, educationLevel, exhibitions, exhibitionsAt, ftpthobby } = personalDetail;

    const data = {
      ftpthobby,
      exhibitions,
      salesPerYear,
      exhibitionsAt,
      educationLevel,
    };
    if (salesPerYear && educationLevel && exhibitions && exhibitionsAt && ftpthobby) {
      dispatch(userDetailTool(data));
      notify('Personal details saved successfully', 'success');
    }
  };

  useEffect(() => {
    if (selectedValues?.editionType?.f_label_value === 1.3) {
      setCustomEducationLevel([pricing?.edition_size?.values?.[0]]);
    } else if (selectedValues?.editionType?.f_label_value === 1.1) {
      setCustomEducationLevel(pricing?.edition_size?.values);
    } else if (selectedValues?.editionType?.f_label_value === 0.75) {
      setCustomEducationLevel([pricing?.edition_size?.values?.[9]]);
    } else {
      setCustomEducationLevel(pricing?.edition_size?.values?.length ? pricing?.edition_size?.values : []);
    }
  }, [selectedValues?.editionType]);
  return (
    <div className="pricing-tool-section">
      <Stack
        direction={{ xs: 'row', sm: 'row' }}
        alignItems="center"
        justifyContent="space-between"
        className="my-account-new"
      >
        <h2 className="font25px maintitle">Artwork Pricing Calculator</h2>
      </Stack>

      <div className="profilesection">
        <Typography variant="body1" className="font20pxtypo" sx={{ mb: { xs: 1, sm: 3 } }}>
          Tell Us About Yourself...
        </Typography>
        <Grid container spacing={2} sx={{ alignItems: 'center' }} className="grid_property_hide">
          <Grid xs={12} sm={3} sx={{ mb: { xs: 0, sm: 3 } }}>
            <Typography variant="body1" className="font20pxtypo colorhead">
              Are You a...
            </Typography>
          </Grid>
          <Grid xs={12} sm={8} sx={{ mb: { xs: 2, sm: 3 } }}>
            <div className="nameemailsection">
              <SimpleSelectBox
                options={pricing?.ft_pt_hobby?.values}
                defaultOption="  Are You a..."
                defaultValue={0}
                value={personalDetail?.ftpthobby}
                onChange={(e) => {
                  handlePersonalChange('ftpthobby', e.target.value);
                  // seterror(e.target.value === 0);
                }}
                error={errors?.ftpthobby}
                helperText={personalDetail?.ftpthobby === 0 ? 'Please select a Are You a... ' : ''}
              />
            </div>
          </Grid>
          <Grid xs={12} sm={3} sx={{ mb: { xs: 1, sm: 3 } }}>
            <Typography variant="body1" className="font20pxtypo colorhead">
              Years Practising:
            </Typography>
          </Grid>
          <Grid xs={12} sm={8} sx={{ mb: { xs: 1, sm: 3 } }}>
            <div className="nameemailsection">
              <SimpleSelectBox
                options={pricing?.years_practicing?.values}
                defaultOption="  Years Practising"
                defaultValue={0}
                value={personalDetail?.exhibitions}
                onChange={(e) => {
                  handlePersonalChange('exhibitions', e.target.value);
                  // seterror(e.target.value === 0);
                }}
                error={errors?.exhibitions}
                helperText={personalDetail?.exhibitions === 0 ? 'Please select a Years Practising ' : ''}
              />
            </div>
          </Grid>
          <Grid xs={12} sm={3} sx={{ mb: { xs: 1, sm: 3 } }}>
            <Typography variant="body1" className="font20pxtypo colorhead">
              Number of Sales Per Year:
            </Typography>
          </Grid>
          <Grid xs={12} sm={8} sx={{ mb: { xs: 1, sm: 3 } }}>
            <div className="nameemailsection">
              <SimpleSelectBox
                options={pricing?.salesyr?.values}
                defaultOption="  Number of Sales Per Year"
                defaultValue={0}
                value={personalDetail?.salesPerYear}
                onChange={(e) => {
                  handlePersonalChange('salesPerYear', e.target.value);
                  // seterror(e.target.value === 0);
                }}
                error={errors?.salesPerYear}
                helperText={personalDetail?.salesPerYear === 0 ? 'Please select a Number of Sales Per Year ' : ''}
              />
            </div>
          </Grid>
          <Grid xs={12} sm={3} sx={{ mb: { xs: 1, sm: 3.5 } }}>
            <Typography variant="body1" className="font20pxtypo colorhead">
              Exhibition History:
            </Typography>
          </Grid>
          <Grid xs={12} sm={8} sx={{ mb: { xs: 1, sm: 3.5 } }}>
            <div className="nameemailsection">
              <SimpleSelectBox
                options={pricing?.exhibitions?.values}
                defaultOption=" Exhibition History"
                defaultValue={0}
                value={personalDetail?.exhibitionsAt}
                onChange={(e) => {
                  handlePersonalChange('exhibitionsAt', e.target.value);
                  // seterror(e.target.value === 0);
                }}
                error={errors?.exhibitionsAt}
                helperText={personalDetail?.exhibitionsAt === 0 ? 'Please select a Exhibition History ' : ''}
              />
            </div>
          </Grid>
          <Grid xs={12} sm={3} sx={{ mb: { xs: 1, sm: 3 } }}>
            <Typography variant="body1" className="font20pxtypo colorhead">
              Fine Art Education Level:
            </Typography>
          </Grid>
          <Grid xs={12} sm={8} sx={{ mb: { xs: 1, sm: 3 } }}>
            <div className="nameemailsection">
              <SimpleSelectBox
                options={pricing?.education_level?.values}
                defaultOption="  Fine Art Education Level"
                defaultValue={0}
                value={personalDetail?.educationLevel}
                onChange={(e) => {
                  handlePersonalChange('educationLevel', e.target.value);
                  // seterror(e.target.value === 0);
                }}
                error={errors?.educationLevel}
                helperText={personalDetail?.educationLevel === 0 ? 'Please select a Fine Art Education Level ' : ''}
              />
            </div>
          </Grid>
          <Grid xs={12} sm={3} sx={{ mb: { xs: 2, sm: 3 } }} className="mobilehide" />

          <Grid xs={12} sm={8} sx={{ mb: { xs: 3, sm: 3 } }} className="btnlast-section">
            {plan?.v_pricing_calculator !== 'No' ? (
              <DarkBtn title="Save Personal Details" onClick={saveDetail} />
            ) : (
              <DarkBtn
                title="Upgrade plan"
                onClick={() => {
                  navigate('/my-subscription');
                }}
              />
            )}
          </Grid>
        </Grid>
      </div>

      <div className="profilesection">
        <Typography variant="body1" className="font20pxtypo" sx={{ mb: { xs: 1, sm: 3 } }}>
          Tell Us About Your Artwork...
        </Typography>
        <Grid container spacing={2} sx={{ alignItems: 'center' }} className="grid_property_hide">
          <Grid xs={12} sm={3} sx={{ mb: { xs: 1, sm: 3 } }}>
            <Typography variant="body1" className="font20pxtypo colorhead">
              Size of Artwork:
            </Typography>
          </Grid>
          <Grid xs={12} sm={8} sx={{ mb: { xs: 1, sm: 3 } }}>
            <div className="nameemailsection">
              <SimpleSelectBoxNew
                options={pricing?.size_cmsq?.values}
                defaultOption="  Size of Artwork"
                defaultValue={0}
                value={selectedValues?.size}
                onChange={(e) => {
                  handleSelectChange('size', e.target.value);
                  // seterror(e.target.value === 0);
                }}
                error={errors?.size}
                helperText={selectedValues?.size === 0 ? 'Please select a  Size of Artwork' : ''}
              />
            </div>
          </Grid>
          <Grid xs={12} sm={3} sx={{ mb: { xs: 1, sm: 3 } }}>
            <Typography variant="body1" className="font20pxtypo colorhead">
              Hours to Make:
            </Typography>
          </Grid>
          <Grid xs={12} sm={8} sx={{ mb: { xs: 1, sm: 3 } }}>
            <div className="nameemailsection">
              <SimpleSelectBoxNew
                options={pricing?.hours_to_make?.values}
                defaultOption="  Hours to Make"
                defaultValue={0}
                value={selectedValues?.hoursToMake}
                onChange={(e) => {
                  handleSelectChange('hoursToMake', e.target.value);
                  // seterror(e.target.value === 0);
                }}
                error={errors?.hoursToMake}
                helperText={selectedValues?.hoursToMake === 0 ? 'Please select a Hours to Make ' : ''}
              />
            </div>
          </Grid>
          <Grid xs={12} sm={3} sx={{ mb: 3.5 }}>
            <Typography variant="body1" className="font20pxtypo colorhead">
              Edition Type:
            </Typography>
          </Grid>
          <Grid xs={12} sm={8} sx={{ mb: { xs: 1, sm: 3.5 } }}>
            <div className="nameemailsection">
              <SimpleSelectBoxNew
                options={pricing?.edition_type?.values}
                defaultOption="  Edition Type"
                defaultValue={0}
                value={selectedValues?.editionType}
                onChange={(e) => {
                  handleSelectChange('editionType', e.target.value);
                  // seterror(e.target.value === 0);
                }}
                error={errors?.editionType}
                helperText={selectedValues?.editionType === 0 ? 'Please select a Edition Type ' : ''}
              />
            </div>
          </Grid>
          <Grid xs={12} sm={3} sx={{ mb: { xs: 1, sm: 3 } }}>
            <Typography variant="body1" className="font20pxtypo colorhead">
              Edition Size:
            </Typography>
          </Grid>
          <Grid xs={12} sm={8} sx={{ mb: { xs: 1, sm: 3 } }}>
            <div className="nameemailsection">
              <SimpleSelectBoxNew
                options={customEducationLevel}
                defaultOption="  Edition Size"
                defaultValue={0}
                value={selectedValues?.editionSize}
                onChange={(e) => {
                  handleSelectChange('editionSize', e.target.value);
                  // seterror(e.target.value === 0);
                }}
                error={errors?.editionSize}
                helperText={selectedValues?.editionSize === 0 ? 'Please select a Edition Size ' : ''}
              />
            </div>
          </Grid>

          <Grid xs={12} sm={3} sx={{ mb: { xs: 1, sm: 3 } }}>
            <Typography variant="body1" className="font20pxtypo colorhead">
              Main Medium:
            </Typography>
          </Grid>
          <Grid xs={12} sm={8} sx={{ mb: { xs: 1, sm: 3 } }}>
            <div className="nameemailsection">
              <SimpleSelectBoxNew
                options={pricing?.medium?.values}
                defaultOption="  Main Medium"
                defaultValue={0}
                value={selectedValues?.mainMedium}
                onChange={(e) => {
                  handleSelectChange('mainMedium', e.target.value);
                  // seterror(e.target.value === 0);
                }}
                error={errors?.mainMedium}
                helperText={selectedValues?.mainMedium === 0 ? 'Please select a Main Medium ' : ''}
              />
            </div>
          </Grid>
          <Grid xs={12} sm={3} sx={{ mb: { xs: 1, sm: 3 } }} className="mobilehide" />

          <Grid xs={12} sm={8} sx={{ mb: { xs: 3, sm: 3 } }} className="btnlast-section">
            {plan?.v_pricing_calculator !== 'No' ? (
              <DarkBtn title="Calculate" type="submit" onClick={handleCalculation} />
            ) : (
              <DarkBtn
                title="Upgrade plan"
                onClick={() => {
                  navigate('/my-subscription');
                }}
              />
            )}
          </Grid>
        </Grid>
      </div>

      <div className="profilesection">
        <Typography
          variant="body1"
          className="font20pxtypo lastpara_text"
          sx={{ mb: { xs: 2, sm: 3 }, textAlign: 'center' }}
        >
          Based on what you’ve told us we believe that a fair price range for your work would be...
        </Typography>
        <Grid container spacing={2} sx={{ alignItems: 'center' }} className="grid_property_hide">
          <Grid item xs={12} sm={1.5} sx={{ mb: { xs: 0, sm: 3 } }}>
            <Typography variant="body1" className="font30pxtypo colorheadblack">
              Min:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={10} sx={{ mb: { xs: 1, sm: 3 } }}>
            <div className="max-min-text">
              {min !== null ? (
                <Typography variant="h4" className="35px">
                  £{min !== 0 && min > 50 ? min : 35}
                </Typography>
              ) : (
                <Typography variant="h4" className="35px">
                  £0
                </Typography>
              )}
            </div>
          </Grid>
          <Grid item xs={12} sm={1.5} sx={{ mb: { xs: 0, sm: 3 } }}>
            <Typography variant="body1" className="font30pxtypo colorheadblack">
              Max:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={10} sx={{ mb: { xs: 4, sm: 3 } }}>
            <div className="max-min-text">
              {max !== null ? (
                <Typography variant="h4" className="35px">
                  £{max !== 0 && max > 85 ? max : 85}
                </Typography>
              ) : (
                <Typography variant="h4" className="35px">
                  £0
                </Typography>
              )}
            </div>
          </Grid>
        </Grid>
        <Typography variant="body2" className="font14px lastpara_text" sx={{ textAlign: 'center' }}>
          Remember, this is just a guide. Your work may be worth significantly more, or less than this. We’d always
          encourage artists to sell their work for as much as they’re able to. However, our data suggests that this
          would be a good starting point for you if you are currently unsure of how to price your work.
        </Typography>
      </div>
    </div>
  );
};

export default PricingTool;
