import { TextField } from '@mui/material';

const InputTypeEmail = ({ placeholder, name, value, type, onChange, onBlur, error, helperText }) => {
  return (
    <div>
      <TextField
        id=""
        placeholder={placeholder || 'Placeholder'}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        error={error}
        type={type || 'text'}
      />
      {error && <div className="font12px error_message">{helperText}</div>}
    </div>
  );
};

export default InputTypeEmail;
