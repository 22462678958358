'use client';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export const SimpleSelectBox = ({
  options,
  onChange,
  value,
  defaultValue,
  defaultOption,
  error,
  helperText,
  className,
  ...props
}) => {
  return (
    <div className="selectbox">
      <FormControl sx={{ m: 1, minWidth: 80 }} className={`selectboxwidth ${className}`}>
        {/* <InputLabel id="demo-simple-select-autowidth-label">Location</InputLabel> */}
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={value}
          onChange={onChange}
          // autoWidth
          // label="Location"
          className="select-field"
          {...props}
        >
          {defaultOption && (
            <MenuItem selected value={defaultValue} disabled>
              {defaultOption}
            </MenuItem>
          )}
          {options?.map((option, index) => {
            return (
              <MenuItem key={index} value={option?.f_label_value ? option?.f_label_value : option?.id}>
                {option?.name ? option?.name : option?.v_label_name}
              </MenuItem>
            );
          })}
        </Select>
        {error && <div className="error_text">{helperText}</div>}
      </FormControl>
    </div>
  );
};
