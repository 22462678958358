import { Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { CardContainer } from '../universal/CardContainer';
import TextFieldInput from '../universal/InputTypeText/TextField';
import TextAreaBox from '../universal/InputTypeText/TextareaBox';
import TextFieldInputLabel from '../universal/InputTypeText/TextFieldLabel';
import TextAreaBoxLabel from '../universal/InputTypeText/TextareaBoxLabel';

export const AddArtworkMeta = ({ setArtworkMeta, artworkMetaRef, fetchedData, setGoodToGo }) => {
  const initialValues = {
    metaTitle: '',
    metaDescription: '',
    metaKeywords: '',
  };

  const validationSchema = Yup.object().shape({
    metaTitle: Yup.string().nullable(),
    metaDescription: Yup.string().nullable(),
    metaKeywords: Yup.string().nullable(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      setGoodToGo((prev) => ({ ...prev, meta: true }));
      setArtworkMeta(values);
    },
  });

  useEffect(() => {
    if (fetchedData?.artworkMeta) {
      const data = {
        metaTitle: fetchedData?.artworkMeta?.metaTitle,
        metaDescription: fetchedData?.artworkMeta?.metaDescription,
        metaKeywords: fetchedData?.artworkMeta?.metaKeywords,
      };
      formik.setValues(data);
      setArtworkMeta(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedData]);

  return (
    <CardContainer className="addartworkdescription">
      <form onSubmit={formik.handleSubmit} id="artworkDesc">
        <Typography paragraph sx={{ mb: 1.2 }}>
          Meta Details
        </Typography>
        <TextFieldInputLabel
          name="metaTitle"
          placeholder="Artwork Meta Title"
          value={formik?.values?.metaTitle}
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          error={formik?.touched?.metaTitle && Boolean(formik?.errors?.metaTitle)}
          helperText={formik?.touched?.metaTitle && formik?.errors?.metaTitle}
        />
        <TextFieldInputLabel
          name="metaKeywords"
          placeholder="Artwork Meta Keywords"
          value={formik?.values?.metaKeywords}
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          error={formik?.touched?.metaKeywords && Boolean(formik?.errors?.metaKeywords)}
          helperText={formik?.touched?.metaKeywords && formik?.errors?.metaKeywords}
        />
        <TextAreaBoxLabel
          name="metaDescription"
          placeholder="Meta Description"
          value={formik?.values?.metaDescription}
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          error={formik?.touched?.metaDescription && formik?.errors?.metaDescription}
        />
        <input hidden type="submit" ref={artworkMetaRef} />
      </form>
    </CardContainer>
  );
};
