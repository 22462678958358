import { DELETE_USER, SET_USER, UPDATE_USER, UPDATE_USER_DETAIL } from '../constant';

export const setUser = (data) => {
  return {
    type: SET_USER,
    data,
  };
};

export const updateUser = (data) => {
  return {
    type: UPDATE_USER,
    data,
  };
};

export const userDetailTool = (data) => {
  return {
    type: UPDATE_USER_DETAIL,
    data,
  };
};

export const deleteUser = () => {
  return {
    type: DELETE_USER,
  };
};
