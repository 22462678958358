import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { Container } from '@mui/system';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import AddCardDailog from '../../components/AddCard';
import TextFieldInput from '../../components/InputTypeText/TextField';
import PaymentMethodStripForm from '../../components/PaymentStripForm';
import { SavedAddressSkeleton } from '../../components/Skeleton/SavedAddressSkeleton';
import { BasicModal } from '../../components/subscription/authpaymodal';
import DarkBtn from '../../components/universal/Buttons/DarkBtn';
import WhiteBtn from '../../components/universal/Buttons/whiteBtn';
import { CardContainer } from '../../components/universal/CardContainer';
import { Loader } from '../../components/universal/Loader';
import { cardTypeImage } from '../../hooks/cardtypes';
import { openWindow } from '../../hooks/popupFrame';
import { setSubscription } from '../../redux/actions/subscription';
import AxiosAll from '../../services/axiosAll';
import notify from '../../utils/notify';
import { globalWindow } from '../../core/constant';

const Checkout = () => {
  const dispatch = useDispatch();
  const [userStatus, setUserStatus] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenurl, setIsModalOpenurl] = useState('');

  const [loadingDetails, setLoadingDetails] = useState(false);
  const [creditBalance, setCreditBalance] = useState(0);
  const [userPaymentToken, setuserPaymentToken] = useState('');
  const [CardList, setCardList] = useState([]);
  const navigate = useNavigate();
  const { userDetails } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const subscriptionPlan = queryParams.get('subscription-plan');
  const price = queryParams.get('price');
  const name = queryParams.get('name');
  const type = queryParams.get('type');
  const offerId = queryParams.get('offer');
  const [total, settotal] = useState(price);
  const [Discount, setDiscount] = useState(0);
  const [DiscountLoading, setDiscountLoading] = useState(false);
  const [isCardListUpdated, setIsCardListUpdated] = useState(false);
  const [CardLoading, setCardLoading] = useState(false);
  const initialValues = {
    coupen_code: '',
  };

  const validationSchema = Yup.object({
    coupen_code: Yup.string().required('Coupon code is required').trim(),
  });

  const onSubmit = async (values) => {
    if (total === 0) {
      notify('Coupon code cannot be used with a 0 credit', 'error');
      return;
    }
    const data = {
      coupen_code: values?.coupen_code.trim(),
    };
    ApplyCode(values?.coupen_code.trim());
  };

  const ApplyCode = async (values) => {
    setDiscountLoading(true);
    const res = await AxiosAll(
      'get',
      `artist/verifyCoupenCode?coupen_code=${values}&plan_id=${subscriptionPlan}`,
      values,
      userDetails.token,
    );
    if (res?.data?.statusCode === 200) {
      notify(res?.data?.message, 'success');
      applyDiscount(res?.data?.data?.discount_type, res?.data?.data?.v_value, total);
      // setDiscount(res?.data);
      setDiscountLoading(false);
    } else {
      // notify('You have entered an incorrect Email or Password', 'error');
      notify(res?.data?.message, 'error');
      formik.resetForm();
      setDiscountLoading(false);
    }
  };

  const applyDiscount = (discountType, value, subtotal) => {
    let discount = 0;

    switch (discountType) {
      case 'percent':
        discount = (value / 100) * subtotal;
        break;
      case 'flat':
        discount = value;
        break;
      case 'credit':
        discount = subtotal > value ? value : subtotal;
        break;
      default:
        return subtotal;
    }
    discountType !== 'credit' && setDiscount(discount);
    const totalamount = subtotal - discount;
    return settotal(totalamount);
  };

  const removeDiscount = () => {
    setDiscount(0);
    formik.resetForm();
    creditBalance > 0 ? applyDiscount('credit', creditBalance, price) : settotal(price);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const addCard = async (data) => {
    setLoadingDetails(true);
    try {
      const res = await AxiosAll('post', 'artist/paymentMethods', data, userDetails.token);
      notify(res?.data?.message, 'success');
      getCards();
      setLoadingDetails(false);
    } catch (error) {
      notify(error?.message, 'success');
      setLoadingDetails(false);
    }
  };

  const getCreditBalanceLog = async () => {
    try {
      const res = await AxiosAll(
        'get',
        `artist/myCreditBalanceLog?param[0]=credit_total_balance`,
        null,
        userDetails?.token,
      );
      if (res?.data?.statusCode === 200) {
        const totalCredit = Math.abs(res?.data?.data?.credit_total_balance);
        setCreditBalance(totalCredit);
        applyDiscount('credit', totalCredit, price);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCards = async () => {
    setLoading(true);
    try {
      const res = await AxiosAll('get', 'artist/paymentMethods', '', userDetails.token);
      const data = res?.data?.data;
      setCardList(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsModalOpenurl('');
    // navigate('/my-subscription');
  };

  const getIsCardListUpdated = (val) => {
    setIsCardListUpdated(!val);
  };

  useEffect(() => {
    getCreditBalanceLog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCardListUpdated]);
  React.useEffect(() => {
    if (userPaymentToken && userPaymentToken !== '') {
      addCard({ payment_method_id: userPaymentToken });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPaymentToken]);

  const PurchaseSubscription = async (id) => {
    setLoadingDetails(true);

    try {
      const res = await AxiosAll(
        'post',
        'artist/customerSubscriptions',
        {
          plan_id: id,
          coupen_code: formik?.values?.coupen_code,
          special_offer_id: offerId || 0,
          redirect_url: globalWindow?.location?.origin,
          // globalWindow?.location?.origin
        },
        userDetails.token,
      );
      if (res?.data?.statusCode === 200) {
        notify(res?.data?.message, 'success');
        setLoadingDetails(false);
        if (res?.data?.data['3dStatus'] && res?.data?.data['3DSecureURL']) {
          window?.open(res?.data?.data['3DSecureURL'], '_self');
        } else {
          // notify(res?.data?.message, 'success');
          setLoadingDetails(false);
          getCurrentSubscription();
          navigate('/paymentStatus?status=success');
        }
      } else {
        notify(res?.data?.message, 'error');
        setLoadingDetails(false);
        navigate('/paymentStatus?status=failure');
      }
    } catch (error) {
      setLoadingDetails(false);
    }
  };

  const handelPopupLoad = () => {
    getCurrentSubscription();
    // navigate('/my-account/subscription-history');
  };

  const getCurrentSubscription = async () => {
    const res = await AxiosAll('GET', 'artist/artist_current_active_subscription_plan_detail', null, userDetails.token);
    if (res?.data?.statusCode === 200) {
      dispatch(setSubscription(res?.data?.data));
      // navigate('/my-account/subscription-history');
      setLoadingDetails(false);
    }
  };

  const makeDefault = async (id) => {
    setCardLoading(true);
    try {
      const res = await AxiosAll('put', `artist/paymentMethods/${id}?`, { is_default: 1 }, userDetails.token);
      notify(res.data.message, 'success');
      getCards();
      setCardLoading(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      setCardLoading(false);
    }
  };

  const calculateCreditBalance = (amount) => {
    if (creditBalance > amount) {
      return amount?.toFixed(2);
    }
    return creditBalance?.toFixed(2);
  };

  return (
    <Container className="main-checkout-page">
      <div className="shippingaddress">
        <div className="min-width">
          <Typography variant="h4" className="mainheading">
            Checkout
          </Typography>
          <Loader
            loading={loadingDetails}
            color="#f5445e"
            message="Confirming your Payment <br> Please do no close or refresh the window"
          />
          <CardContainer className="addressfrom card-checkout">
            <Typography variant="h5" className="heading" style={{ textTransform: 'capitalize' }}>
              Subscribe to {type}ly Plan
            </Typography>
            <Typography variant="h5" className="price">
              £{parseFloat(price)?.toFixed(2)} <span className="small-text">per {type}</span>
            </Typography>
            <Typography variant="h4" className="plan-details" style={{ textTransform: 'capitalize' }}>
              {type}ly plan - {name}
            </Typography>
            <div className="coupan">
              <Typography variant="h5" className="coupan-text">
                {Discount ? 'Applied Coupon' : ' Apply Coupon here'}
              </Typography>
              <form onSubmit={formik?.handleSubmit} className="inputform">
                <TextFieldInput
                  placeholder="Coupon Code"
                  name="coupen_code"
                  id="coupen_code"
                  disabled={Discount}
                  value={formik.values.coupen_code}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.coupen_code && Boolean(formik.errors.coupen_code)}
                  helperText={formik.touched.coupen_code && formik.errors.coupen_code}
                />
                {Discount ? (
                  <WhiteBtn onClick={removeDiscount} loading={DiscountLoading} title="Remove" type="button" />
                ) : (
                  <DarkBtn loading={DiscountLoading} title="Apply" type="submit" />
                )}
              </form>
            </div>
            <div className="cardtaotal">
              <div className="quote">
                <Typography>Subtotal </Typography>
                <Typography>£ {parseFloat(price)?.toFixed(2)}</Typography>
              </div>
              <div className="quote discount">
                <Typography>Discount </Typography>
                <Typography>£ {parseFloat(Discount)?.toFixed(2)}</Typography>
              </div>
              <div className="quote discount">
                <Typography>Credit {creditBalance > 0 ? 'Applied' : 'Balance'}</Typography>
                <Typography>£ {calculateCreditBalance(parseFloat(price))}</Typography>
              </div>
              <div className="quote total" style={{ borderBottom: 'none' }}>
                <Typography>Total </Typography>
                <Typography>£ {parseFloat(total)?.toFixed(2)}</Typography>
              </div>
            </div>
            <Typography paragraph className="billstart">
              Your billing starts today
            </Typography>
            <BasicModal open={isModalOpen} setOpen={handleCloseModal} url={isModalOpenurl} />
            <div className="saved-card">
              <div className="details">
                {loading ? (
                  <SavedAddressSkeleton />
                ) : CardList?.length > 0 ? (
                  <>
                    <AddCardDailog buttonType="dark" getIsCardListUpdated={getIsCardListUpdated} />
                    {CardList?.map((card, index) => {
                      return (
                        <CardContainer bottomMargin="0" topMargin="0" key={index} className="bottomMargin">
                          <div className="address-details">
                            <div className="address-text">
                              <FormControl>
                                <RadioGroup>
                                  <FormControlLabel
                                    onClick={() => makeDefault(card?.id)}
                                    value={card?.id}
                                    control={<Radio />}
                                    checked={card?.is_default}
                                  />
                                </RadioGroup>
                              </FormControl>
                              {cardTypeImage(card?.v_card_brand, 30, 60)}
                              <Typography variant="body1" className="font14px">
                                **** **** **** {card?.v_card_last4}
                              </Typography>
                            </div>
                          </div>
                        </CardContainer>
                      );
                    })}
                    <div className="skip">
                      <DarkBtn
                        title={loadingDetails ? 'loading' : 'Subscribe and Pay'}
                        onClick={() => PurchaseSubscription(subscriptionPlan)}
                      />
                    </div>
                  </>
                ) : (
                  <div style={{ width: '90%', minHeight: 150 }}>
                    <PaymentMethodStripForm setPayCardID={setuserPaymentToken} buttontext="Add Card and Continue" />
                  </div>
                )}
                <Typography className="tearms">
                  <a href="/terms-and-conditions" className="hyper_link" target="_blank" rel="noopener noreferrer">
                    Terms and Conditions
                  </a>{' '}
                  &{' '}
                  <a href="/privacy-policy" className="hyper_link" target="_blank" rel="noopener noreferrer">
                    Privacy Policy
                  </a>
                </Typography>
                <Typography className="conditions">
                  By confirming your subscription, you grant permission to charge your credit card automatically for
                  this payment and future payments
                </Typography>
              </div>
            </div>
          </CardContainer>
        </div>
      </div>
    </Container>
  );
};

export default Checkout;
