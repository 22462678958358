import { useNavigate } from 'react-router';
import DarkBtn from '../universal/Buttons/DarkBtn';

export const EmptyChannel = () => {
  const isArtist = true;
  const artist = 'artist';
  const artLover = 'artLover';

  const navigate = useNavigate();
  return (
    <div className="empty-container">
      <div className="empty-channel">
        <h3>Start Conversation with the {isArtist ? artist : artLover}</h3>
        {/* <DarkBtn title="start messaging" onClick={() => navigate('/search/artists/all')} /> */}
      </div>
    </div>
  );
};
