import { Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { images } from '../../assets';
import { EditUrl } from '../../components/my-bio/editUrl';
import DarkBtn from '../../components/universal/Buttons/DarkBtn';
import { CardContainer } from '../../components/universal/CardContainer';
import TextAreaBox from '../../components/universal/InputTypeText/TextareaBox';
import { useWindowSize } from '../../hooks/window';
import { MAX_MOBILE_WIDTH } from '../../models/breakpoints.model';
import { updateUser } from '../../redux/actions/user';
import { request } from '../../services/request';
import notify from '../../utils/notify';
import GrayBtn from '../../components/universal/Buttons/GrayBtn';

const AccountBio = () => {
  const { width } = useWindowSize();
  const isMobile = (width || 0) < MAX_MOBILE_WIDTH;

  const validationSchema = Yup.object().shape({
    bio: Yup.string().required('Bio is required').trim(),
    facebookUrl: Yup.string().matches(
      /^https:\/\/(www\.)?facebook\.com\//,
      'Invalid Facebook URL, must start with https://www.facebook.com/',
    ),
    instagramUrl: Yup.string().matches(
      /^https:\/\/(www\.)?instagram\.com\//,
      'Invalid Instagram URL, must start with https://www.instagram.com/',
    ),
    twitterUrl: Yup.string().matches(
      /^https:\/\/(www\.)?twitter\.com\//,
      'Invalid Twitter URL, must start with https://www.twitter.com/',
    ),
    linkedinUrl: Yup.string().matches(
      /^https:\/\/(www\.)?linkedin\.com\//,
      'Invalid LinkedIn URL, must start with https://www.linkedin.com/',
    ),
    tiktokUrl: Yup.string().matches(
      /^https:\/\/(www\.)?tiktok\.com\//,
      'Invalid TikTok URL, must start with https://www.tiktok.com/',
    ),
    pinterestUrl: Yup.string().matches(
      /^https:\/\/(www\.)?pinterest\.com\//,
      'Invalid Pinterest URL, must start with https://www.pinterest.com/',
    ),
    imprimoUrl: Yup.string().matches(
      /^https:\/\/(?:[a-zA-Z0-9]+(?:[-_][a-zA-Z0-9]+)*\.)*imprimo\.ca\//,

      'Invalid Imprimo URL, must start with https://imprimo.ca/ or https://subdomain.imprimo.ca/',
    ),
  });

  const initialValues = {
    bio: '',
    facebookUrl: '',
    instagramUrl: '',
    twitterUrl: '',
    linkedinUrl: '',
    tiktokUrl: '',
    pinterestUrl: '',
    imprimoUrl: '',
  };

  const [profileData, setProfileData] = useState({});

  const dispatch = useDispatch();

  const { userDetails } = useSelector((state) => state.user);

  const updateUserProfile = async (data) => {
    try {
      const res = await request({
        url: '/updateProfile',
        method: 'POST',
        data,
        token: userDetails?.token,
      });

      dispatch(updateUser(res.data));
      notify(res?.message, 'success');
    } catch (error) {
      console.log(error);
    }
  };

  const getUserProfile = async (data) => {
    try {
      const res = await request({
        url: '/getProfile',
        method: 'GET',
        data,
        token: userDetails?.token,
      });
      setProfileData(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async (values) => {
    const formData = new FormData();
    formData.append('l_biography', values.bio);
    formData.append('v_facebook', values.facebookUrl);
    formData.append('v_instagram', values.instagramUrl);
    formData.append('v_twitter', values.twitterUrl);
    formData.append('v_linkedin', values.linkedinUrl);
    formData.append('v_tiktok', values.tiktokUrl);
    formData.append('v_pinterest', values.pinterestUrl);
    formData.append('v_imprimo', values.imprimoUrl);
    updateUserProfile(formData);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    formik?.setValues({
      bio: profileData?.user_info?.l_biography || '',
      facebookUrl: profileData?.user_info?.v_facebook || '',
      instagramUrl: profileData?.user_info?.v_instagram || '',
      twitterUrl: profileData?.user_info?.v_twitter || '',
      linkedinUrl: profileData?.user_info?.v_linkedin || '',
      tiktokUrl: profileData?.user_info?.v_tiktok || '',
      pinterestUrl: profileData?.user_info?.v_pinterest || '',
      imprimoUrl: profileData?.user_info?.v_imprimo || '',
    });
  }, [profileData]);

  useEffect(() => {
    getUserProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="bio-profile">
      <h2 className="mainheadart font40px">My Bio</h2>
      <CardContainer className="bio-edit" paddingX={isMobile && '19px'} rowGap={isMobile && '17px'}>
        <Typography variant="h5">Tell us about yourself...</Typography>
        <TextAreaBox
          name="bio"
          placeholder="Write your bio here..."
          value={formik?.values?.bio}
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          error={formik?.touched?.bio && formik?.errors?.bio}
        />
        {!formik?.values?.bio ? (
          <GrayBtn title="save" disabled />
        ) : (
          <DarkBtn title="save" onClick={formik?.handleSubmit} />
        )}
      </CardContainer>
      <CardContainer className="bio-share" paddingX={isMobile && '19px'} rowGap={isMobile && '17px'}>
        <Typography variant="h5">Social Media Links</Typography>
        <EditUrl
          name="facebookUrl"
          img={images?.facebook}
          value={formik?.values?.facebookUrl}
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          error={formik?.touched?.facebookUrl && Boolean(formik?.errors?.facebookUrl)}
          helperText={formik?.touched?.facebookUrl && formik?.errors?.facebookUrl}
          className={isMobile && formik?.errors?.facebookUrl ? 'margin-bottom' : ''}
          placeholder="Facebook URL (Include https://)"
        />
        <EditUrl
          img={images?.instagram}
          name="instagramUrl"
          value={formik?.values?.instagramUrl}
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          error={formik?.touched?.instagramUrl && Boolean(formik?.errors?.instagramUrl)}
          helperText={formik?.touched?.instagramUrl && formik?.errors?.instagramUrl}
          className={isMobile && formik?.errors?.instagramUrl ? 'margin-bottom' : ''}
          placeholder="Instagram URL (Include https://)"
        />
        <EditUrl
          img={images?.TWITTER}
          name="twitterUrl"
          value={formik?.values?.twitterUrl}
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          error={formik?.touched?.twitterUrl && Boolean(formik?.errors?.twitterUrl)}
          helperText={formik?.touched?.twitterUrl && formik?.errors?.twitterUrl}
          className={isMobile && formik?.errors?.twitterUrl ? 'margin-bottom' : ''}
          placeholder="Twitter URL (Include https://)"
        />
        <EditUrl
          img={images?.linkedin}
          name="linkedinUrl"
          value={formik?.values?.linkedinUrl}
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          error={formik?.touched?.linkedinUrl && Boolean(formik?.errors?.linkedinUrl)}
          helperText={formik?.touched?.linkedinUrl && formik?.errors?.linkedinUrl}
          className={isMobile && formik?.errors?.linkedinUrl ? 'margin-bottom' : ''}
          placeholder="Linkedin URL (Include https://)"
        />
        <EditUrl
          img={images?.tikTok}
          name="tiktokUrl"
          value={formik?.values?.tiktokUrl}
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          error={formik?.touched?.tiktokUrl && Boolean(formik?.errors?.tiktokUrl)}
          helperText={formik?.touched?.tiktokUrl && formik?.errors?.tiktokUrl}
          className={isMobile && formik?.errors?.tiktokUrl ? 'margin-bottom' : ''}
          placeholder="TikTok URL (Include https://)"
        />
        <EditUrl
          img={images?.pinterest}
          name="pinterestUrl"
          value={formik?.values?.pinterestUrl}
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          error={formik?.touched?.pinterestUrl && Boolean(formik?.errors?.pinterestUrl)}
          helperText={formik?.touched?.pinterestUrl && formik?.errors?.pinterestUrl}
          className={isMobile && formik?.errors?.pinterestUrl ? 'margin-bottom' : ''}
          placeholder="Pinterest URL (Include https://)"
        />
        <EditUrl
          img={images?.imprimo}
          name="imprimoUrl"
          value={formik?.values?.imprimoUrl}
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          error={formik?.touched?.imprimoUrl && Boolean(formik?.errors?.imprimoUrl)}
          helperText={formik?.touched?.imprimoUrl && formik?.errors?.imprimoUrl}
          className={isMobile && formik?.errors?.imprimoUrl ? 'margin-bottom' : ''}
          placeholder="Imprimo URL (Include https://)"
        />
        {!formik?.values?.facebookUrl &&
        !formik?.values?.instagramUrl &&
        !formik?.values?.twitterUrl &&
        !formik?.values?.linkedinUrl &&
        !formik?.values?.tiktokUrl &&
        !formik?.values?.pinterestUrl &&
        !formik?.values?.imprimoUrl ? (
          <GrayBtn title="save" disabled />
        ) : (
          <DarkBtn title="save" onClick={formik?.handleSubmit} />
        )}
      </CardContainer>
    </div>
  );
};

export default AccountBio;
