import { Skeleton } from '@mui/material';

export const MsgPreviewBoxSkeleton = () => {
  return (
    <div className="message-preview-box">
      <Skeleton />
      <Skeleton />
    </div>
  );
};
