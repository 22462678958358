import { Button } from '@mui/material';

const GrayBtn = ({ title, onClick, type, className, disabled }) => {
  return (
    <Button
      disabled={disabled}
      className={`graybtn${className ? ` ${className}` : ''}`}
      onClick={onClick}
      type={type || 'button'}
    >
      {title}
    </Button>
  );
};

export default GrayBtn;
