import { ProdServer, stagingServer, preProdServer } from './Sever';

export const envType = (Env) => {
  switch (Env) {
    case 1:
      return stagingServer;

    case 2:
      return preProdServer;

    case 3:
      return ProdServer;

    default:
      return stagingServer;
  }
};
