/* eslint-disable no-param-reassign */
import axios from 'axios';
import currentURL from '../env';

const client = axios.create({
  baseURL: currentURL.currentEnv,
});

export const request = async ({ ...options }) => {
  // const token = store.getState().user.userDetails.token;
  if (options.next) {
    options.headers = {
      ...options.headers,
      ...options.next,
    };
  }

  if (options.token) {
    options.headers = {
      ...options.headers,
      Authorization: `Bearer ${options.token}`,
    };
  }

  const onSuccess = response => {
    // if (response.data.statusCode === 401) {
    //   store.dispatch(deleteGuestUser());
    // }
    return response.data;
  };

  const onError = error => {
    // if (
    //   error.response.status === 401 ||
    //   error.response.data.message === 'Unauthenticated.' ||
    //   error.response.statusText === 'Unauthorized'
    // ) {
    //   store.dispatch(deleteGuestUser());
    // }
    // eslint-disable-next-line no-undef
    return Promise.reject(error.response || error.message);
  };

  return client(options).then(onSuccess).catch(onError);
};
