import { TextField } from '@mui/material';
import { useRef } from 'react';

const TextFieldInputLabel = ({
  placeholder,
  name,
  value,
  type,
  onChange,
  onBlur,
  error,
  helperText,
  className,
  isRequired,
  ...props
}) => {
  const inputRef = useRef(null);

  const handleWheel = (e) => {
    e.preventDefault();
    const inputElement = inputRef.current;
    if (inputElement) {
      inputElement.blur();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault();
    }
  };
  return (
    <div className="textfiledspan">
      <TextField
        id="outlined-basic"
        label={placeholder}
        variant="outlined"
        required={isRequired || false}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        inputRef={inputRef} // Add inputRef to the TextFieldInput component
        onWheel={(e) => handleWheel(e)}
        onKeyDown={(e) => handleKeyDown(e)}
        error={error}
        type={type || 'text'}
        className={`iconoverlap ${className}`}
        {...props}
      />
      {error && <div className="error_text 12">{helperText}</div>}
    </div>
  );
};

export default TextFieldInputLabel;
