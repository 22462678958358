export const SingleMsgCard = ({ border, OnPress, id, img, buyerName, subject, date, time, alt, msg }) => {
  return (
    <div tabIndex={0} role="button" className="message-card" style={border} key={id} onClick={() => OnPress(id)}>
      <div className="profile-img">
        <img src={img} width={42} height={42} alt={alt} />
      </div>
      <div className="buyer-details">
        <div className="buyer">
          <h3>{buyerName}</h3>
          <h3>Subject: {subject}</h3>
        </div>
        <p className="msg-date">
          Last message: {time} {date}
        </p>
      </div>
    </div>
  );
};
