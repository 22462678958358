import { Container, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import DarkBtn from '../../components/universal/Buttons/DarkBtn';
import { globalWindow } from '../../core/constant';
import { setSubscription } from '../../redux/actions/subscription';
import AxiosAll from '../../services/axiosAll';

const PaymentStatus = () => {
  const queryParams = new URLSearchParams(globalWindow.location.search);
  const status = queryParams.get('status');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userDetails } = useSelector((state) => state.user);

  const getCurrentSubscription = async () => {
    const res = await AxiosAll('GET', 'artist/artist_current_active_subscription_plan_detail', null, userDetails.token);
    if (res?.data?.statusCode === 200) {
      dispatch(setSubscription(res?.data?.data));
    }
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (status) {
      getCurrentSubscription();
      // Redirect to "//my-account/subscription-history" after 20 seconds
      const redirectTimer = setTimeout(() => {
        globalWindow.location.href = `${globalWindow.location?.origin}${
          status === 'success' ? '/my-account/subscription-history' : '/my-subscription'
        }`;
      }, 20000); // 20,000 milliseconds = 20 seconds

      // Clear the timer when the component unmounts
      return () => clearTimeout(redirectTimer);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [status]);

  return (
    <div className="retrun-policy">
      <Container>
        <section className="first-section">
          <Typography variant="h2" className="first-heading">
            {status === 'success' ? 'Congratulations!' : 'Oops!'} Your last payment was{' '}
            {status === 'success' ? 'successful' : 'failed'}.
          </Typography>
          <Typography paragraph className="headign-para">
            {status === 'success'
              ? 'You completed your payment process successfully; thank you for selecting this plan.'
              : 'Thank you for choosing Cosimo! If your amount is currently being deducted, you should expect any refunds to be processed within 2-6 business days. We appreciate your understanding. Thanks!'}
          </Typography>

          <DarkBtn
            title={status === 'success' ? 'Back to Plans' : 'Retry'}
            onClick={() => navigate(status === 'success' ? '/my-account/subscription-history' : '/my-subscription')}
          />
        </section>
      </Container>
    </div>
  );
};
export default PaymentStatus;
