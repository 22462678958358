'use client';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { alpha, styled } from '@mui/material/styles';

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { deleteSubscription } from '../../../../redux/actions/subscription';
import { deleteUser } from '../../../../redux/actions/user';
import notify from '../../../../utils/notify';
import { notifyMessages } from '../../../../core/constant';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

const AccountMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { userDetails } = useSelector((state) => state.user);
  const open = Boolean(anchorEl);
  const { pathname } = useLocation();

  const handleClickModel = (event) => {
    if (pathname.includes('personalize') && !userDetails?.user?.is_completed_seller) {
      notify(notifyMessages?.completePersonalization, 'error');
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClose = () => {
    // signOut();
    // dispatch(deleteUser());
    setAnchorEl(null);
  };
  const handleClick = (slug) => {
    if (slug) {
      navigate(`${slug}`);
      setAnchorEl(null);
    } else {
      logOut();
      setAnchorEl(null);
    }
  };
  const logOut = () => {
    navigate('/login');
    dispatch(deleteUser());
    dispatch(deleteSubscription());
    const impersonating = localStorage.getItem('impersonating');
    if (impersonating) {
      localStorage.removeItem('impersonating');
    }
    // dispatch(deleteCart());
    setAnchorEl(null);
  };

  const HeaderAccountMenu = [
    {
      title: 'Profile',
      slug: '/dashboard',
    },
    // {
    //   title: "Artist Area",
    //   slug: "/artist-area",
    // },
    {
      title: 'Settings',
      slug: '/my-account/settings',
    },
    {
      title: 'Contact Us',
      slug: '/contact-us',
    },
    {
      title: ' Log Out',
      slug: '',
    },
  ];

  return (
    <div>
      <Button
        id="demo-customized-button"
        className={`loginbtn ${userDetails?.token ? 'logedinbtn' : ''}`}
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClickModel}
        // endIcon={!userDetails?.user?.v_image && <AccountCircleIcon />}
      >
        My Account
        {userDetails?.user?.v_image ? (
          <div className="circle-avatar">
            <img src={userDetails?.user?.v_image} alt="pic" />
          </div>
        ) : (
          userDetails.token && <AccountCircleIcon />
        )}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {HeaderAccountMenu?.map((option, index) => {
          return (
            <MenuItem
              key={index}
              onClick={() => {
                handleClick(option?.slug);
              }}
              disableRipple
              className="font16px accountmenu-text"
            >
              {option?.title}
            </MenuItem>
          );
        })}
      </StyledMenu>
    </div>
  );
};

export default AccountMenu;
