import { Navigate, Route, Routes } from 'react-router';

import SellerSideBar from '../components/SellerSideBar';
import { AddArtworkPage } from '../pages/add-artwork';
import { AddCollection } from '../pages/add-collection';
import { GenrateCertificate } from '../pages/certificate';
import ContactUs from '../pages/contact-us';

import Dashboard from '../pages/dashboard';
import FAQ from '../pages/faqs';
import AllMessage from '../pages/messages';
import MessageDetails from '../pages/messages/details';

import Checkout from '../pages/checkout';
import CookiePolicy from '../pages/cookie-policy';
import { DiscountPage } from '../pages/discountPage';
import Academy from '../pages/academy';
import Directory from '../pages/directory';
import { InvoicePage } from '../pages/invoice';
import Followers from '../pages/my-artists';
import MyArtworks from '../pages/my-artworks';
import AccountBio from '../pages/my-bio';
import { MyCertificate } from '../pages/my-certificate';
import MyCollections from '../pages/my-collections';
import MyNotifications from '../pages/my-notifications';
import MyOrders from '../pages/my-orders';
import OrderDetails from '../pages/my-orders/order-detail/orderId';
import ReferralLogs from '../pages/my-referral-logs';
import ReferralScheme from '../pages/my-referral-scheme';
import AllReviews from '../pages/my-reviews';
import ReviewDetails from '../pages/my-reviews/reviewId';
import AccountSettings from '../pages/my-settings';
import { MySubscription } from '../pages/my-subscription';
import { Offers } from '../pages/offers';
import PaymentSettings from '../pages/payements';
import PaymentStatus from '../pages/payment-status';
import Personalize from '../pages/personalize';
import PrivacyPolicy from '../pages/privacy-policy';
import ReturnPolicy from '../pages/return-policy';
import SavedCards from '../pages/saved-cards';
import { ShippingAddress } from '../pages/shipping-address';
import SiteMap from '../pages/sitemap';
import SubscriptionHistory from '../pages/subscription_history';
import Terms from '../pages/terms-of-use';
import ViewCertificate from '../pages/view-certificate';
import PricingTool from '../pages/pricing-tool';
import VideoLibrary from '../pages/video-library';
import MeetUp from '../pages/meet-up';
import AcademyNew from '../pages/academynew';

const AppRoutes = () => {
  return (
    <Routes>
      {/* <Route path="onboarding" element={<SellerSideBar />}> */}
      <Route index element={<Navigate to="/dashboard" replace />} />
      {/* <Route path="*" element={<Navigate to="/dashboard" replace />} /> */}
      <Route path="/" element={<SellerSideBar />}>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="dashboard/my-notifications" element={<MyNotifications />} />
        <Route path="my-account/settings" element={<AccountSettings />} />
        <Route path="my-account/subscription-history" element={<SubscriptionHistory />} />
        <Route path="my-account/my-card" element={<SavedCards />} />
        <Route path="my-account/bio" element={<AccountBio />} />
        <Route path="portfolio/artworks" element={<MyArtworks />} />
        <Route path="portfolio/artworks/add-artwork" element={<AddArtworkPage />} />
        <Route path="portfolio/artworks/:id" element={<AddArtworkPage />} />
        <Route path="portfolio/collections" element={<MyCollections />} />
        <Route path="portfolio/collections/add-collection" element={<AddCollection />} />
        <Route path="portfolio/collections/:id" element={<AddCollection />} />
        <Route path="portfolio/certificates" element={<GenrateCertificate />} />
        <Route path="community/messages" element={<AllMessage />} />
        <Route path="community/messages/:id" element={<MessageDetails />} />
        <Route path="community/referral-scheme" element={<ReferralScheme />} />
        <Route path="community/referral-logs" element={<ReferralLogs />} />
        <Route path="community/inbox" element={<AllMessage />} />
        <Route path="community/meet-up" element={<MeetUp />} />
        <Route path="community/followers" element={<Followers />} />
        <Route path="academy/directory" element={<Directory />} />
        <Route path="academy/academy" element={<Academy />} />
        <Route path="academy/pricing-tool" element={<PricingTool />} />
        <Route path="academy/video-library" element={<AcademyNew />} />
        <Route path="academy/video-lesson/:id" element={<VideoLibrary />} />

        <Route path="sales/orders" element={<MyOrders />} />
        <Route path="sales/discount" element={<DiscountPage />} />
        <Route path="sales/orders/detail/:orderId" element={<OrderDetails />} />
        <Route path="sales/reviews" element={<AllReviews />} />
        <Route path="sales/reviews/:reviewId" element={<ReviewDetails />} />
        <Route path="sales/payments" element={<PaymentSettings />} />
        <Route path="sales/shipping" element={<ShippingAddress />} />
        <Route path="support" element={<ContactUs />} />
      </Route>
      <Route path="portfolio/certificates/:id" element={<ViewCertificate />} />
      <Route path="certificate/:id" element={<ViewCertificate />} />
      <Route path="sitemap" element={<SiteMap />} />
      {/* <Route path="university" element={<University />} /> */}
      <Route path="privacy-policy" element={<PrivacyPolicy />} />
      <Route path="return-policy" element={<ReturnPolicy />} />
      <Route path="paymentStatus" element={<PaymentStatus />} />
      <Route path="terms-and-conditions" element={<Terms />} />
      <Route path="cookie-policy" element={<CookiePolicy />} />
      <Route path="personalize" element={<Personalize />} />
      <Route path="faqs" element={<FAQ />} />
      <Route path="contact-us" element={<ContactUs />} />
      <Route path="my-subscription" element={<MySubscription />} />
      <Route path="special-offers" element={<Offers />} />
      <Route path="invoice" element={<InvoicePage />} />
      <Route path="my-certificate" element={<MyCertificate />} />
      <Route path="checkout" element={<Checkout />} />

      {/* </Route> */}
    </Routes>
  );
};

export default AppRoutes;
