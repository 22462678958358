import { Container } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import * as Yup from 'yup';
import DarkBtn from '../../components/universal/Buttons/DarkBtn';
import { CardContainer } from '../../components/universal/CardContainer';
import SimpleSelectBox from '../../components/universal/InputTypeText/SelectBox';
import TextFieldInput from '../../components/universal/InputTypeText/TextField';
import TextAreaBox from '../../components/universal/InputTypeText/TextareaBox';
import { globalWindow } from '../../core/constant';
import AxiosAll from '../../services/axiosAll';
import notify from '../../utils/notify';

const ContactUs = () => {
  const [supportOptions, setSupportOptions] = useState([]);
  const { userDetails } = useSelector((state) => state.user);
  const location = useLocation();

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required').trim(),
    email: Yup.string()
      .email('Invalid email address')
      .matches(
        // eslint-disable-next-line max-len
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'Invalid email address',
      )
      .required('Email is required')
      .trim(),
    message: Yup.string().required('Message is required').trim(),
    supportRequired: userDetails?.token && Yup.string().required('Support Required is required').trim(),
  });

  const initialValues = {
    firstName: userDetails?.user?.v_fname || '',
    email: userDetails?.user?.v_email || '',
    message: '',
    supportRequired: '',
  };

  const clearForm = () => {
    formik.resetForm();
    formik.setFieldValue('firstName', '');
    formik.setFieldValue('email', '');
    formik.setFieldValue('supportRequired', '');
  };

  const onSubmit = async (values) => {
    try {
      const data = {
        v_name: values.firstName,
        v_email: values.email,
        l_message: values.message,
      };
      if (userDetails.token) {
        data.i_department_id = values.supportRequired;
      }

      const res = await AxiosAll('post', userDetails?.token ? 'contactSupport' : 'contactUs', data, userDetails?.token);
      if (res?.data?.statusCode === 200) {
        notify(res?.data?.message, 'success');
        clearForm();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSupportDropdown = async () => {
    try {
      const res = await AxiosAll('get', 'dropdowns?type[9]=support_departments', null, userDetails?.token);

      if (res?.data?.statusCode === 200) {
        setSupportOptions(res?.data?.data?.support_departments);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (userDetails.token) {
      getSupportDropdown();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (supportOptions.length > 0) {
      formik.setFieldValue('supportRequired', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supportOptions]);

  useEffect(() => {
    globalWindow?.scrollTo(0, 0);
  }, []);

  return (
    <div
      className={
        location?.pathname === '/contact-us' ? 'contactpage' : location?.pathname === '/support' && 'support-contact'
      }
    >
      <div className="loginpage">
        <CardContainer paddingX={0} paddingY={0}>
          <div className="login_box">
            <h1 className="maintitle font40px">Contact Support</h1>
            <p className="toptext font20px">Welcome to our support page.</p>

            <p className="toptext font20px">
              We&#39;re here to help with any issues or questions you may have with your account. Simply fill out the
              form below and our team will be in touch.
            </p>
            <div className="contactfiled">
              <div className="inner-text-field">
                <span className="font20px">First Name</span>
                <TextFieldInput
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.firstName && formik.errors.firstName}
                  helperText={formik.touched.firstName && formik.errors.firstName}
                />
                <span className="font20px">Email Address</span>
                <TextFieldInput
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && formik.errors.email}
                  helperText={formik.touched.email && formik.errors.email}
                />
                {userDetails.token && supportOptions && (
                  <>
                    <span className="font20px">Support Required</span>
                    <SimpleSelectBox
                      name="supportRequired"
                      options={supportOptions.map((item) => {
                        return { id: item.i_order, name: item.v_title };
                      })}
                      onChange={formik.handleChange}
                      value={formik.values.supportRequired || 0}
                      onBlur={formik.handleBlur}
                      error={formik.touched.supportRequired && formik.errors.supportRequired}
                      helperText={formik.touched.supportRequired && formik.errors.supportRequired}
                      defaultOption="Select"
                      defaultValue={0}
                    />
                  </>
                )}
                <span className="font20px">Message</span>
                <TextAreaBox
                  name="message"
                  // placeholder="Write your message here"
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.message && formik.errors.message}
                />
              </div>
              <div className="submitbtn">
                <DarkBtn title="Submit" onClick={formik.handleSubmit} />
              </div>
            </div>
          </div>
        </CardContainer>
      </div>
    </div>
  );
};
export default ContactUs;
