import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { CustomBtn } from '../universal/Buttons/CustomBtn';
import { GreenBtn } from '../universal/Buttons/GreenBtn';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { onClose, ...other } = props;

  return (
    <div className="pop-head" {...other}>
      {onClose ? (
        <IconButton aria-label="close" onClick={onClose} className="cross-icon">
          <CloseIcon />
        </IconButton>
      ) : null}
    </div>
  );
};

export const OrderPopUp = ({
  topMargin,
  className,
  normalBtn,
  normalBtnTitle,
  btnTitle,
  children,
  onClick,
  headBgColor,
  open,
  openState,
  handleClickOpen,
  downloadShippingLabel,
  handleClose,
  disableBtn,
  disabled,
}) => {
  return (
    <div style={{ marginTop: topMargin }}>
      {!disableBtn && !normalBtn ? (
        <CustomBtn
          title={btnTitle}
          disabled={disabled}
          onClick={() => {
            onClick && onClick();
            handleClickOpen && handleClickOpen(openState);
          }}
          className={`topmargin ${className}`}
        />
      ) : (
        <GreenBtn title={normalBtnTitle} onClick={downloadShippingLabel} className="shipping-label" />
      )}
      <BootstrapDialog
        onClose={() => handleClose(openState)}
        aria-labelledby="customized-dialog-title"
        className="print-pop"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => handleClose(openState)}
          style={{ background: headBgColor }}
        />
        <DialogContent className="box-container">{children}</DialogContent>
      </BootstrapDialog>
    </div>
  );
};
