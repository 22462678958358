import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { SideOption } from '../../core/constant';
import { useWindowSize } from '../../hooks/window';
import { MAX_MOBILE_WIDTH } from '../../models/breakpoints.model';
import AxiosAll from '../../services/axiosAll';
import { AccordionLink } from '../universal/Accordion';

const SideBar = ({ setState, anchor }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { width } = useWindowSize();
  const [cardList, setCardList] = useState([]);
  const isMobile = (width || 0) < MAX_MOBILE_WIDTH;
  const isHeight = isMobile ? 'inherit' : 60;
  const { subscriptionDetails } = useSelector((state) => state?.subscription);
  const { userDetails } = useSelector((state) => state.user);

  const getCards = async () => {
    try {
      const res = await AxiosAll('get', 'artist/paymentMethods', '', userDetails.token);
      const data = res?.data?.data;
      setCardList(data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  useEffect(() => {
    getCards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="sidebar">
      <ul className="sidemenubar">
        {SideOption(subscriptionDetails, cardList)?.map((option, index) => {
          return option?.type === 'accordion' ? (
            <AccordionLink key={index} option={option} setState={setState} anchor={anchor} />
          ) : (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            <li
              className={
                option?.slug === location?.pathname || location.pathname.includes(option?.slug)
                  ? 'activesidebar side-bar-height'
                  : 'side-bar-height'
              }
              key={index}
              onClick={() => {
                navigate(option?.allowed ? option?.slug : '/my-subscription');
                setState && setState((prev) => ({ ...prev, [anchor]: false }));
              }}
              style={{ margin: 0, height: isHeight }}
            >
              <Link
                href
                // className=""
                // onClick={() => setState && setState(prev => ({ ...prev, [anchor]: false }))}
                // href={`${option?.slug}`}
                // to={`${option?.slug}`}
              >
                {option?.title} {option?.allowed ? '' : <img src="/icons/lock.svg" alt="" width={20} height={26} />}
              </Link>
            </li>
          );
        })}
        {/* <li>
          <Link className="" href="/artist-account" to="#">
            Artist Account
          </Link>
        </li> */}
      </ul>
    </div>
  );
};

export default SideBar;
