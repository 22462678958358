import { useFormik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import * as Yup from 'yup';
import AxiosAll from '../../services/axiosAll';
import notify from '../../utils/notify';
import DarkBtn from '../universal/Buttons/DarkBtn';
import TextFieldInput from '../universal/InputTypeText/TextField';
import { globalWindow } from '../../core/constant';

const Password = () => {
  const navigate = useNavigate();
  const params = new URLSearchParams(globalWindow?.location?.search);

  const [loading, setLoading] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(true);
  const [isPasswordShow, setIsPasswordShow] = useState(true);
  const initialValues = {
    password: '',
    confirmPassword: '',
  };

  const validationSchema = Yup.object({
    password: Yup.string()
      .required('Password is required.')
      .min(8, 'Password must be at least 8 characters')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\W_])/,
        'Oops! Your password must contain a capital letter, small letter, number, and special character. Please try again.',
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required.'),
  });

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      const data1 = {
        password: values.password,
        password_confirmation: values.confirmPassword,
      };
      const response = await AxiosAll('post', `resetPassword?token=${params.get('token')}`, data1);
      const data = await response?.data;
      if (data?.statusCode === 200) {
        setLoading(false);
        notify(data?.message, 'success');
        formik.resetForm();
        navigate('/login');
      } else {
        setLoading(false);
        notify(data?.message, 'error');
        navigate('/forgot-password');
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <div className="loginpassword">
      <div className="font30px mobileshow">
        Confirm Your
        <br /> New Password.
      </div>
      <p className="font20px textleft resetpassword-heading">
        Reset your password here. Rules explained characters, numbers, capitals etc.
      </p>
      <div className="signuptab password">
        <form onSubmit={formik?.handleSubmit}>
          <TextFieldInput
            password
            placeholder="Password"
            name="password"
            type={isPasswordShow ? 'password' : 'text'}
            id="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            isPasswordShow={isPasswordShow}
            setIsPasswordShow={setIsPasswordShow}
          />
          <TextFieldInput
            password
            placeholder="Confirm Password"
            name="confirmPassword"
            type={confirmPassword ? 'password' : 'text'}
            id="confirmPassword"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
            helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
            isPasswordShow={confirmPassword}
            setIsPasswordShow={setConfirmPassword}
          />
          <DarkBtn loading={loading} title="Sign In" type="submit" />
        </form>
      </div>
    </div>
  );
};

export default Password;
