import { TextField } from '@mui/material';
import React from 'react';

const TextAreaBox = ({ value, onChange, error, placeholder, ...props }) => {
  return (
    <div className="textareabox">
      <TextField
        id="standard-multiline-static"
        multiline
        value={value}
        onChange={onChange}
        rows={4}
        variant="standard"
        placeholder={placeholder}
        {...props}
      />
      {error && <div className="error_text">{error}</div>}
    </div>
  );
};

export default TextAreaBox;
