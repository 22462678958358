import { Container, Grid } from '@mui/material';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import AboutYourself from '../../components/LoginSection/AboutYourself';
import LocationRightText from '../../components/LoginSection/LocationRightText';

const Personalize = () => {
  const navigate = useNavigate();
  const { userDetails } = useSelector((state) => state.user);

  const generateMatchMakerUrl = () => {
    if (userDetails?.token) {
      if (userDetails?.user?.is_completed_seller !== 0) {
        navigate('/dashboard');
      }
    } else {
      navigate('/login');
    }
  };

  useEffect(() => {
    generateMatchMakerUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="loginpage">
      <Container className="all-screen-container">
        <div className="login_box">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <h2 className="loginhead  textleft mobileshow">
                We like to get to know our artists, tell us a bit about yourself...{' '}
              </h2>
              <div className="loginregpage">
                <div>
                  <AboutYourself />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <LocationRightText text="We like to get to know our artists, tell us a bit about yourself... " />
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
};

export default Personalize;
