import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

const DashboardCharts = ({ data, categories, type, name }) => {
  const [options, setOptions] = useState({
    chart: {
      id: 'dashboard-chart',
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    colors: ['#80C86E'],
    legend: {
      show: false,
    },
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    xaxis: {
      categories,
    },
  });

  const series = [
    {
      name,
      data,
    },
  ];

  useEffect(() => {
    if (type === 'bar') {
      setOptions({
        ...options,
        stroke: {
          ...options.stroke,
          width: 1,
          colors: ['#fff'],
        },
      });
    }
  }, [options, type]);

  return <Chart options={options} series={series} type={type} height={320} />;
};

export default DashboardCharts;
