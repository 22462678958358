import { Grid, Typography } from '@mui/material';
import { Container } from '@mui/system';
import React from 'react';
import { Link } from 'react-router-dom';

import { InvoiceTable } from '../../components/invoice/InvoiceTable';
import DarkBtn from '../../components/universal/Buttons/DarkBtn';
import WhiteBtn from '../../components/universal/Buttons/whiteBtn';
import { images } from '../../assets';
import { useWindowSize } from '../../hooks/window';
import { MAX_MOBILE_WIDTH } from '../../models/breakpoints.model';
import { exportAsPdf } from '../../hooks/downloadAsPDF';

export const InvoicePage = () => {
  const { width } = useWindowSize();
  const isMobile = (width || 0) < MAX_MOBILE_WIDTH;
  const idNumber = 123456;
  const userName = 'Justin Pearl';
  const userAddress = 'Justin Pearl, Australia, Canberra - Street 345 high hill behind the tower Canberra India 234CAN';

  return (
    <div className="invoice">
      <div className="orderinvoice">
        <Container>
          <div className="btn-section">
            <DarkBtn title="Download pdf" onClick={() => exportAsPdf('salesinvoice', '123')} />
            <WhiteBtn title="Email invoice" />
          </div>
          <div id="salesinvoice" className="salesinvoice">
            <div className="sales-name-section">
              <div className="sales-name">Sales Invoice</div>
              <div className="sales-name">Invoice #{idNumber}</div>
            </div>
            <Grid container spacing={2}>
              <Grid item xs={isMobile ? 6 : 4}>
                <Typography variant="h4" className="sub-heading trending-heading">
                  Shipped To :
                </Typography>
                <Typography
                  paragraph
                  className="para"
                  dangerouslySetInnerHTML={{ __html: userName + {} + userAddress }}
                />
              </Grid>
              <Grid item xs={isMobile ? 6 : 4}>
                <Typography variant="h4" className="sub-heading trending-heading">
                  Billed To :
                </Typography>
                <Typography
                  paragraph
                  className="para"
                  dangerouslySetInnerHTML={{ __html: userName + {} + userAddress }}
                />
              </Grid>
            </Grid>

            <InvoiceTable />
          </div>
        </Container>
      </div>
      <div className="sellerfooter">
        <Container>
          <div className="footer-section">
            <img src={images.mainLogo} alt="" />

            <div className="link-section">
              <Link href className="privacy-link">
                Privacy Policy
              </Link>
              <Link href className="privacy-link">
                Terms and Conditions
              </Link>
              <Link href className="privacy-link">
                Contact Us
              </Link>
            </div>

            <div className="icon-section">
              <Link href>
                <img src={images.FACEBOOK} alt="" className="icon-img" />
              </Link>
              <Link href>
                <img src={images.INSTAGRAM} alt="" className="icon-img" />
              </Link>
              <Link href>
                <img src={images.LINKEDIN} alt="" className="icon-img" />
              </Link>
              <Link href>
                <img src={images.PINTERESR} alt="" className="icon-img" />
              </Link>
              <Link href>
                <img src={images.TWITTER} alt="" className="icon-img" />
              </Link>
              <Link href>
                <img src={images.google} alt="" className="icon-img" />
              </Link>
              <Link href>
                <img src={images.apple} alt="" className="icon-img" />
              </Link>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};
