import { Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { FeedbackCard } from '../../../components/all_reviews/FeedbackCard';
import { CardContainer } from '../../../components/universal/CardContainer';
import { useWindowSize } from '../../../hooks/window';
import { MAX_MOBILE_WIDTH } from '../../../models/breakpoints.model';
import { request } from '../../../services/request';
import { SingleReviewView } from '../../../components/all_reviews/SingleReviewView';
import WhiteBtn from '../../../components/universal/Buttons/whiteBtn';

const ReviewDetails = () => {
  const [singleMessageBox, setSingleMessageBox] = useState(true);
  const [reviewDetails, setReviewDetails] = useState({});
  const [isReviewAdded, setIsReviewAdded] = useState(false);

  const { userDetails } = useSelector((state) => state.user);
  const params = useParams();
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const isToSmall = (width || MAX_MOBILE_WIDTH) < 350;
  const isMobile = (width || 0) < MAX_MOBILE_WIDTH;

  // call me for this perticular useEffect @brajesh
  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 0 && singleMessageBox) {
        setSingleMessageBox(false);
      } else if (window.scrollY === 0 && !singleMessageBox) {
        setSingleMessageBox(true);
      }
    }
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [singleMessageBox]);

  const getReviews = useCallback(async () => {
    try {
      const res = await request({
        url: `/artist/artworkReviews/${params.reviewId}}`,
        method: 'GET',
        token: userDetails?.token,
      });

      setReviewDetails(res.data);
    } catch (error) {
      console.log(error);
    }
  }, [userDetails?.token, params.reviewId]);

  useEffect(() => {
    getReviews();
  }, [getReviews]);

  return (
    <div className="allreviews">
      <div className="searchbtninput">
        <div className="font25px mobilehide">Your Reviews</div>
        <div className="font25px mobileshow">Your Reviews</div>
      </div>
      <div className="message-section">
        <CardContainer height={775} gap={25} className="feedback-id-card-container">
          <SingleReviewView reviewDetails={reviewDetails} />
          {reviewDetails?.e_review_status === '1' && (
            <>
              <div className="scroll-dir">
                <div className="messagesbox feedback-id-messagebox">
                  <FeedbackCard
                    comment={reviewDetails?.review?.l_comment}
                    stars={reviewDetails?.review?.f_overall_happiness}
                    condition={reviewDetails?.review?.f_condition}
                    delivery={reviewDetails?.review?.f_delivery}
                    quality={reviewDetails?.review?.f_quality}
                    value={reviewDetails?.review?.f_value}
                    starHeight={isToSmall ? 25 : 40}
                    starWidth={isToSmall ? 25 : 40}
                  />
                </div>
              </div>
              {isMobile && <WhiteBtn title="Back" onClick={() => navigate('/sales/reviews')} />}
            </>
          )}
          {reviewDetails?.e_review_status === '0' && (
            <>
              <div className="mobileForm">
                <Typography variant="h5" align="center" sx={{ mt: '3rem' }}>
                  No Reviews Yet
                </Typography>
              </div>
              {isMobile && <WhiteBtn title="Back" onClick={() => navigate('/sales/reviews')} />}
            </>
          )}
        </CardContainer>
      </div>
    </div>
  );
};

export default ReviewDetails;
