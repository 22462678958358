import * as React from 'react';

import { Container, Grid } from '@mui/material';

import LoginRightText from '../../components/LoginSection/LoginRightText';
import LoginTab from '../../components/LoginSection/LoginTab/LoginTab';

const Login = () => {
  // const dispatch = useDispatch();
  // const nav = useNavigate();

  // const loginCall = async () => {
  //   nav('/dashboard');
  //   // notify(response.data.message, "success");
  //   dispatch(
  //     addLogin({
  //       token: 'response?.data?.result?.userToken',
  //       user: { id: 1 },
  //     }),
  //   );
  // };

  return (
    <div className="loginpage">
      <Container className="all-screen-container">
        <div className="login_box">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <h2 className="loginhead mobileshow">
                {/* Fill Your <br /> World With Art */}
                List Your Art <br /> in Just a Few Clicks
              </h2>

              <div className="loginregpage">
                <div>
                  <LoginTab tabVal="2" />
                  {/* <AboutYourself /> */}
                  {/* <ForgotPasswordEmail/> */}
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <LoginRightText />
              {/* <LocationRightText/> */}
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
};

export default Login;
