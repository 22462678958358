import { Grid, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import * as Yup from 'yup';
import TextFieldInput from '../../components/InputTypeText/TextField';
import DarkBtn from '../../components/universal/Buttons/DarkBtn';
import UnderLineBtn from '../../components/universal/Buttons/UnderLineBtn';
import { CardContainer } from '../../components/universal/CardContainer';
import { useWindowSize } from '../../hooks/window';
import { MAX_MOBILE_WIDTH } from '../../models/breakpoints.model';
import AxiosAll from '../../services/axiosAll';
import notify from '../../utils/notify';
import { nextStep } from '../dashboard';
import { GreenBtn } from '../../components/universal/Buttons/GreenBtn';
import { Loader } from '../../components/universal/Loader';
import { EuropeanCountries } from '../../core/constant';
import { SimpleSelectBox } from '../../components/InputTypeText/SelectBox';

export const ShippingAddress = () => {
  const [isDisabled, setIsDisabled] = useState({
    shipping: true,
    mobile: true,
  });
  const [AccountStatusPoint, setAccountStatusPoint] = useState({});
  const [addressList, setAddressList] = useState([]);
  const [isAddressListUpdated, setIsAddressListUpdated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [saveType, setSaveType] = useState('');

  const { userDetails } = useSelector((state) => state.user);
  const { width } = useWindowSize();
  const isToSmall = (width || MAX_MOBILE_WIDTH) < 600;
  const navigate = useNavigate();

  const initialValues = {
    address: '',
    address2: '',
    city: '',
    region: '',
    country: '',
    postCode: '',
    phone: '',
  };

  const validationSchema = Yup.object().shape({
    address: Yup.string()?.required('Address line 1 is required'),
    address2: Yup.string()?.required('Address line 2 is required'),
    city: Yup.string()?.required('City is required'),
    region: Yup.string()?.required('Region is required'),
    postCode: Yup.string()?.required('Post Code is required'),
    country: Yup.string()?.required('Country is required'),
    phone: Yup.string()
      .nullable()
      .matches(/^[0-9]*$/, 'Phone number must be numeric only')
      .min(10, 'Phone number is invalid')
      .max(13, 'Phone number is invalid'),
  });

  const getAccountStatus = async () => {
    const res = await AxiosAll('GET', 'artist/accountStatus', null, userDetails?.token);
    if (res?.data?.statusCode === 200) {
      const data = res?.data?.data;
      setAccountStatusPoint(data);
    }
  };

  const getAddress = async () => {
    try {
      const res = await AxiosAll('get', 'addresses?address_type=3', '', userDetails.token);
      const data = res?.data?.data;
      formik.setFieldValue('address', data?.[0]?.v_address_line || '');
      formik.setFieldValue('address2', data?.[0]?.v_address_line2 || '');
      formik.setFieldValue('city', data?.[0]?.v_city || '');
      formik.setFieldValue('postCode', data?.[0]?.v_postcode || '');
      formik.setFieldValue('region', data?.[0]?.v_region || '');
      formik.setFieldValue(
        'country',
        EuropeanCountries.find((country) => country.name === data?.[0]?.v_country)?.id || '',
      );
      formik.setFieldValue('phone', data?.[0]?.v_phone || '');
      formik.setFieldValue('isDefault', data?.[0]?.i_is_default_address === 1);
      setAddressList(data);
      setIsAddressListUpdated(false);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAddress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddressListUpdated]);

  const addAddress = async (data) => {
    try {
      const res = await AxiosAll('post', 'addresses', data, userDetails.token);
      if (res?.data?.statusCode === 200) {
        notify(res?.data?.message, 'success');
        if (saveType === 'shipping') {
          setIsDisabled({ ...isDisabled, shipping: true });
        } else {
          setIsDisabled({ ...isDisabled, mobile: true });
        }
        if (AccountStatusPoint?.organise_shipping === 0) {
          getAccountStatus();
        }
        getAddress();
      } else {
        notify(res?.data?.message, 'error');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateAddress = async (data) => {
    try {
      const id = addressList[0]?.id;
      const res = await AxiosAll('put', `addresses/${id}`, data, userDetails.token);
      if (res?.data?.statusCode === 200) {
        notify(res?.data?.message, 'success');
        if (saveType === 'shipping') {
          setIsDisabled({ ...isDisabled, shipping: true });
        } else {
          setIsDisabled({ ...isDisabled, mobile: true });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = (values) => {
    const data = {
      address_line: values.address,
      address_line2: values.address2,
      city: values.city,
      region: values.region,
      country: values.country,
      phone: values.phone,
      postcode: values.postCode,
      is_default_address: 1,
      address_type: 3,
    };
    if (addressList.length === 0) {
      addAddress(data);
    } else {
      updateAddress(data);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    getAccountStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (addressList && addressList?.[0]?.v_address_line) {
      formik.setFieldValue('address', addressList?.[0]?.v_address_line || '');
      formik.setFieldValue('address2', addressList?.[0]?.v_address_line2 || '');
      formik.setFieldValue('city', addressList?.[0]?.v_city || '');
      formik.setFieldValue('postCode', addressList?.[0]?.v_postcode || '');
      formik.setFieldValue('region', addressList?.[0]?.v_region || '');
      formik.setFieldValue(
        'country',
        EuropeanCountries.find((country) => country.name === addressList?.[0]?.v_country)?.id || '',
      );
      formik.setFieldValue('phone', addressList?.[0]?.v_phone || '');
      formik.setFieldValue('isDefault', addressList?.[0]?.i_is_default_address === 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressList]);

  return (
    <div className="shippingaddress">
      <Typography variant="h4" className="mainheading">
        Your Shipping Preferences
      </Typography>
      <Loader loading={loading} color="#f5445e" />
      <CardContainer className="addressfrom">
        <Typography variant="h5" className="heading">
          Where Will You Be Shipping From
        </Typography>
        <TextFieldInput
          placeholder="Address Line 1"
          variant={isDisabled.shipping ? 'filled' : 'outlined'}
          disabled={isDisabled.shipping}
          name="address"
          value={formik.values.address}
          onChange={formik.handleChange}
          error={formik.touched.address && Boolean(formik.errors.address)}
          helperText={formik.touched.address && formik.errors.address}
        />
        <TextFieldInput
          placeholder="Address Line 2"
          name="address2"
          variant={isDisabled.shipping ? 'filled' : 'outlined'}
          disabled={isDisabled.shipping}
          value={formik.values.address2}
          onChange={formik.handleChange}
          error={formik.touched.address2 && Boolean(formik.errors.address2)}
          helperText={formik.touched.address2 && formik.errors.address2}
        />
        <Grid container spacing={2} className="city">
          <Grid item xs={6}>
            <TextFieldInput
              placeholder="City"
              name="city"
              variant={isDisabled.shipping ? 'filled' : 'outlined'}
              disabled={isDisabled.shipping}
              value={formik.values.city}
              onChange={formik.handleChange}
              error={formik.touched.city && Boolean(formik.errors.city)}
              helperText={formik.touched.city && formik.errors.city}
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldInput
              placeholder="Region"
              name="region"
              variant={isDisabled.shipping ? 'filled' : 'outlined'}
              disabled={isDisabled.shipping}
              value={formik.values.region}
              onChange={formik.handleChange}
              error={formik.touched.region && Boolean(formik.errors.region)}
              helperText={formik.touched.region && formik.errors.region}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} className="city">
          <Grid item xs={6}>
            <SimpleSelectBox
              name="country"
              options={EuropeanCountries}
              onChange={formik.handleChange}
              value={formik.values.country || 0}
              variant={isDisabled.shipping ? 'filled' : 'outlined'}
              disabled={isDisabled.shipping}
              onBlur={formik.handleBlur}
              error={formik.touched.country && formik.errors.country}
              helperText={formik.touched.country && formik.errors.country}
              defaultOption="Country/Region"
              defaultValue={0}
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldInput
              placeholder="Post Code"
              name="postCode"
              variant={isDisabled.shipping ? 'filled' : 'outlined'}
              disabled={isDisabled.shipping}
              value={formik.values.postCode}
              onChange={formik.handleChange}
              error={formik.touched.postCode && Boolean(formik.errors.postCode)}
              helperText={formik.touched.postCode && formik.errors.postCode}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} className="pin">
          <Grid item xs={6}>
            <TextFieldInput
              placeholder="Mobile Number (Optional)"
              type="number"
              name="phone"
              variant={isDisabled.shipping ? 'filled' : 'outlined'}
              disabled={isDisabled.shipping}
              value={formik.values.phone}
              onChange={formik.handleChange}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
            />
          </Grid>
          <Grid item xs={6}>
            {isDisabled.shipping ? (
              <DarkBtn title="Edit" onClick={() => setIsDisabled({ ...isDisabled, shipping: false })} />
            ) : (
              <GreenBtn
                title="Save"
                type="submit"
                onClick={() => {
                  formik.handleSubmit();
                  setSaveType('shipping');
                }}
              />
            )}
          </Grid>
        </Grid>
      </CardContainer>
      {/* {addressList.length > 0 && (
        <CardContainer className="optionalfrom">
          <Grid container spacing={4} className="city">
            <Grid item xs={8}>
              <TextFieldInput
                placeholder="Mobile Number (Optional)"
                type="number"
                name="phone"
                disabled={isDisabled.mobile}
                value={formik.values.phone}
                onChange={formik.handleChange}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
              />
            </Grid>
            <Grid item xs={4}>
              {isDisabled.mobile ? (
                <DarkBtn title="Edit" onClick={() => setIsDisabled({ ...isDisabled, mobile: false })} />
              ) : (
                <DarkBtn
                  title="Save"
                  type="submit"
                  onClick={() => {
                    formik.handleSubmit();
                    setSaveType('mobile');
                  }}
                />
              )}
            </Grid>
          </Grid>
        </CardContainer>
      )} */}

      <div className="skip">
        {nextStep(AccountStatusPoint) && AccountStatusPoint?.organise_shipping !== 1 && (
          <UnderLineBtn
            title="Skip For Now"
            onClick={() => navigate(`${nextStep(AccountStatusPoint, 'organise_shipping')}`)}
          />
        )}
        {AccountStatusPoint?.organise_shipping === 1 && AccountStatusPoint?.launch_page !== 1 && (
          <DarkBtn title="Continue" onClick={() => navigate(`${nextStep(AccountStatusPoint)}`)} />
        )}
      </div>
    </div>
  );
};
