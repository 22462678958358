import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DarkBtn from '../../components/universal/Buttons/DarkBtn';
import AxiosAll from '../../services/axiosAll';
import notify from '../../utils/notify';
import { nextStep } from '../dashboard';
import UnderLineBtn from '../../components/universal/Buttons/UnderLineBtn';
import { notifyMessages } from '../../core/constant';

const PaymentSettings = () => {
  const [userStatus, setUserStatus] = useState({});
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [AccountStatusPoint, setAccountStatusPoint] = useState({});
  const [fetchingLink, setFetchingLink] = useState(false);

  const navigate = useNavigate();
  const { userDetails } = useSelector((state) => state.user);

  /**
   * todo: v_ryftpay_seller_sub_acc_status in production : "ActionRequired" "Unverified" "VerificationPending" "Verified"
   */

  const getBankDetails = async () => {
    try {
      setLoadingDetails(true);
      const res = await AxiosAll('get', 'artist/bankDetails/index', null, userDetails?.token);
      if (res?.data?.statusCode === 200) {
        setUserStatus(res?.data?.data);
        setLoadingDetails(false);
      } else {
        setLoadingDetails(false);
      }
    } catch (error) {
      setLoadingDetails(false);
    }
  };

  const getAccountStatus = async () => {
    const res = await AxiosAll('GET', 'artist/accountStatus', null, userDetails?.token);
    if (res?.data?.statusCode === 200) {
      const data = res?.data?.data;
      setAccountStatusPoint(data);
    }
  };

  const getAccountLink = async () => {
    try {
      if (userDetails?.user?.is_completed_seller === 0) {
        notify(notifyMessages?.completeProfileMsg, 'error');
        navigate('/personalize');
        return;
      }
      // getBankDetails();
      setFetchingLink(true);
      const res = await AxiosAll('post', 'artist/bankDetails/getAccountLink', null, userDetails?.token);
      if (res?.data?.statusCode === 200) {
        window.open(res?.data?.data?.url);
        // setAccountLink(res?.data?.data);
        setFetchingLink(false);
      } else {
        setFetchingLink(false);
      }
    } catch (error) {
      console.log(error);
      setFetchingLink(false);
    }
  };

  const generateButtonTitle = () => {
    switch (true) {
      case userStatus?.v_ryftpay_seller_sub_acc_error?.[0].action === 'PayoutDetailsRequired':
        return 'Complete Payout Details';
      case userStatus?.v_ryftpay_seller_sub_acc_error?.[0].action === 'PayoutMethodRequired':
        return 'Complete Payout Method';
      case userStatus?.v_ryftpay_seller_sub_acc_status === 'Unverified':
        return 'Unverified';
      case userStatus?.v_ryftpay_seller_sub_acc_status === 'Verified':
        return 'Connected';
      default:
        return 'Connect Account';
    }
  };

  const handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      getBankDetails();
    }
  };

  useEffect(() => {
    if (!AccountStatusPoint?.accept_payments) {
      getAccountStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStatus]);

  useEffect(() => {
    getBankDetails();
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // cleanup function to remove event listener
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="myprofilerightsection">
      <h2 className="font40px maintitle">Payments</h2>
      <div className="requestlinksection">
        {loadingDetails ? (
          <p className="font20px">Loading...</p>
        ) : userStatus?.v_ryftpay_seller_sub_acc_error?.[0]?.description ? (
          <p className="font20px">{userStatus?.v_ryftpay_seller_sub_acc_error?.[0]?.description}</p>
        ) : userStatus?.v_ryftpay_seller_sub_acc_status === 'Verified' ? (
          <p className="font20px">Your account is connected</p>
        ) : (
          <p className="font20px">
            Connect with Ryft, our payment partner <br /> to enable smooth and secure transactions.
          </p>
        )}
        {loadingDetails ? (
          <DarkBtn title="Loading..." />
        ) : userStatus?.v_ryftpay_seller_sub_acc_status === 'Verified' ? (
          <DarkBtn title="Connected" />
        ) : (
          <DarkBtn title={fetchingLink ? 'Fetching link...' : generateButtonTitle()} onClick={getAccountLink} />
        )}
      </div>
      <div className="skip">
        {nextStep(AccountStatusPoint) && AccountStatusPoint?.accept_payments !== 1 && (
          <UnderLineBtn
            title="Skip For Now"
            onClick={() => navigate(`${nextStep(AccountStatusPoint, 'accept_payments')}`)}
          />
        )}
        {AccountStatusPoint?.accept_payments === 1 && AccountStatusPoint?.launch_page !== 1 && (
          <DarkBtn title="Continue" onClick={() => navigate(`${nextStep(AccountStatusPoint)}`)} />
        )}
      </div>
    </div>
  );
};

export default PaymentSettings;
