import { images } from '../../../assets';
import { pageLimitOptions, truncateMessage } from '../../../core/constant';
import { formatDate } from '../../../hooks/formatDate';
import { useWindowSize } from '../../../hooks/window';
import { MAX_MOBILE_WIDTH } from '../../../models/breakpoints.model';
import { EmptyNotification } from '../EmptyNotification';
import { NotificationPopUp } from '../NotificationPopUp';
import PaginationBox from '../PaginationBox';
import ImageCard from '../imageCard';

const RenderNotifications = ({ item, readNotification }) => {
  const { width } = useWindowSize();
  const isMobile = (width || 0) < MAX_MOBILE_WIDTH;
  const isToSmall = (width || MAX_MOBILE_WIDTH) < 350;
  return (
    <div className="notificationbar">
      <div className="nottext font20px">{truncateMessage(item?.v_title, isMobile ? 15 : 20)}</div>
      {/* <div className="nottime font20px">{moment(item?.created_at).format('hh:mm A')}</div>
      <div className="notdate font20px">{moment(item?.created_at).format('DD/MM/YY')}</div> */}
      <div className="nottime font20px">{formatDate(item?.created_at, null, 'hh:mm A')}</div>
      <div className="notdate font20px">{formatDate(item?.created_at)}</div>
      <div className="noticon ">
        {item?.e_status === '0' ? (
          <ImageCard image={images.action} alt="notification" />
        ) : (
          <ImageCard image={images.done} alt="notification" />
        )}
      </div>
      <div className="notbutton ">
        <NotificationPopUp
          item={item}
          cardWidth={(isToSmall && 280) || (isMobile && 350)}
          readNotification={readNotification}
        />
      </div>
    </div>
  );
};

const ArtNotifications = ({
  notifications,
  readNotification,
  page,
  limit,
  onPageChange,
  onPageLimitChange,
  isShowPagination,
  showMore,
  onShowMore,
}) => {
  return (
    <div className="notifications">
      <div className="notiheading font25px">Notifications</div>
      <div className="managepad">
        <div className="notifiarea" id="notification-area">
          {notifications?.data?.map((item, index) => {
            return <RenderNotifications key={index} item={item} readNotification={readNotification} />;
          })}
          {notifications?.data?.length === 0 && <EmptyNotification />}
        </div>
        {showMore && (
          <span tabIndex={0} role="button" className="showmorebtn" onClick={onShowMore}>
            Show More
          </span>
        )}
        {isShowPagination && (
          <PaginationBox
            count={notifications?.last_page}
            page={page}
            onChange={onPageChange}
            value={limit}
            pageLimit={pageLimitOptions}
            onChangePageLimit={onPageLimitChange}
          />
        )}
      </div>
    </div>
  );
};

export default ArtNotifications;
