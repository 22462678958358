import { images } from '../../assets';

const LocationRightText = ({ noImage, text }) => {
  return (
    <div className="textsection">
      <h2 className="locationrighthead font40px mobilehide">
        {text}
        {/* conditional text */}
        {/* We like to get to know our artists, tell us a bit about yourself...  */}
      </h2>

      {!noImage && <img src={images.locationRight} alt="search icon" className="locationrightimg" />}
    </div>
  );
};

export default LocationRightText;
