import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IosShareIcon from '@mui/icons-material/IosShare';
import { useState } from 'react';
import { Tooltip, Typography } from '@mui/material';
import DarkBtn from '../Buttons/DarkBtn';

export const InputShare = ({ url, status, erroMsg }) => {
  const [text, setText] = useState('Copy');

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    // <div>
    <>
      {' '}
      {status ? (
        <Tooltip title={erroMsg} arrow>
          <IosShareIcon sx={{ fontSize: 30, color: status ? '#eaeaea' : 'black' }} />
        </Tooltip>
      ) : (
        <Button onClick={handleClickOpen} style={{ minWidth: 'auto', padding: '6px 0px' }}>
          <IosShareIcon sx={{ fontSize: 30, color: status ? '#eaeaea' : 'black' }} />
        </Button>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="share-model"
      >
        <DialogContent>
          <input type="text" disabled value={url} />
          <DarkBtn
            onClick={() => {
              navigator.clipboard.writeText(url);
              setText('Copied');
              setTimeout(() => {
                setText('Copy');
              }, 1000);
            }}
            title={text}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
