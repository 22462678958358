import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import * as Yup from 'yup';
import { deleteUser } from '../../../redux/actions/user';
import AxiosAll from '../../../services/axiosAll';
import notify from '../../../utils/notify';
import DarkBtn from '../../universal/Buttons/DarkBtn';
import GrayBtn from '../../universal/Buttons/GrayBtn';
import TextFieldInput from '../../universal/InputTypeText/TextField';
import sweatAlertBox from '../../universal/SweetAlertBox';
import { deleteSubscription } from '../../../redux/actions/subscription';

export const ChangePassword = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isPasswordShow, setIsPasswordShow] = useState(true);
  const [isPasswordNewShow, setIsPasswordNewShow] = useState(true);
  const { userDetails } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleClose = () => {
    setOpen(false);
  };

  const title = 'Your new password must contain a capital letter, number, and a special character.';

  const initialValues = {
    password: '',
    new_password: '',
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string().required('Old Password is required.').min(8, 'Old Password must be at least 8 characters'),
    new_password: Yup.string()
      .required('New Password is required.')
      .min(8, 'New Password must be at least 8 characters')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\W_])/,
        'Oops! Your password must contain a capital letter, small letter, number, and special character. Please try again.',
      ),
  });

  const changePassword = async (values) => {
    setLoading(true);
    const payload = {
      old_password: values?.password,
      new_password: values?.new_password,
    };
    const response = await AxiosAll('post', `changePassword`, payload, userDetails?.token);
    const data = await response?.data;
    if (data?.statusCode === 200) {
      notify(data?.message, 'success');
      handleClose();
      formik.resetForm();
      setLoading(false);
      logOut();
    } else {
      notify(data?.message, 'error');
      setLoading(false);
    }
  };

  const onSubmit = async (values) => {
    sweatAlertBox(
      'Once you click the proceed button, you will be logged out from your account.',
      '',
      () => changePassword(values),
      'Proceed',
      'Cancel',
    );
  };

  const logOut = () => {
    navigate('/login');
    dispatch(deleteUser());
    dispatch(deleteSubscription());
    const impersonating = localStorage.getItem('impersonating');
    if (impersonating) {
      localStorage.removeItem('impersonating');
    }
    // dispatch(deleteCart());
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const handleClickOpen = () => {
    setOpen(true);
    formik.resetForm();
  };

  return (
    // <div>
    <>
      <DarkBtn title="Change Password" onClick={handleClickOpen} />
      <Dialog sx={{ zIndex: 99 }} open={open} onClose={handleClose}>
        <div className="reset-password">
          <DialogTitle align="center" className="main-title">
            {title}
          </DialogTitle>
          <DialogContent className="pop-content">
            <DialogContentText>
              <form className="reset-form" onSubmit={formik?.handleSubmit}>
                <TextFieldInput
                  password
                  placeholder="Old Password"
                  name="password"
                  id="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type={isPasswordShow ? 'password' : 'text'}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password}
                  isPasswordShow={isPasswordShow}
                  setIsPasswordShow={setIsPasswordShow}
                />
                <TextFieldInput
                  password
                  placeholder="New Password"
                  name="new_password"
                  id="new_password"
                  value={formik.values.new_password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type={isPasswordNewShow ? 'password' : 'text'}
                  error={formik.touched.new_password && Boolean(formik.errors.new_password)}
                  helperText={formik.touched.new_password && formik.errors.new_password}
                  isPasswordShow={isPasswordNewShow}
                  setIsPasswordShow={setIsPasswordNewShow}
                />
                <DarkBtn loading={loading} type="submit" title="Submit" />
                <GrayBtn type="button" title="Cancel" onClick={handleClose} />
              </form>
            </DialogContentText>
          </DialogContent>
        </div>
      </Dialog>
    </>
  );
};
