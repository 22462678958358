import { Skeleton, TableBody, TableCell, TableRow } from '@mui/material';
import React from 'react';

export const TableBodySkeleton = () => {
  const timesToRender = 7;
  return (
    <TableBody className="order-body">
      {[...Array(timesToRender)].map((_, index) => (
        <TableRow key={index}>
          <TableCell align="center">
            <Skeleton variant="rectangular" width="100%" height={45} style={{ borderRadius: 15 }} />
          </TableCell>
          <TableCell align="center">
            <Skeleton variant="rectangular" width="100%" height={45} style={{ borderRadius: 15 }} />
          </TableCell>
          <TableCell align="center">
            <Skeleton variant="rectangular" width="100%" height={45} style={{ borderRadius: 15 }} />
          </TableCell>
          <TableCell align="center">
            <Skeleton variant="rectangular" width="100%" height={45} style={{ borderRadius: 50 }} />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};
