import { Container, Grid } from '@mui/material';
import LocationRightText from '../../components/LoginSection/LocationRightText';
import Password from '../../components/LoginSection/Password';

const ResetPassword = () => {
  return (
    <div className="loginpage confirmpassword">
      <Container className="all-screen-container">
        <div className="login_box">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <div className="loginregpage">
                <Password />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} className="mobilehide">
              <LocationRightText
                noImage
                text={
                  <span>
                    Confirm Your <br />
                    New Password
                  </span>
                }
              />
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
};
export default ResetPassword;
