import { images } from '../../../assets';
import { globalWindow, slugify } from '../../../core/constant';
import DarkBtn from '../Buttons/DarkBtn';
import GrayBtn from '../Buttons/GrayBtn';
import ImageCard from '../imageCard';

const ProfileCard = ({ item, handleRemoveFav, sellerName }) => {
  return (
    <div
      style={{
        cursor: 'pointer',
      }}
      className="profilecard profilecardnew"
    >
      <div className="image_section">
        <div>
          <div style={{ position: 'relative' }}>
            <ImageCard
              image={item?.artwork_cover_image?.v_image ? item?.artwork_cover_image?.v_image : images?.imagePlaceholder}
              onClick={() => {
                globalWindow?.location?.assign(
                  `${process.env.REACT_APP_USER_APP_URL}artwork/${slugify(item?.v_title)}/${item?.id}`,
                );
              }}
              alt="test"
            />
          </div>
          <div className="profile_data">
            <div className="profilenameprice">
              <p className="profilename font14px">{item?.v_title}</p>
              <p className="profileprice font14px">
                £{item?.artwork_price?.i_price ? item?.artwork_price?.i_price?.toFixed(2) : '0.00'}
              </p>
            </div>
            {sellerName && <p className="font14px widset">{item?.artwork_seller?.full_name}</p>}
            <p className="profilelocation font12px">{item?.artwork_seller?.user_city?.v_title}</p>
          </div>
        </div>
        <div className="profilebtn">
          <GrayBtn title="See more" />
          <DarkBtn title="Buy Now" />
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
