import React, { useEffect, useRef } from 'react';
import Vimeo from '@vimeo/player';

const VimeoPlayer = ({ videoURl, VideoEnd }) => {
  const playerRef = useRef(null);
  console.log(videoURl);
  useEffect(() => {
    // Initialize the Vimeo player when component mounts
    const player = new Vimeo(playerRef.current, {
      url: `${videoURl}`, // Use URL instead of id
      width: 640,
      autoplay: false, // Set to true if you want the video to autoplay
    });

    const endedCallback = () => {
      console.log('Finished.');
      VideoEnd();
    };

    player.on('ended', endedCallback);

    return () => {
      // Cleanup function to destroy the player when component unmounts
      player.off('ended', endedCallback);
      player.destroy();
    };
  }, [videoURl]);

  return <div ref={playerRef} />;
};

export default VimeoPlayer;
