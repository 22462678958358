import { images } from '../../assets';
import { truncateMessage } from '../../core/constant';
import { formatDate } from '../../hooks/formatDate';
import { SingleMsgCard } from './singleMsgCard';

const MsgChannel = ({ border, selectHandler, data, activeMsgId }) => {
  return (
    <div className="message-preview">
      {data.map((msg, i) => {
        return (
          <SingleMsgCard
            key={i}
            id={msg?.id}
            border={activeMsgId === msg?.id ? border : null}
            OnPress={selectHandler}
            img={msg?.receiver?.v_image || images.imagePlaceholder}
            buyerName={msg?.receiver?.full_name}
            subject={msg?.last_message?.v_subject_title || truncateMessage(msg?.last_message?.l_message, 10)}
            // time={moment(msg?.last_message?.created_at).format('HH:mm')}
            // date={moment(msg?.last_message?.created_at).format('DD/MM/YY')}
            time={formatDate(msg?.last_message?.created_at, null, 'HH:mm')}
            date={formatDate(msg?.last_message?.created_at)}
            alt={images.imagePlaceholder}
          />
        );
      })}
    </div>
  );
};

export default MsgChannel;
