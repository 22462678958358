/* eslint-disable jsx-a11y/anchor-is-valid */
import { Container, Grid, Link } from '@mui/material';
import { useEffect, useState } from 'react';
import { images } from '../../../assets';
import AxiosAll from '../../../services/axiosAll';
import packageEnv from '../../../../package.json';

export const Footer = () => {
  const [footerData, setFooterData] = useState([]);
  const [socialLinks, setsocialLinks] = useState([]);

  // const router = useRouter();

  // Api call for Footer
  const footerApi = async () => {
    try {
      const res = await AxiosAll('get', `getFooterData`);
      const data = res?.data?.data;
      setFooterData(data);
      setsocialLinks(data?.socialLinks);
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    footerApi();
  }, []);

  return (
    <div className="footersection">
      <Container className="all-screen-container">
        <Grid container spacing={2} sx={{ flexGrow: 1 }}>
          <Grid item xs={12} sm={8}>
            <Grid container spacing={2} sx={{ flexGrow: 1 }}>
              {footerData?.menu?.map((item, index) => {
                return (
                  <Grid item xs={6} sm={3} key={index}>
                    <div className="footermenuone">
                      <h5 className="font25px artists_heading">{item?.title}</h5>
                      <ul className="footermenu">
                        {item?.content?.map((e, i) => {
                          return (
                            <li key={i}>
                              <Link
                                href={
                                  item.section_slug === 'forArtists' || item.section_slug === 'support'
                                    ? e?.url
                                    : `${process.env.REACT_APP_USER_APP_URL}${e?.url?.replace('/', '')}`
                                }
                              >
                                {e?.name}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div className="cardiconfooter">
              <ul>
                <li>
                  <img alt="gpayIcon" src={images.visaIcon} className="iconimgfooter" />
                </li>
                <li>
                  <img alt="gpayIcon" src={images.payIcon} className="iconimgfooter" />
                </li>
                <li>
                  <img alt="gpayIcon" src={images.gpayIcon} className="iconimgfooter" />
                </li>
                <li>
                  <img alt="master" src={images.masterIcon} className="iconimgfooter" />
                </li>
              </ul>
            </div>

            {/* 0 -> insta, 1 -> utube, 2 -> fb, 3-> twitter  */}
          </Grid>
        </Grid>
        <Grid container className="bootomcopy" spacing={2} sx={{ flexGrow: 1 }}>
          <Grid item xs={12} sm={6}>
            <ul className="bottomlinks">
              {footerData?.otherLinks?.map((item, index) => {
                return (
                  <li key={index}>
                    <Link target="_blank" href={item?.url}>
                      {item?.name}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="socialiconfooter">
              {/* <ul>
                      <li >
                        <a target="_blank" href="#" rel="noreferrer">
                         
                        <img 
                     alt="master"
                     src={images.masterIcon}
                     className="iconimgfooter"
                      />
                         
                        </a>
                      </li>
                   
                </ul> */}

              <ul>
                {socialLinks?.map((item, index) => {
                  return (
                    <li key={index}>
                      <a target="_blank" href={item?.l_value} rel="noreferrer">
                        {item?.v_name ? (
                          <img
                            alt={item?.v_name.replaceAll(/SOCIAL_|_LINK/g, '')}
                            src={images[item?.v_name.replaceAll(/SOCIAL_|_LINK/g, '')]}
                            className="socialimgfooter"
                          />
                        ) : (
                          <img
                            alt="Social Media"
                            loading="lazy"
                            src={images.socialIconImg}
                            className="socialimgfooter"
                          />
                        )}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
            <p className="copyrighttext">{footerData?.copyRight}</p>
            {packageEnv?.env === 3 ? null : <p className="copyrighttext">{`V${packageEnv.version}`}</p>}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
