/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';

import { Button, Box, Grid, TextField, Typography, Tooltip, tooltipClasses } from '@mui/material';

// eslint-disable-next-line import/no-extraneous-dependencies
import ScrollContainer from 'react-indiana-drag-scroll';
import { useLocation, useParams } from 'react-router';
// import { useRouter,useParams,useSearchParams, usePathname } from 'next/navigation';

import { Link, useSearchParams } from 'react-router-dom';
import { Image } from '@mui/icons-material';
import { color, styled } from '@mui/system';

import { images } from '../../../../assets';

import { globalWindow, notifyMessages } from '../../../../core/constant';
import notify from '../../../../utils/notify';
import LoginSignInDialog from '../../../dialogs/JoinOrSignIn';
// import { useRouter,useParams,useSearchParams, usePathname } from 'next/navigation';
// import { styled } from '@mui/material/styles';
// import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { SubLiNav } from './SubLiNav';
import DarkBtn from '../../Buttons/DarkBtn';
import VerticalTabs from '../../HeaderTab';
import GrayBtn from '../../Buttons/GrayBtn';

const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'transparent',
      color: '#fff',
      maxWidth: 450,
      left: '60px',
      marginTop: '36px !important',
      Padding: '0px !important',
    },
    [`& .${tooltipClasses.arrow}`]: {
      borderTopColor: 'yellow',
      color: '#fff',
      left: '-36px !important',
    },
  }),
);

export const DesktopViewHeader = ({ headerData, search, setSearch, searchQuery, userDetails }) => {
  // const navigate = useRouter();
  const params = useParams();

  const router = useSearchParams();
  const { pathname } = useLocation();
  // const active_index=router.get("main_category")||""
  // const pathnamenew = usePathname();

  return (
    <div className="mainsection mobilehide">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div
          tabIndex={0}
          role="button"
          onClick={() => {
            if (pathname.includes('personalize') && !userDetails?.user?.is_completed_seller) {
              notify(notifyMessages?.completePersonalization, 'error');
              return;
            }
            globalWindow.location.href = `${process.env.REACT_APP_USER_APP_URL}`;
          }}
          className="mainlogodiv"
        >
          <img src={images?.mainLogo} alt="top bar" className="mainlogo" />
        </div>
        {!pathname.includes('personalize') && (
          <div className="mainmenu">
            {userDetails?.token ? (
              <div className="navbar">
                <div className="subnav subnavbtn">
                  <Link href="/dashboard" to="/dashboard" className="menumaintext">
                    Dashboard
                  </Link>
                </div>
                <div className="subnav subnavbtn">
                  <Link
                    href={`${process.env.REACT_APP_USER_APP_URL}artist/${userDetails?.user?.v_fname}-${userDetails?.user?.v_lname}/${userDetails?.user?.id}`}
                    to={`${process.env.REACT_APP_USER_APP_URL}artist/${userDetails?.user?.v_fname}-${userDetails?.user?.v_lname}/${userDetails?.user?.id}`}
                    className="menumaintext"
                  >
                    View Portfolio
                  </Link>
                </div>
                <div className="subnav subnavbtn">
                  <Link
                    href={`${process.env.REACT_APP_USER_APP_URL}match-maker`}
                    to={`${process.env.REACT_APP_USER_APP_URL}match-maker`}
                    className="menumaintext"
                  >
                    Explore
                  </Link>
                </div>
              </div>
            ) : (
              // new header implementation

              <div className="navbar">
                {headerData?.categories?.map((item) => (
                  <div key={item?.id} value={item?.id} className="subnav subnavbtn">
                    <Link
                      href={`${process.env.REACT_APP_USER_APP_URL}search/artworks/search-all?main_category=${item?.id}`}
                      to={`${process.env.REACT_APP_USER_APP_URL}search/artworks/search-all?main_category=${item?.id}`}
                      // href={`${process.env.REACT_APP_USER_APP_URL}search/artworks/search-all`}
                      className="menumaintext"
                      key={item?.id}
                      // style={{
                      //   color: active_index === `${item?.id}` ? '#f5445e' : '#000000',
                      // }}
                    >
                      {item?.v_name}
                    </Link>
                  </div>
                ))}

                <div className="subnav subnavbtn">
                  <Link
                    href={`${process.env.REACT_APP_USER_APP_URL}about-us`}
                    to={`${process.env.REACT_APP_USER_APP_URL}about-us`}
                    className="menumaintext"
                    // onClick={handleClickBtn}
                    style={{
                      color: pathname === '/about-us' ? '#000' : '#f5445e',
                    }}
                  >
                    For Artists
                  </Link>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <Grid item xs={1.4} sm={2} className="signbtnright">
        <LoginSignInDialog />
      </Grid>
      {!userDetails?.token && (
        <Grid item xs={1} sm={2} className="mobilehide">
          <HtmlTooltip
            arrow
            placement="bottom-end"
            effect="solid"
            className="customTooltip"
            leaveDelay={2000}
            // open={open}
            // onClose={searchToolTipCl}
            // disableHoverListener
            title={
              // <React.Fragment>
              <Box
                sx={{
                  width: 500,
                  maxWidth: '100%',
                }}
                className="floating-search-container"
              >
                <TextField
                  id="fullWidth"
                  fullWidth
                  placeholder="Search artists & artworks..."
                  variant="outlined"
                  className="search_input_hover"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter' && search) {
                      globalWindow?.open(searchQuery, '_blank');
                    }
                  }}
                />
                <GrayBtn title="search" className="searchbtnontooltip" />
                {/* <GrayBtn title="search" className="searchbtnontooltip" onClick={Searchopen} /> */}
              </Box>
              // </React.Fragment>
            }
          >
            <Button
              sx={{ minWidth: 'fit-content' }}
              // onClick={searchToolTip}
            >
              <div className="topsearchdiv" style={{ position: 'relative' }}>
                {/* <div
              onClick={() => (search ? globalWindow?.open(searchQuery, '_blank') : 'javascript:;')}
              className="searchicon"
              >
                <Image src={images.searchIcon} alt="search icon" className="searciconimg searchiconimgnew" />
              </div> */}
                <div tabIndex={0} role="button" onClick={() => globalWindow?.open(searchQuery, '_blank')}>
                  <img src={images.searchIcon} alt="search icon" className="searciconimg searchiconimgnew" />
                </div>
              </div>
            </Button>
          </HtmlTooltip>

          {/* 
          <div className="topsearchdiv" style={{ position: 'relative' }}>
            <TextField
              placeholder="Search Art & Artists"
              type="text"
              className="topsearchbar"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter' && search) {
                  globalWindow?.open(searchQuery, '_blank');
                }
              }}
            />
            <Link
              href
              // href={search ? globalWindow?.open(searchQuery, '_blank') : 'javascript:;'}
              // to={search ? globalWindow?.open(searchQuery, '_blank') : 'javascript:;'}
              className="searchicon"
            >
              <div tabIndex={0} role="button" onClick={() => globalWindow?.open(searchQuery, '_blank')}>
                <img src={images.searchIcon} alt="search icon" className="searciconimg" />
              </div>
            </Link>
          </div> */}
        </Grid>
      )}

      {!userDetails?.token && (
        <Grid item xs={1.6} sm={1} className="alignmentcart">
          <div className="carticontop">
            <Link href={`${process.env.REACT_APP_USER_APP_URL}cart`} to={`${process.env.REACT_APP_USER_APP_URL}cart`}>
              <img src={images.cartIcon} alt="carticon" className="valuecart" />
              <span className="valuecart">(0)</span>
            </Link>
          </div>
        </Grid>
      )}
    </div>
  );
};
