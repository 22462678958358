import { Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { SimpleSelectBox } from '../InputTypeText/SelectBox';
import { GreenBtn } from '../universal/Buttons/GreenBtn';
import TextFieldInput from '../universal/InputTypeText/TextField';
import { request } from '../../services/request';

export const DispatchModal = ({ message, btnTitle, handleDispatch, bottomMessage }) => {
  const [trackingNumber, setTrackingNumber] = useState('');
  const [loading, setloading] = useState(true);
  const [companyList, setcompanyList] = useState([]);
  const [error, seterror] = useState(false);
  const [companySelected, setcompanySelected] = useState(0);
  const handleTrackingNumber = (e) => {
    setTrackingNumber(e.target.value);
  };
  const { userDetails } = useSelector((state) => state.user);
  const getCompany = async () => {
    setloading(true);
    try {
      const res = await request({
        url: '/dropdowns',
        method: 'GET',
        params: {
          type: ['shipping_companies'],
        },
        token: userDetails?.token,
      });

      const data = res?.data;
      setcompanyList(data?.shipping_companies);
      setloading(false);
    } catch (e) {
      console.error('Error fetching dropdowns: ', e);
      setloading(false);
    }
  };
  useEffect(() => {
    getCompany();
  }, []);

  const handleSubmit = (e) => {
    if (trackingNumber?.length === 0 || companySelected === 0) {
      seterror(true);
    } else {
      handleDispatch(e, trackingNumber, companySelected, '0');
    }
  };

  return (
    <div className="print-box">
      {/* <form onSubmit={(e)=>handleSubmit(e)}> */}
      <Typography paragraph className="top-heading" dangerouslySetInnerHTML={{ __html: message }} />
      <Typography paragraph className="description" dangerouslySetInnerHTML={{ __html: bottomMessage }} />
      <div className="dispatch-order">
        <Typography paragraph>Company :</Typography>
        {loading ? (
          'loading...'
        ) : (
          <SimpleSelectBox
            options={companyList}
            defaultOption="Select a Company"
            defaultValue={0}
            value={companySelected}
            onChange={(e) => {
              setcompanySelected(e.target.value);
              seterror(e.target.value === 0);
            }}
            error={error && companySelected === 0}
            helperText={companySelected === 0 ? 'Please select a shipping company ' : ''}
          />
        )}
        <Typography paragraph>Tracking No:</Typography>
        <TextFieldInput
          type="text"
          className="textfield"
          name="trackingNumber"
          value={trackingNumber.trim()}
          onChange={handleTrackingNumber}
          placeholder="Enter Tracking No."
          helperText="Please enter tracking number"
          error={error && trackingNumber?.length === 0}
        />
      </div>
      <GreenBtn
        title={btnTitle}
        onClick={(e) => {
          handleSubmit(e);
        }}
      />
      {/* </form> */}
    </div>
  );
};
