/* eslint-disable react/function-component-definition */
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';

// import { useRouter } from 'next/navigation';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Login from './Login';
import Signup from './Signup';

export default function LoginTab({ setActiveStep, tabVal }) {
  const [value, setValue] = React.useState(tabVal);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const navigate = useNavigate();

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Sign Up" value="1" onClick={() => navigate('/signup')} />
            <Tab label="Log In" value="2" onClick={() => navigate('/login')} />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Signup setActiveStep={setActiveStep} />
        </TabPanel>
        <TabPanel value="2">
          <Login setActiveStep={setActiveStep} />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
