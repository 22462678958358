import React, { useCallback, useEffect, useState } from 'react';
import { Container } from '@mui/system';
import { useLocation, useNavigate, useRoutes } from 'react-router';
import { useSelector } from 'react-redux';
import ArtistsCard from '../../components/universal/ArtistsCard';
import PaginationBox from '../../components/universal/PaginationBox';
import { globalWindow, pageLimitOptions, slugify } from '../../core/constant';
import { EmptyText } from '../../components/universal/EmptyText';
import { request } from '../../services/request';
import { useWindowSize } from '../../hooks/window';
import { MAX_MOBILE_WIDTH } from '../../models/breakpoints.model';
import navigator from '../../utils/navigator';
import { ArtistCardSkeleton } from '../../components/Skeleton/ArtistCardSkeleton';

const Followers = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const searchParam = new URLSearchParams(useLocation().search);
  const pageParams = searchParam.get('page');
  const orderByParams = searchParam.get('order_by');

  const [artists, setArtists] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(12);
  const [parameters, setParameters] = useState({});
  const { userDetails } = useSelector((state) => state.user);
  const { width } = useWindowSize();
  const isMobile = (width || 0) < MAX_MOBILE_WIDTH;
  const isToSmall = (width || MAX_MOBILE_WIDTH) < 350;

  const isShowPagination = !isLoading && artists?.data?.length > 0 && artists?.last_page > 1;
  const getArtists = useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await request({
        url: '/artist/followers',
        method: 'GET',
        params: {
          page,
          limit,
          type: 'followed_artists',
        },
        token: userDetails?.token,
      });
      if (res?.data?.current_page > res?.data?.last_page) {
        setPage(res?.data?.last_page);
        setParameters({ ...parameters, page: res?.data?.last_page });
        // router.push(`${pathname}?${navigate(parameters, 'page', res?.data?.last_page)}`);
        return;
      }
      setArtists(res.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, page, userDetails?.token]);

  const onPageChange = (e, pg) => {
    setPage(pg);
    navigate(`${pathname}?${navigator(parameters, 'page', pg)}`);
  };

  const onPageLimitChange = (e) => {
    setPage(1);
    setLimit(e.target.value);
    navigate(`${pathname}?${navigator(parameters, 'page', 1)}`);
  };

  useEffect(() => {
    getArtists();
  }, [getArtists]);

  useEffect(() => {
    // document.getElementById('top').scrollIntoView({
    //   behavior: 'smooth',
    // });
    globalWindow?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageParams, limit]);

  return (
    <Container className="all-screen-container search_result_container_inner above4K" id="top">
      <div className="searchcardbox">
        {isLoading ? (
          [...Array(12).keys()].map((item) => <ArtistCardSkeleton key={item} imgHeight={isToSmall ? 200 : 285} />)
        ) : !isLoading && artists?.data?.length === 0 ? (
          <EmptyText text="Artist" />
        ) : (
          artists?.data?.map((artist, index) => (
            <ArtistsCard
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              item={artist}
              buttonTitle="See Profile"
              // onClick={() => {
              //   globalWindow.location.href = `${process.env.REACT_APP_USER_APP_URL}artist/${slugify(
              //     artist?.full_name,
              //   )}/${artist.id}`;
              // }}
              className="artists-card-mobile"
            />
          ))
        )}
      </div>
      {isShowPagination && (
        <PaginationBox
          count={artists.last_page}
          page={page}
          onChange={onPageChange}
          value={limit}
          pageLimit={pageLimitOptions}
          onChangePageLimit={onPageLimitChange}
        />
      )}
    </Container>
  );
};

export default Followers;
