import { TableCell, Typography } from '@mui/material';
import React from 'react';

export const PlanCard = ({ planTitle, planHeading, paid, type }) => {
  return (
    <TableCell>
      <div className="plancard">
        <Typography variant="h5">{planTitle}</Typography>
        <Typography variant="h2" sx={{ textTransform: 'uppercase' }}>
          {paid && '£'}
          {planHeading === 0 ? 'Free' : planHeading}
        </Typography>
        {planHeading !== 0 && <Typography variant="h6">/{type}</Typography>}
      </div>
    </TableCell>
  );
};
