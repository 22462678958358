import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import './App.css';
import { persistor, store } from './redux/store';
import Routes from './routing';

const theme = createTheme({
  palette: {
    primary: {
      main: '#EC425B',
    },
    secondary: {
      main: '#80C86E',
    },
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Routes />
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
