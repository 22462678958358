import { CardContainer } from '../universal/CardContainer';
import { PlanBtn } from './PlanBtn';
import { PlanCard } from './PlanCard';
import { SubcriptionTable } from './SubcriptionTable';

export const SubcriptionDetails = ({ type, data }) => {
  return (
    <CardContainer className="subscriptiondetail">
      <SubcriptionTable data={data} />
    </CardContainer>
  );
};
