export const EmptyNotification = () => {
  return (
    <div className="empty-notify-box">
      <div className="empty-parent">
        <img src="/icons/empty-notification.svg" alt="" />
        <h3>You have no notifications yet!</h3>
      </div>
    </div>
  );
};
