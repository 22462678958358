import Skeleton from '@mui/material/Skeleton';
import * as React from 'react';

export const SavedAddressSkeleton = () => {
  return (
    <div className="saveaddressskeleton">
      <div className="saveaddleft">
        <Skeleton variant="text" className="addline" />
        <Skeleton variant="text" className="addline" />
        <Skeleton variant="text" className="addline" />
        <Skeleton variant="text" className="addline" />
      </div>
      <div className="saveaddright">
        <Skeleton variant="rectangular" className="btnbox" />
        <Skeleton variant="rectangular" className="editbox" />
        <Skeleton variant="rectangular" className="delbox" />
      </div>
    </div>
  );
};
