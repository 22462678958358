import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { ContactNameDialog } from '../dialogs/ContactName';
import { CustomBtn } from '../universal/Buttons/CustomBtn';
import DarkBtn from '../universal/Buttons/DarkBtn';
import { CardContainer } from '../universal/CardContainer';
import { OrderPopUp } from './OrderPopUp';
import { OrderShippingLable } from './OrderShippingLable';
import {
  CancelCard,
  DisputeCard,
  RefunCard,
  ShippingInPersonOrderDetail,
  ShippingOrderDetial,
} from './ShippingOrderDetial';
import { DispatchModal } from './dispatchModal';

export const ShippingDetail = ({
  order,
  open,
  handleClickOpen,
  handleClose,
  loadingOrder,
  sellerAddress,
  handleDispatch,
  handleMarkAsDelivered,
  RequestShippingLabel,
  downloadShippingLabel,
  ConfirmPickupAddress,
  update,
}) => {
  const { userDetails } = useSelector((state) => state.user);
  const route = useNavigate();
  const addAddress = () => {
    route('/sales/shipping');
  };
  const printLable = ' Congrats On Your Sale! <br /><br /> Please confirm the address <br /> you’ll be shipping from.';
  const printLableError = ' Congrats On Your Sale! <br /><br /> Please add the address <br /> you’ll be shipping from.';
  const inPersonLabel = ' Congrats On Your Sale! <br /><br /> Please confirm your pickup address.';
  const readyToCollectLabel = 'Note! <br />';

  const printDescription =
    'Once you have confirmed your location, we will generate a shipping label for you within 24 hours. You will be notified once this is ready to download and print, with further instructions as to the final delivery steps.';
  const printDescriptionError =
    'Once you have added your location and confirmed, we will generate a shipping label for you within 24 hours. You will be notified once this is ready to download and print, with further instructions as to the final delivery steps.';
  const inPersonDescription =
    'This address will be securely maintained in our records and will not be disclosed to art lover or any individuals.';
  const readyToCollectDescription =
    'Once you confirm your readiness to collect, we will inform the art lover via email for artwork retrieval. Subsequently, you can coordinate the collection details by exchanging messages.';

  const dispatchLable = 'Dispatch Order';
  const dispatchDescription =
    'Your customer will get shipping updates via email. Please provide the tracking number which we will share with them.';
  const disputedStatus = order?.v_order_status == 'Disputed';
  const cancelledStatus = order?.v_order_status == 'Cancelled';
  const refundStatus = order?.v_order_status == 'Refund';
  const isAddress = sellerAddress?.v_country && sellerAddress?.v_region && sellerAddress?.v_address_line;

  return (
    <div>
      <CardContainer className="shipping-detail">
        <h2>Shipping Details</h2>
        {loadingOrder ? (
          <Typography variant="h4">Loading...</Typography>
        ) : order ? (
          <>
            {order?.is_in_person_delivery === 0 ? (
              <ShippingOrderDetial data={order} />
            ) : order?.is_pickup_address_confirmed === 1 ? (
              <ShippingInPersonOrderDetail data={order} inPerson />
            ) : (
              <ShippingInPersonOrderDetail data={order} />
            )}
            {order?.is_in_person_delivery === 1 && (
              <ContactNameDialog orderDetails ArtLoverData={order?.order?.buyer} order={order} />
            )}
            {order?.v_order_accepted === 'Accepted' &&
              order?.order?.is_in_person_delivery === 0 &&
              order?.is_pod_order === 0 && (
                <>
                  {order?.v_order_status === 'Processing' && order?.v_order_status !== 'Dispatched' && (
                    <OrderPopUp
                      open={open.shippingLabel}
                      openState="shippingLabel"
                      handleClickOpen={handleClickOpen}
                      handleClose={handleClose}
                      disableBtn={order?.v_order_status === 'Pending' || order?.v_order_status === 'Cancelled'}
                      topMargin="auto"
                      normalBtn={order?.shipping_label && order?.shipping_label_requested}
                      normalBtnTitle="Download Shipping Label"
                      downloadShippingLabel={downloadShippingLabel}
                      btnTitle={
                        !order?.shipping_label_requested ? 'Request Shipping Label' : 'Shipping Label Requested'
                      }
                      disabled={order?.shipping_label_requested}
                      className="darkbtn"
                    >
                      <OrderShippingLable
                        bottomMessage={isAddress ? printDescription : printDescriptionError}
                        message={isAddress ? printLable : printLableError}
                        UserCountry={sellerAddress?.v_country}
                        UserEmail={userDetails?.user?.v_email}
                        UserRegion={sellerAddress?.v_region}
                        houseNo={sellerAddress?.v_address_line}
                        roadNo={sellerAddress?.v_address_line2}
                        btnTitle={isAddress ? 'Confirm' : 'Add Shipping'}
                        onClick={isAddress ? RequestShippingLabel : addAddress}
                      />
                    </OrderPopUp>
                  )}
                  {order?.shipping_label &&
                    order?.shipping_label_requested &&
                    order?.v_order_status === 'Processing' && (
                      <OrderPopUp
                        open={open.dispatchModal}
                        openState="dispatchModal"
                        handleClickOpen={handleClickOpen}
                        handleClose={handleClose}
                        disableBtn={order?.v_order_status === 'Pending' || order?.v_order_status === 'Cancelled'}
                        topMargin="auto"
                        btnTitle="Mark as Dispatched"
                        className="darkbtn"
                      >
                        <DispatchModal
                          bottomMessage={dispatchDescription}
                          message={dispatchLable}
                          btnTitle="Confirm"
                          handleDispatch={handleDispatch}
                        />
                      </OrderPopUp>
                    )}
                  {order?.v_order_status === 'Dispatched' && (
                    <DarkBtn title="Mark as Delivered" onClick={() => handleMarkAsDelivered('0')} />
                  )}
                </>
              )}
            {order?.v_order_accepted === 'Accepted' &&
              order?.order?.is_in_person_delivery == '1' &&
              order?.is_pod_order === 0 && (
                <>
                  {order?.v_order_status === 'Processing' &&
                    order?.v_order_status !== 'Dispatched' &&
                    order?.is_pickup_address_confirmed != '1' && (
                      <OrderPopUp
                        open={open.shippingLabel}
                        openState="shippingLabel"
                        handleClickOpen={handleClickOpen}
                        handleClose={handleClose}
                        topMargin="auto"
                        btnTitle="Confirm Pickup Address"
                        className="darkbtn"
                      >
                        <OrderShippingLable
                          bottomMessage={inPersonDescription}
                          message={inPersonLabel}
                          UserCountry={sellerAddress?.v_country}
                          UserEmail={userDetails?.user?.v_email}
                          UserRegion={sellerAddress?.v_region}
                          houseNo={sellerAddress?.v_address_line}
                          roadNo={sellerAddress?.v_address_line2}
                          btnTitle={isAddress ? 'Confirm' : 'Add Shipping'}
                          onClick={isAddress ? ConfirmPickupAddress : addAddress}
                        />
                      </OrderPopUp>
                    )}
                  {order?.order?.is_in_person_delivery == '1' &&
                    order?.is_pickup_address_confirmed == '1' &&
                    order?.v_order_status === 'Processing' && (
                      <OrderPopUp
                        open={open.shippingLabel}
                        openState="shippingLabel"
                        handleClickOpen={handleClickOpen}
                        handleClose={handleClose}
                        topMargin="auto"
                        btnTitle="Ready to Collect"
                        className="darkbtn"
                      >
                        <OrderShippingLable
                          hideAddress
                          bottomMessage={readyToCollectDescription}
                          message={readyToCollectLabel}
                          btnTitle="Confirm"
                          onClick={(e) => {
                            handleDispatch(e, null, null, '1');
                          }}
                        />
                      </OrderPopUp>
                    )}
                  {order?.v_order_status === 'Dispatched' && (
                    <DarkBtn title="Mark as Collected" onClick={() => handleMarkAsDelivered('1')} />
                  )}
                </>
              )}
          </>
        ) : (
          <Typography variant="h4">No data</Typography>
        )}
      </CardContainer>

      {order?.is_pod_order === 0 && (
        <CardContainer>
          <p>
            Your order is currently in{' '}
            <b>
              {order?.v_order_status === 'Dispatched' && order?.is_in_person_delivery == '1'
                ? 'Ready To Collect'
                : order?.v_order_status === 'Completed' && order?.is_in_person_delivery == '1'
                ? 'Collected'
                : order?.v_order_status}
            </b>{' '}
            status. If you encounter any issues with it, please contact our
            <a className="" href="/contact-us">
              {' '}
              support
            </a>{' '}
            team for assistance.
          </p>
        </CardContainer>
      )}

      {order?.is_pod_order === 0 && (
        <>
          <div style={{ display: disputedStatus ? '' : 'none' }}>
            <CardContainer>{disputedStatus && <DisputeCard data={order} update={update} />}</CardContainer>
          </div>

          <div style={{ display: cancelledStatus ? '' : 'none' }}>
            <CardContainer>{cancelledStatus && <CancelCard data={order} />}</CardContainer>
          </div>

          <div style={{ display: refundStatus ? '' : 'none' }}>
            <CardContainer>{refundStatus && <RefunCard data={order} />}</CardContainer>
          </div>
        </>
      )}
    </div>
  );
};
