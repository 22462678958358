import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import React from 'react';

export const UploadImgCard = React.memo(
  ({ className, iconImg, mainTitle, description, onClick, image, handleRemoveImage, status }) => {
    const gapChange = image.length > 0;
    const directionFlex = image.length > 0;

    return (
      <div
        tabIndex={0}
        role="button"
        className={className}
        onClick={onClick}
        style={{ gap: gapChange && 12, flexDirection: directionFlex && 'row' }}
      >
        {image.length > 0 ? (
          image?.map((file) => {
            return (
              <div key={file.id} style={{ position: 'relative' }} className="artwork-upload-card">
                <div tabIndex={0} className="closebtn" role="button" onClick={(e) => e.stopPropagation()}>
                  <CloseIcon
                    style={{ margin: '0 0 0 auto', cursor: 'pointer' }}
                    onClick={() => handleRemoveImage(status, file)}
                  />
                </div>
                <img src={file.preview} alt={`${file.name}`} className="user-upload" />
              </div>
            );
          })
        ) : (
          <img src={iconImg} alt="icon" />
        )}
        {image.length === 0 && (
          <>
            <Typography variant="h6">{mainTitle}</Typography>
            <Typography paragraph>{description}</Typography>
          </>
        )}
      </div>
    );
  },
);
