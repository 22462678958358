import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { images } from '../../assets';
import CustomTable from '../../components/universal/CustomTable';
import PaginationBox from '../../components/universal/PaginationBox';
import { ReferralToggleButtons } from '../../components/universal/ReferralToggle';
import { globalWindow, pageLimitOptions } from '../../core/constant';
import AxiosAll from '../../services/axiosAll';
import navigator from '../../utils/navigator';

const ReferralLogs = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const searchParam = new URLSearchParams(useLocation().search);
  const pageParams = searchParam.get('page');

  const [referralLog, setReferralLog] = useState([]);
  const [creditBalanceLog, setCreditBalanceLog] = useState([]);
  const [page, setPage] = useState(pageParams ? +pageParams : 1);
  const [limit, setLimit] = useState(12);
  const [parameters, setParameters] = useState({});
  const [logType, setLogType] = useState('referral');

  const { userDetails } = useSelector((state) => state.user);

  const isShowPagination =
    logType === 'referral' ? referralLog?.total > limit : creditBalanceLog?.balance_history?.total > limit;

  const getReferralLog = useCallback(async () => {
    try {
      const res = await AxiosAll('get', `artist/myReferrals?limit=${limit}&page=${page}`, null, userDetails?.token);
      if (res?.data?.statusCode === 200) {
        setReferralLog(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, [limit, page, userDetails?.token]);

  const getCreditBalanceLog = useCallback(async () => {
    try {
      const res = await AxiosAll(
        'get',
        `artist/myCreditBalanceLog?limit=${limit}&page=${page}`,
        null,
        userDetails?.token,
      );
      if (res?.data?.statusCode === 200) {
        setCreditBalanceLog(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, [limit, page, userDetails?.token]);

  const onPageChange = (e, pg) => {
    setPage(pg);
    navigate(`${pathname}?${navigator(parameters, 'page', pg)}`);
  };

  const onPageLimitChange = (e) => {
    setPage(1);
    setLimit(e.target.value);
    navigate(`${pathname}?${navigator(parameters, 'page', 1)}`);
  };

  const ReferralCells = [{ label: 'Amount' }, { label: 'Description' }, { label: 'Code' }, { label: 'Date' }];

  const BalanceCells = [{ label: 'Amount' }, { label: 'Description' }, { label: 'Type' }, { label: 'Date' }];

  useEffect(() => {
    if (userDetails.token) {
      if (logType === 'referral') {
        getReferralLog();
      } else {
        getCreditBalanceLog();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCreditBalanceLog, getReferralLog, userDetails.token]);

  useEffect(() => {
    if (userDetails.token) {
      getCreditBalanceLog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    globalWindow?.scrollTo({
      // eslint-disable-next-line no-unsafe-optional-chaining
      top: document?.getElementById('log-area')?.offsetTop - 40,
      behavior: 'smooth',
    });
  }, [page, limit]);

  return (
    <div className="contactpage referral-contact" id="log-area">
      <div className="btn-toggle-section">
        <ReferralToggleButtons
          logType={logType}
          setLogType={setLogType}
          setPage={setPage}
          setLimit={setLimit}
          pathname={pathname}
          parameters={parameters}
        />
      </div>
      <div className="referral-log">
        <CustomTable
          referralLog={logType === 'referral'}
          creditBalanceLog={logType === 'credit'}
          isEmpty={logType === 'referral' ? referralLog?.data?.length < 1 : creditBalanceLog?.balance_history?.data < 1}
          rows={logType === 'referral' ? referralLog?.data : creditBalanceLog?.balance_history?.data}
          headCells={logType === 'referral' ? ReferralCells : BalanceCells}
          heading={logType === 'referral' ? 'Referral Logs' : 'Credit Balance Logs'}
          isShowHeading
          invoiceText="Credit Balance"
          balanceText={Math.abs(creditBalanceLog?.credit_total_balance?.toFixed(2))}
          imgInfo={images.Info}
        />
        {isShowPagination && (
          <PaginationBox
            count={logType === 'referral' ? referralLog?.last_page : creditBalanceLog?.balance_history?.last_page}
            page={page}
            onChange={onPageChange}
            value={limit}
            pageLimit={pageLimitOptions}
            onChangePageLimit={onPageLimitChange}
          />
        )}
      </div>
    </div>
  );
};
export default ReferralLogs;
