import { Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { ReviewFeedback } from '../../components/all_reviews/ReviewFeedback';
import { ReviewsChannel } from '../../components/all_reviews/ReviewsChannel';
import { CardContainer } from '../../components/universal/CardContainer';
import PaginationBox from '../../components/universal/PaginationBox';
import { globalWindow, pageLimitOptions } from '../../core/constant';
import { useWindowSize } from '../../hooks/window';
import { MAX_MOBILE_WIDTH } from '../../models/breakpoints.model';
import { request } from '../../services/request';
import { NoDataContainer } from '../../components/universal/NoDataContainer';

const AllReviews = () => {
  const [pop, setPop] = useState(false);
  const [singleMessageBox, setSingleMessageBox] = useState(true);
  const [reviews, setReviews] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(12);
  const [activeCard, setActiveCard] = useState(null);
  const [isReviewAdded, setIsReviewAdded] = useState(false);
  const [scrollEnabled, setScrollEnabled] = useState(false);
  const isShowNotifyPagination = reviews?.data?.length > 0 && reviews?.last_page > 1;

  const { userDetails } = useSelector((state) => state.user);

  const navigate = useNavigate();

  const active = {
    border: '1px solid #f5445e',
  };

  const { width } = useWindowSize();
  const isMobile = (width || 0) < MAX_MOBILE_WIDTH;

  const onPageChange = (e, pg) => {
    setPage(pg);
    const child = document.getElementById('review-channel-child');
    if (child) {
      child.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const onPageLimitChange = (e) => {
    setPage(1);
    setLimit(e.target.value);
    const child = document.getElementById('review-channel-child');
    if (child) {
      child.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  // call me for this perticular useEffect @brajesh
  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 0 && singleMessageBox) {
        setSingleMessageBox(false);
      } else if (window.scrollY === 0 && !singleMessageBox) {
        setSingleMessageBox(true);
      }
    }
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [singleMessageBox]);

  const getReviews = useCallback(async () => {
    try {
      const res = await request({
        url: '/artist/artworkReviews',
        method: 'GET',
        token: userDetails?.token,
        params: {
          page,
          limit,
        },
      });

      setReviews(res.data);
    } catch (error) {
      console.log(error);
    }
  }, [limit, page, userDetails?.token]);

  useEffect(() => {
    getReviews();
  }, [getReviews, isReviewAdded]);

  const selectHandler = (id) => {
    if (isMobile) {
      navigate(`/sales/reviews/${id}`);
    } else {
      setActiveCard(id);
    }
  };

  useEffect(() => {
    if (reviews?.data?.length > 0) {
      setActiveCard(reviews?.data?.[0]?.id);
    }
  }, [reviews]);

  useEffect(() => {
    // if there enough messages to scroll then enable scroll
    const child = document.getElementById('review-channel-child');
    if (child) {
      if (child.scrollHeight > child.clientHeight) {
        setScrollEnabled(true);
      } else {
        setScrollEnabled(false);
      }
    }
  }, [reviews]);

  useEffect(() => {
    globalWindow?.scrollTo({
      // eslint-disable-next-line no-unsafe-optional-chaining
      top: document?.getElementById('top')?.offsetTop - 40,
      behavior: 'smooth',
    });
  }, [page, limit]);

  return (
    <div className="allreviews">
      <div className="searchbtninput" id="top">
        <div className="font25px mobilehide">Your Reviews</div>
        <div className="font25px mobileshow">Your Reviews</div>
      </div>
      <div className="message-section">
        {/* {!isMobile ? ( */}
        {reviews?.data?.length > 0 ? (
          <CardContainer height={reviews?.data?.length > 0 && 775} gap={45}>
            <div
              className="scroll-dir"
              style={{ overflowY: scrollEnabled ? 'scroll' : 'hidden' }}
              id="review-channel-child"
            >
              <ReviewsChannel
                border={active}
                // SelectHandler={() => setPop(true)}
                reviews={reviews?.data}
                selectHandler={selectHandler}
                activeCard={activeCard}
                leftMar={scrollEnabled ? 22 : 0}
              />
            </div>
            {!isMobile && (
              <div className="send-message">
                <ReviewFeedback
                  reviews={reviews?.data}
                  activeCard={activeCard}
                  setIsReviewAdded={setIsReviewAdded}
                  isReviewAdded={isReviewAdded}
                />
              </div>
            )}
          </CardContainer>
        ) : (
          <NoDataContainer
            cardHeight={reviews?.data?.length > 0 && 775}
            msgText="No data"
            className="empty-review"
            gapBtw={45}
          />
        )}
      </div>
      {isShowNotifyPagination && (
        <PaginationBox
          count={reviews?.last_page}
          page={page}
          onChange={onPageChange}
          value={limit}
          pageLimit={pageLimitOptions}
          onChangePageLimit={onPageLimitChange}
        />
      )}
    </div>
  );
};

export default AllReviews;
