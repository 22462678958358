/* eslint-disable react/jsx-no-useless-fragment */

import { Button, Link } from '@mui/material';

const WhiteBtn = ({ title, onClick, link }) => {
  return (
    <>
      {link ? (
        <Link className="whitebtn" href={link} target="_blank">
          {title}
        </Link>
      ) : (
        <Button className="whitebtn" onClick={onClick}>
          {title || 'Button Text'}
        </Button>
      )}
    </>
  );
};

export default WhiteBtn;
