import { Container, Grid } from '@mui/material';
import React from 'react';
import { ForgotPasswordEmail } from '../../components/LoginSection/ForgotPasswordEmail';
import LocationRightText from '../../components/LoginSection/LocationRightText';

const ForgotPassword = () => {
  return (
    <div className="loginpage forgotpage">
      <Container className="all-screen-container">
        <div className="login_box">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <div className="loginregpage forgotpassword">
                <ForgotPasswordEmail />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} className="mobilehide">
              <LocationRightText
                noImage
                text={
                  <span>
                    Oops! <br />
                    No problem,
                    <br /> let’s reset your <br />
                    password.
                  </span>
                }
              />
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
};

export default ForgotPassword;
