import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { images } from '../../assets';
import { updateUser } from '../../redux/actions/user';
import AxiosAll from '../../services/axiosAll';
import { request } from '../../services/request';
import notify from '../../utils/notify';
import DarkBtn from '../universal/Buttons/DarkBtn';
import { AutoCompeletTextField } from '../universal/InputTypeText/AutoCompeletTextField';
import SimpleSelectBox from '../universal/InputTypeText/SelectBox';
import ImageCard from '../universal/imageCard';
import WhiteBtn from '../universal/Buttons/whiteBtn';

const AboutYourself = () => {
  const [dropdowns, setDropdowns] = useState([]);
  const [location, setLocation] = useState();
  const [styles, setStyles] = useState(0);
  const [mediums, setMediums] = useState(0);
  const [error, setError] = useState({});
  const [user, setUser] = useState({
    profilePicture: '',
  });
  const { userDetails } = useSelector((state) => state.user);

  const profilePictureRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getDropdowns = async () => {
    const res = await AxiosAll('get', 'dropdowns?type[0]=mediums&type[1]=cities&type[2]=styles');
    setDropdowns(res?.data?.data);
  };

  useEffect(() => {
    getDropdowns();
  }, []);

  const updateUserProfile = async (data) => {
    try {
      const res = await request({
        url: '/updateProfile',
        method: 'POST',
        data,
        token: userDetails?.token,
      });
      dispatch(updateUser(res.data));
      notify(res?.message, 'success');
    } catch (err) {
      console.log(err);
    }
  };

  const submitPersonalizeInfo = async () => {
    if (!location || !styles || !mediums || location === 0 || styles === 0 || mediums === 0) {
      setError({
        location: !location ? 'Location is required' : '',
        styles: !styles ? 'Style is required' : '',
        mediums: !mediums ? 'Mediums is required' : '',
      });
      return;
    }

    const formData = new FormData();
    if (user?.profilePicture instanceof File) {
      formData.append('v_image', user.profilePicture);
      updateUserProfile(formData);
    }

    const res = await AxiosAll(
      'post',
      'signupNextStep',
      {
        v_location: location,
        v_style: styles,
        v_medium: mediums,
        user_type: 'seller',
      },
      userDetails?.token,
    );

    if (res?.data?.statusCode === 200) {
      notify(res?.data?.message, 'success');
      dispatch(updateUser({ ...userDetails?.user, is_completed_seller: 1 }));
      navigate(`/dashboard`);
    } else {
      notify(res?.data?.message, 'error');
    }
  };

  const handleProfilePicURL = () => {
    if (user.profilePicture instanceof File) {
      return URL.createObjectURL(user.profilePicture);
    }
    return user.profilePicture;
  };

  const handleRemoveProfilePic = () => {
    if (user.profilePicture) {
      profilePictureRef.current.value = '';
      setUser((prev) => ({ ...prev, profilePicture: '' }));
    }
  };

  const handleSkip = async () => {
    const res = await AxiosAll(
      'post',
      'signupNextStep',
      {
        skip: 1,
      },
      userDetails?.token,
    );

    if (res?.data?.statusCode === 200) {
      notify(res?.data?.message, 'success');
      navigate(`/dashboard`);
    } else {
      notify(res?.data?.message, 'error');
    }
  };
  return (
    <div className="aboutyourself">
      <p className="font20px well-text">We’ll use this to create your bio!</p>
      <div className="signuptab aboutsection">
        <div className="font18px nearest-text">Nearest City</div>
        <AutoCompeletTextField
          options={dropdowns?.cities?.map((city) => {
            return { id: city?.id, label: city?.v_title };
          })}
          value={location}
          onChange={(e, value) => {
            setLocation(value?.id);
            if (value?.id) {
              setError({ ...error, location: '' });
            }
          }}
        />
        {error?.location && <p className="error_text">{error?.location}</p>}
        <div className="font18px nearest-text">Style</div>
        <SimpleSelectBox
          options={dropdowns?.styles?.map((sty) => {
            return { id: sty?.id, name: sty?.v_name };
          })}
          value={styles}
          onChange={(e) => {
            setStyles(e.target.value);
            if (e.target.value) {
              setError({ ...error, styles: '' });
            }
          }}
          defaultOption="Pick From List"
          defaultValue={0}
        />
        {error?.styles && <p className="error_text">{error?.styles}</p>}
        <div className="font18px nearest-text">Medium</div>
        <SimpleSelectBox
          options={dropdowns?.mediums?.map((md) => {
            return { id: md?.id, name: md?.v_name };
          })}
          value={mediums}
          onChange={(e) => {
            setMediums(e.target.value);
            if (e.target.value) {
              setError({ ...error, mediums: '' });
            }
          }}
          defaultOption="Pick From List"
          defaultValue={0}
        />
        {error?.mediums && <p className="error_text">{error?.mediums}</p>}
        <div className="profilesection">
          <div className="profilepic">
            <ImageCard
              image={user?.profilePicture ? handleProfilePicURL() : images.avtarPlaceholder}
              alt="profile pic"
            />
            <input
              hidden
              type="file"
              name="profilePicture"
              ref={profilePictureRef}
              accept="image/x-png ,image/jpeg"
              onChange={(e) => {
                setUser((prev) => ({
                  ...prev,
                  profilePicture: e.target.files[0],
                }));
              }}
            />
            {!user?.profilePicture && (
              <div className="changebtn">
                <WhiteBtn
                  title="Add Your Profile Picture"
                  onClick={() => {
                    profilePictureRef.current.click();
                  }}
                />
              </div>
            )}
            {user?.profilePicture instanceof File && <WhiteBtn title="Remove" onClick={handleRemoveProfilePic} />}
          </div>
        </div>

        <DarkBtn title="List Your Work" onClick={submitPersonalizeInfo} />

        {/* <div className="font20px skiptext">
          <span
            role="button"
            tabIndex={0}
            style={{
              cursor: 'pointer',
            }}
            onClick={handleSkip}
          >
            Skip For Now
          </span>
        </div> */}
      </div>
    </div>
  );
};

export default AboutYourself;
