import React, { useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { Footer } from '../components/universal/footer';
import { Header } from '../components/universal/header';

import ScrollToTop from '../components/ScrollTotop';
import { globalWindow } from '../core/constant';
import { deleteSubscription, setSubscription } from '../redux/actions/subscription';
import { deleteUser } from '../redux/actions/user';
import AxiosAll from '../services/axiosAll';
import AppRoutes from './app';
import PublicRoutes from './public';
import { request } from '../services/request';

const Routes = () => {
  const params = new URLSearchParams(globalWindow?.location?.search);
  const isImpersonate = params.get('isImpersonate');
  const tokenQuery = params.get('token');
  const keyQuery = params.get('key');
  const { userDetails } = useSelector((state) => state.user);
  const [headerData, setHeaderData] = useState({});
  const today = new Date()?.toISOString()?.slice(0, 10);

  const dispatch = useDispatch();

  const impersonating = useMemo(() => {
    return localStorage.getItem('impersonating') === 'true';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  // const getHeaderData = async () => {
  //   try {
  //     const res = await AxiosAll('get', 'getHeaderData');
  //     const data = res?.data?.data?.header;
  //     setHeaderData(data);
  //   } catch (error) {
  //     console.error('Error fetching data from the API', error);
  //   }
  // };

  const getHeaderData = async () => {
    const res = await request({
      url: '/dropdowns',
      method: 'GET',
      params: {
        type: ['categories'],
      },
    });
    const data = res?.data;
    setHeaderData(data);
    return data;
  };

  const getCurrentSubscription = async (token) => {
    const res = await AxiosAll('GET', 'artist/artist_current_active_subscription_plan_detail', null, token);
    if (res?.data?.statusCode === 200) {
      localStorage.setItem('visitedDate', today);
      dispatch(setSubscription(res?.data?.data));
    }
  };

  useEffect(() => {
    if (userDetails?.token && isImpersonate && tokenQuery && keyQuery) {
      dispatch(deleteUser());
      dispatch(deleteSubscription());
      localStorage.removeItem('impersonating');
    }
  }, [dispatch, isImpersonate, keyQuery, tokenQuery, userDetails?.token]);

  useEffect(() => {
    if (!userDetails?.token && impersonating) {
      localStorage.removeItem('impersonating');
    }
  }, [userDetails?.token, impersonating]);

  useEffect(() => {
    const websiteVisited = localStorage.getItem('visitedDate');
    if (userDetails?.token && !isImpersonate && websiteVisited && websiteVisited !== today) {
      getCurrentSubscription(userDetails?.token);
    } else if (userDetails?.token && !isImpersonate && !websiteVisited) {
      getCurrentSubscription(userDetails?.token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails?.token, isImpersonate, today]);

  useEffect(() => {
    getHeaderData();
    // getDropdowns();
  }, []);

  return (
    <BrowserRouter>
      {impersonating && (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          You are currently impersonating {userDetails?.user?.full_name}
        </div>
      )}
      <Header headerData={headerData} />
      <Toaster />
      <ScrollToTop />
      {userDetails?.token ? <AppRoutes /> : <PublicRoutes />}
      <Footer />
    </BrowserRouter>
  );
};

export default Routes;
