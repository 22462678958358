import { Grid, TextField } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { images } from '../../../../assets';
import { globalWindow, notifyMessages } from '../../../../core/constant';
import notify from '../../../../utils/notify';
import LoginSignInDialog from '../../../dialogs/JoinOrSignIn';
import { ResponsiveDrawer } from '../../MobileMenu';

export const MobileViewheader = ({ headerData, search, setSearch, searchQuery, userDetails }) => {
  const { pathname } = useLocation();

  return (
    <div className="mobileshow">
      <Grid container spacing={2}>
        <Grid item xs={3} className="mobilemenudiv mobileshow">
          <ResponsiveDrawer headerData={headerData} />
        </Grid>
        <Grid item xs={6} sm={5}>
          <div
            tabIndex={0}
            role="button"
            onClick={() => {
              if (pathname.includes('personalize') && !userDetails?.user?.is_completed_seller) {
                notify(notifyMessages?.completePersonalization, 'error');
                return;
              }
              globalWindow.location.href = `${process.env.REACT_APP_USER_APP_URL}`;
            }}
            className="mainlogodiv"
            style={{ position: 'relative' }}
          >
            <img src={images?.mainLogo} alt="top bar" className="mainlogo" />
          </div>
        </Grid>

        <Grid item xs={!userDetails?.token ? 1.4 : 3} sm={2} className="signbtnright">
          <LoginSignInDialog />
        </Grid>
        {!userDetails?.token && (
          <Grid item xs={1.6} sm={1} className="alignmentcart">
            <div className="carticontop">
              <Link href={`${process.env.REACT_APP_USER_APP_URL}cart`} to={`${process.env.REACT_APP_USER_APP_URL}cart`}>
                <img src={images.cartIcon} alt="carticon" /> &nbsp;
                <span className="valuecart">(0)</span>
              </Link>
            </div>
          </Grid>
        )}
        {!userDetails?.token && (
          <Grid item xs={12} className="mobileshow">
            <div className="topsearchdiv" style={{ position: 'relative' }}>
              <TextField
                id="outlined-search"
                placeholder="Search Art & Artists"
                type="text"
                className="topsearchbar"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' && search) {
                    globalWindow?.open(searchQuery, '_blank');
                  }
                }}
              />
              <Link
                href
                // href={search ? globalWindow?.open(searchQuery, '_blank') : 'javascript:;'}
                // to={search ? globalWindow?.open(searchQuery, '_blank') : 'javascript:;'}
                style={{ position: 'relative' }}
              >
                <div tabIndex={0} role="button" onClick={() => globalWindow?.open(searchQuery, '_blank')}>
                  <img src={images.searchIcon} alt="test" className="searciconimg" />
                </div>
              </Link>
            </div>
          </Grid>
        )}
      </Grid>
    </div>
  );
};
