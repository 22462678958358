import InfoIcon from '@mui/icons-material/Info';
import { useState } from 'react';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { CardContainer } from '../universal/CardContainer';
import { ArtworkDetail } from './ArtworkDetail';
import { OrderDetailTable } from './OrderDetailTable';

export const OrderDetail = ({ order, loadingOrder }) => {
  const [open1, setOpen1] = useState(false);

  return (
    <CardContainer className="order-detail">
      <div className="order-heading">
        <h2 style={{ display: 'flex' }}>
          Order Detail
          {order?.is_pod_order === 1 && (
            <>
              <span className="mobilehide">
                <Tooltip title="We will add the commission once we accept the order">
                  <IconButton sx={{ verticalAlign: 'top', p: 0 }}>
                    <InfoIcon style={{ color: '#f5445e' }} />
                  </IconButton>
                </Tooltip>
              </span>
              <span className="mobileshow">
                <Tooltip title="We will add the commission once we accept the order" open={open1}>
                  <IconButton sx={{ verticalAlign: 'top', p: 0 }} onClick={() => setOpen1(!open1)}>
                    <InfoIcon style={{ color: '#f5445e' }} />
                  </IconButton>
                </Tooltip>
              </span>
            </>
          )}
        </h2>
      </div>
      {loadingOrder ? (
        <Typography variant="h4">Loading...</Typography>
      ) : order ? (
        <>
          <OrderDetailTable order={order} />
          <ArtworkDetail item={order} seller={order?.seller} />
        </>
      ) : (
        <Typography variant="h4">No data</Typography>
      )}
    </CardContainer>
  );
};
