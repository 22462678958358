import { useFormik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
import DarkBtn from '../universal/Buttons/DarkBtn';
import InputTypeEmail from '../universal/InputTypeText/InputTypeEmail';
import AxiosAll from '../../services/axiosAll';
import notify from '../../utils/notify';
import { globalWindow } from '../../core/constant';

export const ForgotPasswordEmail = () => {
  const [loading, setLoading] = useState(false);

  const initialValues = {
    email: '',
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Invalid email address')
      // check if email domain is valid
      .matches(
        // eslint-disable-next-line max-len
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'Invalid email address',
      )
      .required('Email is required')
      .trim(),
  });

  // url is hardcode needs to change oon different scenarios
  const onSubmit = async values => {
    setLoading(true);
    // const baseURL = 'https://dev.cosimo.next.skunktest.work';

    const res = await AxiosAll('post', 'forgetPassword', {
      email: values?.email.trim(),
      url: `${globalWindow?.location?.origin}/reset-password`,
    });
    if (res.data.status === 'Success') {
      notify(res.data.message, 'success');
      formik.resetForm();
      setLoading(false);
    } else {
      setLoading(false);
      notify(res.data.message, 'error');
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <div className="loginpassword">
      <p className="font30px mobileshow textcenter">
        Oops!
        <br /> No problem,
        <br /> let’s reset your
        <br /> password.
      </p>
      <p className="font20px"> Enter your email address and we’ll send you a link to reset your password</p>
      <div className="signuptab password">
        <form onSubmit={formik?.handleSubmit}>
          <InputTypeEmail
            placeholder="Email Address"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <DarkBtn loading={loading} title="Reset Password" type="submit" />
        </form>
      </div>
    </div>
  );
};
