import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router';
import * as Yup from 'yup';
import { SelectBoxLabel } from '../InputTypeText/SelectBoxLabel';
import { UpgradePlanContainer } from '../universal/BlurContainer';
import WhiteBtn from '../universal/Buttons/whiteBtn';
import { CardContainer } from '../universal/CardContainer';
import TextFieldInputLabel from '../universal/InputTypeText/TextFieldLabel';
import sweatAlertBox from '../universal/SweetAlertBox';

export const AddArtworkSize = React.memo(
  ({ dropdowns, setArtworkSizes, artworkSizesRef, fetchedData, setGoodToGo, deletedSizes, setDeletedSizes, plan }) => {
    const navigate = useNavigate();

    const initialValues = {
      artworkSize: [
        {
          allow_access: 1,
          height: '',
          width: '',
          len: '',
          editionType: '',
          editionSize: '',
          quantityAvailable: '',
          price: '',
          salePrice: '',
          shippingSize: '',
        },
      ],
    };

    const validationSchema = Yup.object().shape({
      artworkSize: Yup.array().of(
        Yup.object().shape({
          height: Yup.number().min(0.01, 'Height must be greater than 0').required('Height is required'),
          width: Yup.number().min(0.01, 'Width must be greater than 0').required('Width is required'),
          len: Yup.number().min(0.01, 'Length must be greater than 0').required('Length is required'),
          editionType: Yup.string().required('Edition Type is required'),
          editionSize: Yup.number().required('Edition Size is required'),
          quantityAvailable: Yup.number()
            .min(0, 'Quantity must be greater than or equal to 0')
            .required('Quantity is required')
            .test('quantity', 'Please enter a valid quantity', (value) => Number.isInteger(value)),
          price: Yup.number().min(5, 'Price must be greater than or equal to 5').required('Price is required'),
          salePrice: Yup.number()
            .nullable()
            .when('price', (price, schema) => {
              return price
                ? schema.test({
                    test: (salePrice) => {
                      if (!salePrice || salePrice === 0) return true;
                      return salePrice && salePrice >= 5 && salePrice < price;
                    },
                    message: 'sale price must be less than price and greater than or equal to 5',
                  })
                : schema;
            }),
          shippingSize: Yup.string().required('Shipping Size is required'),
        }),
      ),
    });

    const calculatePrice = useCallback((price) => {
      if (price <= 0) {
        return '0.00';
      }

      let shippingFeePercentage;
      let minimumShippingFee;

      switch (true) {
        case price < 100:
          shippingFeePercentage = 0.15;
          minimumShippingFee = 1.99;
          break;
        case price < 250:
          shippingFeePercentage = 0.12;
          minimumShippingFee = 1.99;
          break;
        case price < 1000:
          shippingFeePercentage = 0.08;
          minimumShippingFee = 1.99;
          break;
        default:
          shippingFeePercentage = 0.05;
          minimumShippingFee = 1.99;
      }

      const shippingFee = price * shippingFeePercentage + minimumShippingFee;

      const finalShippingFee = shippingFee > 126.99 ? 126.99 : shippingFee < 11.65 ? 11.65 : shippingFee;

      // const estimatedPrice = (price + finalShippingFee).toFixed(2);

      return finalShippingFee.toFixed(2);
    }, []);

    const handleRemoveSize = (size, index) => {
      size.id && setDeletedSizes([...deletedSizes, size.id]);
      const newSizes = formik?.values?.artworkSize?.filter((item, i) => item.id !== size.id || i !== index);
      formik?.setFieldValue('artworkSize', newSizes);
      // scroll to previous size
      const element = document.getElementById(`size-${index - 1}`);
      element && element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    };

    const formik = useFormik({
      initialValues,
      validationSchema,
      onSubmit: (values) => {
        setGoodToGo((prev) => ({ ...prev, sizes: true }));
        setArtworkSizes(values.artworkSize);
      },
    });

    const error = document?.querySelector('.error_text');

    useEffect(() => {
      error && error.scrollIntoView({ behavior: 'smooth', block: 'center' });
      setGoodToGo((prev) => ({ ...prev, sizes: false }));
    }, [error, setGoodToGo]);

    useEffect(() => {
      if (fetchedData?.artworkSize) {
        formik.setFieldValue('artworkSize', fetchedData?.artworkSize);
        setArtworkSizes(fetchedData?.artworkSize);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchedData]);

    const artworkSizeFields = formik?.values?.artworkSize;

    return (
      <form onSubmit={formik?.handleSubmit}>
        {artworkSizeFields.map((size, index) => {
          // const price = calculatePrice(size?.price);
          return size?.allow_access ? (
            <CardContainer className="addartworksize" key={`artworkSize-${index}`}>
              {artworkSizeFields.length > 1 && (
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                  <div style={{ cursor: 'pointer' }}>
                    <CloseIcon onClick={() => handleRemoveSize(size, index)} />
                  </div>
                </div>
              )}
              <Typography paragraph>Size & Pricing</Typography>
              <div className="size" id={`size-${index}`}>
                <TextFieldInputLabel
                  type="number"
                  // isRequired
                  placeholder="Height * (cm)"
                  name={`artworkSize[${index}].height`}
                  value={size?.height}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  inputProps={{ min: 0.01, step: 0.01 }}
                  onKeyPress={(event) => {
                    if (event?.key === '-' || event?.key === '+') {
                      event.preventDefault();
                    }
                  }}
                  error={
                    formik?.touched?.artworkSize?.[index]?.height &&
                    Boolean(formik?.errors?.artworkSize?.[index]?.height)
                  }
                  helperText={
                    formik?.touched?.artworkSize?.[index]?.height && formik?.errors?.artworkSize?.[index]?.height
                  }
                />
                <Typography paragraph>x</Typography>
                <TextFieldInputLabel
                  type="number"
                  // isRequired
                  placeholder="Width * (cm)"
                  name={`artworkSize[${index}].width`}
                  value={size?.width}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  inputProps={{ min: 0.01, step: 0.01 }}
                  onKeyPress={(event) => {
                    if (event?.key === '-' || event?.key === '+') {
                      event.preventDefault();
                    }
                  }}
                  error={
                    formik?.touched?.artworkSize?.[index]?.width && Boolean(formik?.errors?.artworkSize?.[index]?.width)
                  }
                  helperText={
                    formik?.touched?.artworkSize?.[index]?.width && formik?.errors?.artworkSize?.[index]?.width
                  }
                />
                <Typography paragraph>x</Typography>
                <TextFieldInputLabel
                  type="number"
                  // isRequired
                  placeholder="Length * (cm)"
                  name={`artworkSize[${index}].len`}
                  value={size?.len}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  inputProps={{ min: 0.01, step: 0.01 }}
                  onKeyPress={(event) => {
                    if (event?.key === '-' || event?.key === '+') {
                      event.preventDefault();
                    }
                  }}
                  error={
                    formik?.touched?.artworkSize?.[index]?.len && Boolean(formik?.errors?.artworkSize?.[index]?.len)
                  }
                  helperText={formik?.touched?.artworkSize?.[index]?.len && formik?.errors?.artworkSize?.[index]?.len}
                />
              </div>
              <SelectBoxLabel
                lableName="Edition Type ? *"
                className="type-select"
                // isRequired
                options={[
                  { id: 1, name: 'One of One' },
                  { id: 2, name: 'Limited Edition' },
                  { id: 3, name: 'Unlimited Edition' },
                ]}
                name={`artworkSize[${index}].editionType`}
                value={size?.editionType}
                onChange={(e) => {
                  if (e?.target?.value === 1) {
                    formik?.setFieldValue(`artworkSize[${index}].editionSize`, 1);
                    formik?.setFieldTouched(`artworkSize[${index}].editionSize`, false);
                    formik?.setFieldValue(`artworkSize[${index}].quantityAvailable`, 1);
                    formik?.setFieldTouched(`artworkSize[${index}].quantityAvailable`, false);
                  } else if (e?.target?.value === 3) {
                    formik?.setFieldValue(`artworkSize[${index}].editionSize`, 10000);
                    formik?.setFieldTouched(`artworkSize[${index}].editionSize`, false);
                  } else {
                    formik?.setFieldValue(`artworkSize[${index}].editionSize`, '');
                    formik?.setFieldValue(`artworkSize[${index}].quantityAvailable`, '');
                  }
                  formik?.handleChange(e);
                }}
                onBlur={formik?.handleBlur}
                error={
                  formik?.touched?.artworkSize?.[index]?.editionType &&
                  Boolean(formik?.errors?.artworkSize?.[index]?.editionType)
                }
                helperText={
                  formik?.touched?.artworkSize?.[index]?.editionType &&
                  formik?.errors?.artworkSize?.[index]?.editionType
                }
                // defaultOption="Edition Type ?"
                // defaultValue={0}
              />
              {size?.editionType === 3 ? null : (
                <TextFieldInputLabel
                  type="number"
                  // isRequired
                  placeholder="Edition Size *"
                  name={`artworkSize[${index}].editionSize`}
                  value={size?.editionSize}
                  disabled={size?.editionType === 1}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  inputProps={{ min: 0, step: 0.01 }}
                  onKeyPress={(event) => {
                    if (event?.key === '-' || event?.key === '+') {
                      event.preventDefault();
                    }
                  }}
                  error={
                    formik?.touched?.artworkSize?.[index]?.editionSize &&
                    Boolean(formik?.errors?.artworkSize?.[index]?.editionSize)
                  }
                  helperText={
                    formik?.touched?.artworkSize?.[index]?.editionSize &&
                    formik?.errors?.artworkSize?.[index]?.editionSize
                  }
                />
              )}
              <TextFieldInputLabel
                type="number"
                // isRequired
                placeholder="Quantity Available *"
                name={`artworkSize[${index}].quantityAvailable`}
                value={size?.quantityAvailable}
                disabled={size?.editionType === 1}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                inputProps={{ min: 0, step: 0 }}
                onKeyPress={(event) => {
                  if (event?.key === '-' || event?.key === '+' || event?.key === '.') {
                    event.preventDefault();
                  }
                }}
                error={
                  formik?.touched?.artworkSize?.[index]?.quantityAvailable &&
                  Boolean(formik?.errors?.artworkSize?.[index]?.quantityAvailable)
                }
                helperText={
                  formik?.touched?.artworkSize?.[index]?.quantityAvailable &&
                  formik?.errors?.artworkSize?.[index]?.quantityAvailable
                }
              />
              <TextFieldInputLabel
                type="number"
                // isRequired
                placeholder="Price *"
                name={`artworkSize[${index}].price`}
                value={size?.price}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                inputProps={{ min: 0, step: 0.01 }}
                onKeyPress={(event) => {
                  if (event?.key === '-' || event?.key === '+') {
                    event.preventDefault();
                  }
                }}
                error={
                  formik?.touched?.artworkSize?.[index]?.price && Boolean(formik?.errors?.artworkSize?.[index]?.price)
                }
                helperText={formik?.touched?.artworkSize?.[index]?.price && formik?.errors?.artworkSize?.[index]?.price}
              />
              <TextFieldInputLabel
                type="number"
                // required
                placeholder="Sale Price (If Offering Discount)"
                name={`artworkSize[${index}].salePrice`}
                value={size?.salePrice}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                inputProps={{ min: 0, step: 0.01 }}
                onKeyPress={(event) => {
                  if (event?.key === '-' || event?.key === '+') {
                    event.preventDefault();
                  }
                }}
                error={
                  formik?.touched?.artworkSize?.[index]?.salePrice &&
                  Boolean(formik?.errors?.artworkSize?.[index]?.salePrice)
                }
                helperText={
                  formik?.touched?.artworkSize?.[index]?.salePrice && formik?.errors?.artworkSize?.[index]?.salePrice
                }
              />
              {/* <TextFieldInput placeholder="Estimated Price" value={`Shipping Price - ${price}`} disabled /> */}
              <SelectBoxLabel
                lableName="Shipping Size *"
                className="type-select"
                // isRequired
                options={dropdowns?.sizes?.map((item) => ({ id: item?.id, name: item?.description }))}
                name={`artworkSize[${index}].shippingSize`}
                value={size?.shippingSize}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                error={
                  formik?.touched?.artworkSize?.[index]?.shippingSize &&
                  Boolean(formik?.errors?.artworkSize?.[index]?.shippingSize)
                }
                helperText={
                  formik?.touched?.artworkSize?.[index]?.shippingSize &&
                  formik?.errors?.artworkSize?.[index]?.shippingSize
                }
                // defaultOption="Shipping Size"
                // defaultValue={0}
              />
              {
                // eslint-disable-next-line no-unsafe-optional-chaining
                plan?.v_list_multiple_sizes === 'Yes' && index === formik?.values?.artworkSize?.length - 1 ? (
                  <WhiteBtn
                    title="Add Another Size +"
                    onClick={() => {
                      formik?.setFieldValue('artworkSize', [
                        // eslint-disable-next-line no-unsafe-optional-chaining
                        ...formik?.values?.artworkSize,
                        {
                          allow_access: 1,
                          height: '',
                          width: '',
                          len: '',
                          editionType: '',
                          editionSize: '',
                          quantityAvailable: '',
                          price: '',
                          salePrice: '',
                          shippingSize: '',
                        },
                      ]);
                    }}
                  />
                ) : (
                  // eslint-disable-next-line no-unsafe-optional-chaining
                  index === formik?.values?.artworkSize?.length - 1 && (
                    <WhiteBtn
                      title="Upgrade plan to add more size"
                      onClick={() => {
                        sweatAlertBox(
                          'You will lose all the changes you made. Are you sure you want to proceed?',
                          null,
                          () => navigate('/my-subscription'),
                          'Proceed',
                          'Cancel',
                          // eslint-disable-next-line no-sequences
                        );
                      }}
                    />
                  )
                )
              }
            </CardContainer>
          ) : (
            // <h1>upgrade access to edit size</h1>
            <UpgradePlanContainer
              mainTitle="Upgrade access to edit size"
              btnTitle="Upgrade Now"
              btnNavigation="/my-subscription"
            />
          );
        })}
        <input hidden type="submit" ref={artworkSizesRef} />
      </form>
    );
  },
);
