import { Skeleton, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { SetList } from '../../components/Dashboard/SetList';
import { UpgradePlanContainer } from '../../components/universal/BlurContainer';
import { GreenBtn } from '../../components/universal/Buttons/GreenBtn';
import { CardContainer } from '../../components/universal/CardContainer';
import { ChartCard } from '../../components/universal/ChartCard';
import DashboardCharts from '../../components/universal/DashboardChart';
import SimpleSelectBox from '../../components/universal/InputTypeText/SelectBox';
import ArtNotifications from '../../components/universal/Notification/Notification';
import ProductSlider from '../../components/universal/ProductSlider';
import { CustomizedProgressBars } from '../../components/universal/ProgressBar';
import { useWindowSize } from '../../hooks/window';
import { MAX_MOBILE_WIDTH } from '../../models/breakpoints.model';
import AxiosAll from '../../services/axiosAll';
import { deleteUser } from '../../redux/actions/user';
import { deleteSubscription } from '../../redux/actions/subscription';
import { EmailUpdate } from '../../components/dialogs/EmailUpdate';
import DarkBtn from '../../components/universal/Buttons/DarkBtn';
import { images } from '../../assets';

export const nextStep = (data, skip) => {
  switch (true) {
    case data?.account_detail === 0 && skip !== 'account_detail':
      return '/personalize';
    case data?.tell_us_about_youself === 0 && skip !== 'tell_us_about_youself':
      return '/my-account/bio';
    case data?.list_your_artwork === 0 && skip !== 'list_your_artwork':
      return '/portfolio/artworks';
    case data?.organise_shipping === 0 && skip !== 'organise_shipping':
      return '/sales/shipping';
    case data?.accept_payments === 0 && skip !== 'accept_payments':
      return '/sales/payments';
    default:
      return '';
  }
};

const Dashboard = () => {
  const { width } = useWindowSize();
  const [artwork, setArtwork] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [AccountStatuspoint, setAccountStatuspoint] = useState({});
  const [OfferData, setOfferData] = useState({});
  const [chartYear, setChartYear] = useState(0);
  const [isChartLoading, setIsChartLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [loading, setloading] = useState(true);
  const [limit, setLimit] = useState(12);
  const isMobile = (width || 0) < MAX_MOBILE_WIDTH;
  const isShowNotifyPagination = notifications?.data?.length > 0 && notifications?.last_page > 1;
  const chartMonth = useMemo(() => chartData?.range?.map((item) => item?.month), [chartData]);
  const chartValue = useMemo(() => chartData?.range?.map((item) => item?.value), [chartData]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { userDetails } = useSelector((state) => state.user);
  const { subscriptionDetails } = useSelector((state) => state?.subscription);
  const plan = subscriptionDetails?.active_plan;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onPageChange = (e, pg) => {
    setPage(pg);
    const child = document.getElementById('notification-area');
    if (child) {
      child.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const onPageLimitChange = (e) => {
    setPage(1);
    setLimit(e.target.value);
    const child = document.getElementById('notification-area');
    if (child) {
      child.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const getArtwork = async () => {
    const res = await AxiosAll('GET', 'artist/popularArtwork?limit=3', null, userDetails?.token);
    if (res?.data?.statusCode === 200) {
      setArtwork(res?.data?.data);
    }
  };
  const getOffer = async () => {
    setloading(true);
    const res = await AxiosAll('GET', 'artist/mySpecialOffer', null, userDetails?.token);
    if (res?.data?.statusCode === 200) {
      setOfferData(res?.data?.data);
      setloading(false);
    } else {
      setloading(false);
    }
  };

  const getChartData = useCallback(async () => {
    try {
      setIsChartLoading(true);
      const res = await AxiosAll(
        'GET',
        chartYear === 0 ? 'artist/viewsChart' : `artist/viewsChart?year=${chartYear}`,
        null,
        userDetails?.token,
      );
      if (res?.data?.statusCode === 200) {
        const data = res?.data?.data;
        setChartData(data);
        setIsChartLoading(false);
      }
    } catch (error) {
      setIsChartLoading(false);
      console.log(error);
    }
  }, [chartYear, userDetails?.token]);

  const getAccountStatus = async () => {
    const res = await AxiosAll('GET', 'artist/accountStatus', null, userDetails?.token);
    if (res?.data?.statusCode === 200) {
      const data = res?.data?.data;
      setAccountStatuspoint(data);
    }
  };

  const getNotifications = useCallback(async () => {
    try {
      const res = await AxiosAll(
        'GET',
        `artLoverNotifications?limit=${limit}&page=${page}&user_type=seller`,
        null,
        userDetails?.token,
      );
      setNotifications(res?.data?.data);
    } catch (error) {
      console.log('error', error);
    }
  }, [limit, page, userDetails?.token]);

  const readNotification = async (id) => {
    try {
      const res = await AxiosAll('POST', `artist/readNotification/${id}`, null, userDetails?.token);
      if (res?.data?.statusCode === 200) {
        getNotifications();
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    if (plan?.v_analytics_tools === 'Basic' || plan?.v_analytics_tools === 'Advanced') {
      getArtwork();
    }
    getAccountStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plan?.v_analytics_tools]);

  useEffect(() => {
    if (plan?.v_analytics_tools === 'Advanced') {
      getChartData();
    }
  }, [getChartData, plan?.v_analytics_tools]);

  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  useEffect(() => {
    getOffer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const trueCount = Object.values(AccountStatuspoint).filter((value) => value === 1).length;
  const percentageTrue = (trueCount / Object.keys(AccountStatuspoint).length) * 100;
  const roundedPercentage = Math.round(percentageTrue);
  useEffect(() => {
    if (
      queryParams?.get('artist')?.replace('art', '') &&
      queryParams?.get('artist')?.replace('art', '') != userDetails?.user?.id
    ) {
      logOut();
    }
  }, []);
  const logOut = () => {
    navigate('/login');
    dispatch(deleteUser());
    dispatch(deleteSubscription());
    const impersonating = localStorage.getItem('impersonating');
    if (impersonating) {
      localStorage.removeItem('impersonating');
    }
    // dispatch(deleteCart());
  };

  // useEffect(() => {
  //   localStorage?.getItem('debug') === 'Start'
  //     ? localStorage.setItem('debug', 'Start')
  //     : localStorage.setItem('debug', 'NO');
  // }, []);

  return (
    <div className="sell-dashboard">
      {userDetails.user?.e_login_type === 'apple' && userDetails.user?.email_apple_verified !== 1 ? (
        <EmailUpdate emailAddress={userDetails.user?.v_email} />
      ) : null}
      <h2 className="mainheadart font40px">Hello {userDetails.user.v_fname}!</h2>

      {loading ? null : OfferData?.monthly || OfferData?.yearly ? (
        <CardContainer className="special-offer">
          <div className="offer-image">
            <img src={images.celebrateImg} alt="" />
            <p className="font20px color000">
              Don’t miss this special offer... <br /> Upgrade Today and Save £
              {(
                ((OfferData?.monthly && OfferData?.monthly[0]?.v_amount) ||
                  (OfferData?.yearly && OfferData?.yearly[0]?.v_amount) ||
                  0) -
                ((OfferData?.monthly && OfferData?.monthly[0]?.v_sale_amount) ||
                  (OfferData?.yearly && OfferData?.yearly[0]?.v_sale_amount) ||
                  0)
              )?.toFixed(2)}
              !
            </p>
          </div>
          <DarkBtn title="Redeem Now" onClick={() => navigate('/special-offers')} />
        </CardContainer>
      ) : null}

      <CardContainer
        className="step-bar"
        borderRadius={20}
        paddingX={isMobile ? 16 : 41}
        paddingY={36}
        bottomMargin="5.4rem"
      >
        <CustomizedProgressBars progress={roundedPercentage} />
        <SetList
          accountDetails={AccountStatuspoint?.account_detail}
          aboutYourself={AccountStatuspoint?.tell_us_about_youself}
          listedArtwork={AccountStatuspoint?.list_your_artwork}
          organiseShipping={AccountStatuspoint?.organise_shipping}
          acceptPayments={AccountStatuspoint?.accept_payments}
          launchYour={AccountStatuspoint?.launch_page}
        />
        {nextStep(AccountStatuspoint) ? (
          <div className="stepbtn">
            <GreenBtn
              title="Next Step"
              onClick={() => {
                navigate(`${nextStep(AccountStatuspoint)}`);
              }}
              minmunWidth={203}
            />
          </div>
        ) : null}
      </CardContainer>
      <ArtNotifications
        showMore={isShowNotifyPagination}
        readNotification={readNotification}
        notifications={notifications}
        onShowMore={() => navigate('/dashboard/my-notifications')}
      />
      {artwork?.data?.length > 0 && (plan?.v_analytics_tools === 'Basic' || plan?.v_analytics_tools === 'Advanced') ? (
        <>
          <p className="taglineart font25px">Your Most Popular Artworks</p>
          <div className="artcardarea">
            <ProductSlider data={artwork.data} ProductCard sellerName slidesCount={3} />
          </div>
        </>
      ) : (
        plan?.v_analytics_tools === 'No' && (
          <UpgradePlanContainer
            btnNavigation="/my-subscription"
            btnTitle="Upgrade your plan"
            mainTitle="Upgrade to access your most popular artworks"
          />
        )
      )}

      {plan?.v_analytics_tools === 'Advanced' ? (
        <>
          <div className="seller-graph">
            <Typography variant="h5">Page Views</Typography>
            <SimpleSelectBox
              name="pageViewsDropdown"
              options={chartData?.years?.map((item) => {
                return { id: item, name: item };
              })}
              onChange={(e) => setChartYear(e.target.value)}
              value={chartYear || new Date().getFullYear()}
              // defaultOption="Select"
              // defaultValue={0}
            />
          </div>
          {isChartLoading ? (
            <ChartCard borderRadius={20} paddingX={isMobile ? 16 : 41} paddingY={36} bottomMargin="2rem">
              <Skeleton variant="rectangular" width="100%" height={320} />
            </ChartCard>
          ) : (
            <ChartCard borderRadius={20} paddingX={isMobile ? 16 : 41} paddingY={36} bottomMargin="2rem">
              <DashboardCharts
                name="Views per month"
                data={chartValue}
                categories={chartMonth}
                type="line"
                className="testor"
              />
            </ChartCard>
          )}
        </>
      ) : (
        <UpgradePlanContainer
          btnNavigation="/my-subscription"
          btnTitle="Upgrade your plan"
          mainTitle="Upgrade to access page views"
        />
      )}
    </div>
  );
};

export default Dashboard;
