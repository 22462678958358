import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export const AutoCompeletTextField = ({ options, onChange, value }) => {
  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={options}
      value={value}
      onChange={onChange}
      sx={{ width: 300 }}
      renderInput={(params) => <TextField {...params} placeholder="Start Typing..." />}
      className="autocomplete"
    />
  );
};
