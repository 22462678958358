import { Button } from '@mui/material';

const OutlineBtn = ({ title, type, loading, onClick, loadingText, styleBtn, disabled }) => {
  return (
    <Button
      disabled={disabled || loading}
      className={`outlinebtn ${styleBtn}`}
      type={type || 'button'}
      onClick={onClick}
    >
      {/* Find Out More */}
      {loading ? loadingText || 'Loading...' : title}
    </Button>
  );
};

export default OutlineBtn;
