import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import * as React from 'react';
import { useSelector } from 'react-redux';
import AxiosAll from '../../services/axiosAll';
import notify from '../../utils/notify';
import PaymentMethodStripForm from '../PaymentStripForm';
import DarkBtn from '../universal/Buttons/DarkBtn';

const AddCardDailog = ({ buttonType, getIsCardListUpdated }) => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { userDetails } = useSelector((state) => state.user);
  const [PayCardID, setPayCardID] = React.useState('');
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const addCard = async (data) => {
    try {
      setLoading(true);
      const res = await AxiosAll('post', 'artist/paymentMethods', data, userDetails.token);
      UpdateList();
      notify(res?.data?.message, 'success');
      handleClose();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const UpdateList = () => {
    getIsCardListUpdated(!true);
  };

  React.useEffect(() => {
    if (PayCardID && PayCardID !== '') {
      addCard({ payment_method_id: PayCardID });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PayCardID]);

  return (
    <div>
      <div className="textflex">
        {buttonType === 'dark' ? (
          <DarkBtn onClick={handleClickOpen} title="Add Card" />
        ) : (
          <div tabIndex={0} role="button" className="add-card" onClick={handleClickOpen}>
            <span className="plusimg">
              <img src="/icons/add.svg" alt="" height={24} width={24} />
            </span>
            <span className="add-card-text">Add Card</span>
          </div>
        )}
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="Add-Address card-detail"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description" className="main-pop-up">
            <div className="redeem-voucher">
              <div className="voucher-text">Add Card</div>
            </div>
            <PaymentMethodStripForm loading={loading} setPayCardID={setPayCardID} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddCardDailog;
