import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import * as React from 'react';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export const MultipleSelectChip = ({
  value,
  onChange,
  options,
  defaultOption,
  defaultValue,
  error,
  helperText,
  ...props
}) => {
  return (
    <div>
      <FormControl sx={{ m: 1, width: 300 }}>
        <Select
          value={value}
          onChange={onChange}
          displayEmpty
          multiple
          renderValue={(selected) => {
            if (selected.length === 0) {
              return defaultOption;
            }
            return (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected?.map((v) => (
                  <Chip key={v} label={options?.find((option) => option?.id === v)?.name} />
                ))}
              </Box>
            );
          }}
          MenuProps={MenuProps}
          {...props}
        >
          {defaultOption && (
            <MenuItem value={defaultValue} disabled>
              {defaultOption}
            </MenuItem>
          )}
          {options?.map((option, index) => {
            return (
              <MenuItem key={index} value={option?.id}>
                {option?.name}
              </MenuItem>
            );
          })}
        </Select>
        {error && <div className="error_text">{helperText}</div>}
      </FormControl>
    </div>
  );
};
