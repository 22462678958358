import { Container } from '@mui/system';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { EmptyChannel } from '../../components/AllMessages/EmptyChannel';
import MsgChannel from '../../components/AllMessages/MsgChannel';
import MsgPreview from '../../components/AllMessages/MsgPreview';
import { SingleMsgCardSkeleton } from '../../components/Skeleton/SingleMsgCardSkeleton';
import { CardContainer } from '../../components/universal/CardContainer';
import { useWindowSize } from '../../hooks/window';
import { MAX_MOBILE_WIDTH } from '../../models/breakpoints.model';
import AxiosAll from '../../services/axiosAll';
import { globalWindow } from '../../core/constant';

const AllMessage = () => {
  const borderStyle = {
    border: '1px solid #f5445e',
  };
  const [allChatsData, setAllChatsData] = useState([]);
  const [isChatLoading, setIsChatLoading] = useState(false);
  const [sellerMsg, setSellerMsg] = useState([]);
  const [isSellerMsgLoading, setIsSellerMsgLoading] = useState(false);
  const [activeMsgId, setActiveMsgId] = useState(null);
  const [scrollEnabled, setScrollEnabled] = useState(false);

  const { width } = useWindowSize();
  const isMobile = (width || 0) < MAX_MOBILE_WIDTH;
  const { userDetails } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(globalWindow?.location?.search);
  const msgId = queryParams.get('msgId');

  const getSellerMessages = useCallback(
    async (id) => {
      try {
        setIsSellerMsgLoading(true);
        const res = await AxiosAll('get', `sellerMessages?i_chat_id=${id}`, null, userDetails?.token);
        if (res?.data?.statusCode === 200) {
          setSellerMsg(res?.data?.data);
          setIsSellerMsgLoading(false);
        }
      } catch (error) {
        setIsSellerMsgLoading(false);
      }
    },
    [userDetails?.token],
  );

  const updateLatestMessage = (lastMsg) => {
    const updatedData = allChatsData?.map((item) => {
      if (item?.id === lastMsg?.i_chat_id) {
        return {
          ...item,
          last_message: {
            ...item.last_message,
            created_at: lastMsg?.created_at,
            updated_at: lastMsg?.updated_at,
            l_message: lastMsg?.l_message,
            v_subject_title: lastMsg?.v_subject_title,
          },
        };
      }
      return item;
    });
    setAllChatsData(updatedData);
    setActiveMsgId(lastMsg?.i_chat_id);
  };

  const getAllChats = async () => {
    try {
      setIsChatLoading(true);
      const res = await AxiosAll('get', 'chats', null, userDetails?.token);
      if (res?.data?.statusCode === 200) {
        setAllChatsData(res?.data?.data);
        // setActiveMsgId(res?.data?.data[0]?.id);
        setIsChatLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsChatLoading(false);
    }
  };

  const selectHandler = (id) => {
    if (isMobile) {
      navigate(`/community/messages/${id}`);
    } else {
      setActiveMsgId(id);
    }
  };

  useEffect(() => {
    if (msgId) {
      const msgIdNumber = Number(msgId);
      setActiveMsgId(msgIdNumber);
      navigate(`/community/messages`, { replace: true });
    } else if (allChatsData?.length > 0 && !isMobile && !activeMsgId && !msgId) {
      setActiveMsgId(allChatsData[0]?.id);
    }
  }, [activeMsgId, allChatsData, isMobile, msgId]);

  useEffect(() => {
    getAllChats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (activeMsgId) {
      getSellerMessages(activeMsgId);
    }
  }, [activeMsgId, getSellerMessages]);

  useEffect(() => {
    const child = document.getElementById('msg-preview-child');
    if (child) {
      child.scrollTo({
        top: child.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [sellerMsg]);

  useEffect(() => {
    // if there enough messages to scroll then enable scroll
    const child = document.getElementById('msg-channel-child');
    if (child) {
      if (child.scrollHeight > child.clientHeight) {
        setScrollEnabled(true);
      } else {
        setScrollEnabled(false);
      }
    }
  }, [allChatsData]);

  return (
    <div className="allmessage">
      <div className="searchbtninput">
        <div className="font40px mobilehide">Your Messages</div>
        <div className="font40px mobileshow">Your Messages</div>
      </div>
      <div className="message-section">
        <CardContainer height={allChatsData?.length > 0 && 775} gap={45}>
          <div className="scroll-dir" style={{ overflowY: scrollEnabled ? 'scroll' : 'hidden' }} id="msg-channel-child">
            {/* need to add condition for overflow-y: auto; when there is no MsgChannel */}
            {isChatLoading ? (
              <>
                <SingleMsgCardSkeleton />
                <SingleMsgCardSkeleton />
                <SingleMsgCardSkeleton />
              </>
            ) : allChatsData?.length > 0 ? (
              <MsgChannel
                data={allChatsData}
                activeMsgId={activeMsgId}
                border={borderStyle}
                selectHandler={selectHandler}
                setActiveMsgId={setActiveMsgId}
              />
            ) : (
              <EmptyChannel />
            )}
          </div>
          {!isMobile && sellerMsg?.messages?.length > 0 ? (
            <div className="send-message">
              <MsgPreview
                updateLatestMessage={updateLatestMessage}
                isLoading={isSellerMsgLoading}
                data={sellerMsg}
                getSellerMessages={getSellerMessages}
              />
            </div>
          ) : (
            !isMobile && (
              <div className="Empty-msg">
                <img src="/icons/empty-notification.svg" alt="empty-notification" />
                <h3>currently you have no messages</h3>
              </div>
            )
          )}
        </CardContainer>
      </div>
    </div>
  );
};

export default AllMessage;
