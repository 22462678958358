import { DELETE_SUBSCRIPTION, SET_SUBSCRIPTION, UPDATE_SUBSCRIPTION } from '../constant';

const initialState = {
  subscriptionDetails: {},
};
// eslint-disable-next-line default-param-last
const subscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SUBSCRIPTION:
      return { ...state, subscriptionDetails: action.data };
    case UPDATE_SUBSCRIPTION:
      return {
        ...state,
        subscriptionDetails: {
          ...state.subscriptionDetails,
          user: action.data,
        },
      };
    case DELETE_SUBSCRIPTION:
      return {
        subscriptionDetails: {},
      };
    default:
      return state;
  }
};

export default subscriptionReducer;
