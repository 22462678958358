import { Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import * as Yup from 'yup';
import { images } from '../../assets';
import { MultipleSelectChip } from '../../components/add_collection/MultiSelectionChip';
import DarkBtn from '../../components/universal/Buttons/DarkBtn';
import { CardContainer } from '../../components/universal/CardContainer';
import TextFieldInput from '../../components/universal/InputTypeText/TextField';
import TextAreaBox from '../../components/universal/InputTypeText/TextareaBox';
import ImageCard from '../../components/universal/imageCard';
import { useWindowSize } from '../../hooks/window';
import { MAX_MOBILE_WIDTH } from '../../models/breakpoints.model';
import AxiosAll from '../../services/axiosAll';
import { request } from '../../services/request';
import notify from '../../utils/notify';
import { notifyMessages } from '../../core/constant';
import sweatAlertBox from '../../components/universal/SweetAlertBox';

export const AddCollection = () => {
  const [isDisabled, setIsDisabled] = useState({
    coverImage: true,
  });
  const [artworksDropdown, setArtworksDropdown] = useState([]);
  const [collectionData, setCollectionData] = useState([]);
  const [totalCollection, setTotalCollection] = useState(0);
  const navigate = useNavigate();
  const params = useParams();
  const coverImageRef = useRef();
  const { width } = useWindowSize();
  const isMobile = (width || 0) < MAX_MOBILE_WIDTH;
  const { userDetails } = useSelector((state) => state.user);
  const { subscriptionDetails } = useSelector((state) => state?.subscription);
  const plan = subscriptionDetails?.active_plan;

  const initialValues = {
    coverImage: '',
    title: '',
    description: '',
    tags: [],
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    description: Yup.string().required('Description is required'),
    tags: !params?.id && Yup.array().required('artwork is required').min(1, 'Please select at least one artwork'),
  });

  const onSubmit = async (values) => {
    try {
      const formData = new FormData();
      values.coverImage instanceof File && formData.append('image', values.coverImage);
      formData.append('title', values.title);
      formData.append('description', values.description);
      formData.append('status', 'Active');
      if (values.tags.length > 0) {
        for (let i = 0; i < values.tags.length; i++) {
          formData.append(`artworks[${i}]`, values.tags[i]);
        }
      } else {
        formData.append('artworks[]', '');
      }
      const res = await AxiosAll(
        'post',
        params?.id ? `artist/collections/${params?.id}?_method=put` : 'artist/collections',
        formData,
        userDetails?.token,
      );
      if (res?.data?.statusCode === 200) {
        notify(res?.data?.message, 'success');
        navigate('/portfolio/collections');
      } else {
        notify(res?.data?.message, 'error');
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleCoverImageURL = () => {
    if (formik?.values.coverImage instanceof File) {
      return URL.createObjectURL(formik?.values.coverImage);
    }
    return formik?.values.coverImage;
  };

  const handleRemoveProfilePic = () => {
    if (formik?.values.coverImage) {
      coverImageRef.current.value = '';
      formik.setFieldValue('coverImage', collectionData?.image);
      setIsDisabled({ ...isDisabled, coverImage: true });
    }
  };

  const showProfileRemoveBtn = () => {
    if (formik.values.coverImage instanceof File) {
      setIsDisabled({ ...isDisabled, coverImage: false });
    } else {
      setIsDisabled({ ...isDisabled, coverImage: true });
    }
  };

  const getArtworksDropdown = async () => {
    try {
      const res = await AxiosAll('get', 'dropdowns?type[14]=unassigned_artworks', null, userDetails?.token);
      if (res?.data?.statusCode === 200) {
        setArtworksDropdown(res?.data?.data?.unassigned_artworks);
        if (params?.id) {
          getArtworkCollections(res?.data?.data?.unassigned_artworks);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCollections = async () => {
    try {
      const res = await request({
        url: '/artist/collections?is_artist_dashboard=1',
        method: 'GET',
        params: {
          page: 1,
          type: 'my-collection',
        },
        token: userDetails?.token,
      });
      setTotalCollection(res?.data?.total);
    } catch (error) {
      console.log('error', error);
    }
  };

  const getArtworkCollections = async (artworks) => {
    try {
      const res = await request({
        url: `/artist/collections/${params?.id}`,
        method: 'GET',
        token: userDetails?.token,
      });
      setCollectionData(res?.data);
      const selectedArtworks = res?.data?.artworks?.map((item) => {
        return {
          id: item?.id,
          v_title: item?.v_title,
        };
      });
      const mergedArtworks = [...artworks, ...selectedArtworks];
      const uniqueArtworks = mergedArtworks.filter(
        (thing, index, self) => index === self.findIndex((t) => t.id === thing.id),
      );
      setArtworksDropdown(uniqueArtworks);
      const selectedArtworksIds = res?.data?.artworks?.map((item) => item?.id);
      formik.setFieldValue('tags', selectedArtworksIds);
      formik.setFieldValue('title', res?.data?.title);
      formik.setFieldValue('description', res?.data?.description);
      formik.setFieldValue('coverImage', res?.data?.image);
    } catch (error) {
      console.log('error', error);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    getCollections();
    getArtworksDropdown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formik.values.coverImage instanceof File) {
      showProfileRemoveBtn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.coverImage]);
  return (
    <div className="main-form">
      <form onSubmit={formik.handleSubmit}>
        <CardContainer
          className="collection-form"
          paddingY={isMobile ? 20 : '1.6vw'}
          paddingX={isMobile ? 20 : '1.6vw'}
        >
          <Typography variant="h2" sx={{ mb: 1.2 }}>
            Description
          </Typography>
          <div className="profilepic">
            <ImageCard
              image={formik?.values?.coverImage ? handleCoverImageURL() : images.imagePlaceholder}
              alt="profile pic"
            />
            <input
              hidden
              type="file"
              name="coverImage"
              ref={coverImageRef}
              accept="image/x-png ,image/jpeg"
              onChange={(e) => {
                if (e.target.files[0].size > 2097152) {
                  notify(notifyMessages?.fileTooLarge, 'error');
                  return;
                }
                formik.setFieldValue('coverImage', e.target.files[0]);
              }}
            />
            <div className={`changebtn ${isDisabled.coverImage ? '' : 'greenbtn'}`}>
              <DarkBtn
                title="Change"
                onClick={() => {
                  coverImageRef.current.click();
                }}
              />
              {formik?.values?.coverImage instanceof File && !isDisabled.coverImage && (
                <span
                  role="button"
                  tabIndex={0}
                  style={{ cursor: 'pointer' }}
                  onClick={handleRemoveProfilePic}
                  className="canclebtn font20px"
                >
                  Remove
                </span>
              )}
            </div>
          </div>
          <TextFieldInput
            name="title"
            placeholder="Collection Title"
            value={formik?.values?.title}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            error={formik?.touched?.title && formik?.errors?.title}
            helperText={formik?.touched?.title && formik?.errors?.title}
          />
          <MultipleSelectChip
            name="tags"
            className="multiselect-chip"
            value={formik?.values?.tags}
            onChange={formik?.handleChange}
            options={artworksDropdown?.map((item) => {
              return { id: item?.id, name: item?.v_title };
            })}
            defaultOption="Chose Artworks"
            onBlur={formik?.handleBlur}
            error={formik?.touched?.tags && formik?.errors?.tags}
            helperText={formik?.touched?.tags && formik?.errors?.tags}
            defaultValue={0}
          />
          <TextAreaBox
            placeholder="Description"
            name="description"
            value={formik?.values?.description}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            error={formik?.touched?.description && formik?.errors?.description}
          />
        </CardContainer>

        <DarkBtn title="Save Collection" type="submit" />
      </form>
    </div>
  );
};
