import { Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { request } from '../../services/request';
import { FeedbackCard } from './FeedbackCard';

export const ReviewFeedback = ({ activeCard, isReviewAdded, setIsReviewAdded }) => {
  const [review, setReview] = useState({});
  const { userDetails } = useSelector((state) => state.user);

  const getReview = useCallback(async () => {
    try {
      const res = await request({
        url: `/artist/artworkReviews/${activeCard}`,
        method: 'GET',
        token: userDetails?.token,
      });
      setReview(res.data);
    } catch (error) {
      console.log(error);
    }
  }, [activeCard, userDetails?.token]);

  useEffect(() => {
    if (activeCard) {
      getReview();
    }
  }, [activeCard, getReview, isReviewAdded]);

  return (
    <>
      <div className="messagesbox" style={{ height: 'inherit' }}>
        {review?.e_review_status === '1' && review?.id === activeCard && (
          <FeedbackCard
            comment={review?.review?.l_comment}
            stars={review?.review?.f_overall_happiness}
            condition={review?.review?.f_condition}
            delivery={review?.review?.f_delivery}
            quality={review?.review?.f_quality}
            value={review?.review?.f_value}
          />
        )}
      </div>
      {review?.e_review_status === '0' && review?.id === activeCard && (
        <Typography variant="h5" align="center" sx={{ mt: '3rem' }}>
          No Reviews Yet
        </Typography>
      )}
    </>
  );
};
