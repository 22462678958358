import { Button } from '@mui/material';

export const GreenBtn = ({
  title,
  shadow,
  btnWidth = 'fit-content',
  minmunWidth,
  type,
  loading,
  onClick,
  loadingText,
  className,
}) => {
  return (
    <Button
      disabled={loading}
      className={`greenbtn ${className}`}
      type={type || 'button'}
      onClick={onClick}
      style={{ boxShadow: shadow, width: btnWidth, minWidth: minmunWidth }}
    >
      {/* Find Out More */}
      {loading ? loadingText || 'Loading...' : title}
    </Button>
  );
};
