import React, { useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { loadStripe } from '@stripe/stripe-js';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Typography } from '@mui/material';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { stripkey } from '../../core/constant';
import { useWindowSize } from '../../hooks/window';
import { MAX_MOBILE_WIDTH } from '../../models/breakpoints.model';
// import { CARD_OPTIONS } from './stripStyleConfig';

const CheckoutForm = ({ setPayCardID, buttontext, btnclass, addCardLoading }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, seterrorMessage] = useState('');
  const [loading, setloading] = useState(addCardLoading || false);
  const { width } = useWindowSize();
  const isMobile = (width || 0) < MAX_MOBILE_WIDTH;

  const handleSubmit = async (event) => {
    setloading(true);
    seterrorMessage('');
    event.preventDefault();
    // eslint-disable-next-line no-unused-vars
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });
    // eslint-disable-next-line no-console
    setPayCardID && setPayCardID(paymentMethod?.id);
    setTimeout(() => setloading(false), 10000);

    seterrorMessage(error?.message);
    error && setloading(false);
    // error&&setTimeout(()=>seterrorMessage(''), 1000);
  };
  const appearance = {
    style: {
      base: {
        fontSize: isMobile ? '16px' : '21px',
        color: '#000',
        border: '1px solid #D9D9D9',
        backgroundColor: '#FFFCFC',
        borderRadius: '20px',
      },
      invalid: {
        color: '#F5445E',
        iconColor: '#F5445E',
        border: '1px solid #F5445E',
      },
    },
    hidePostalCode: true,
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement options={appearance} />
      <div className="font12px error_message">{errorMessage}</div>
      {loading ? (
        <button type="button" disabled={loading} className={`darkbtn ${btnclass}`}>
          {loading ? 'Loading...' : buttontext || 'Add'}
        </button>
      ) : (
        <button type="submit" disabled={!stripe || !elements || loading} className={`darkbtn ${btnclass}`}>
          {loading ? 'Loading...' : buttontext || 'Add'}
        </button>
      )}
    </form>
  );
};

const stripePromise = loadStripe(stripkey);

const PaymentMethodStripForm = ({ setPayCardID, buttontext, btnclass, loading }) => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm
        setPayCardID={setPayCardID}
        buttontext={buttontext}
        btnclass={btnclass}
        addCardLoading={loading}
        // theme={appearance}
      />
    </Elements>
  );
};

export default PaymentMethodStripForm;
