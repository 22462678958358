import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';

const CheckBoxFiled = ({ name, label, value, defaultChecked, onChange, onBlur, error, helperText }) => {
  return (
    <>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox name={name} value={value} onChange={onChange} onBlur={onBlur} defaultChecked={defaultChecked} />
          }
          label={label}
        />
      </FormGroup>
      {error && <div className="error_text">{helperText}</div>}
    </>
  );
};

export default CheckBoxFiled;
