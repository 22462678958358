import { DELETE_SUBSCRIPTION, SET_SUBSCRIPTION, UPDATE_SUBSCRIPTION } from '../constant';

export const setSubscription = (data) => {
  return {
    type: SET_SUBSCRIPTION,
    data,
  };
};

export const updateSubscription = (data) => {
  return {
    type: UPDATE_SUBSCRIPTION,
    data,
  };
};

export const deleteSubscription = () => {
  return {
    type: DELETE_SUBSCRIPTION,
  };
};
