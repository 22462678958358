import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { alpha, styled } from '@mui/material/styles';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { useSelector } from 'react-redux';
import TableRow from '@mui/material/TableRow';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Button, Checkbox, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import IosShareIcon from '@mui/icons-material/IosShare';
import { useNavigate } from 'react-router-dom';
import DarkBtn from '../universal/Buttons/DarkBtn';
import { InputShare } from '../universal/InputUrl';
import TruncatedText from '../truncatedText';
import { request } from '../../services/request';
import notify from '../../utils/notify';

export const ArtistTable = ({
  artworks,
  collections,
  tableAlignment,
  allowedAccess,
  selected,
  handleSelect,
  dataMap,
  allowedViews,
  getArtwork,
}) => {
  const navigate = useNavigate();
  const [selectedItemId, setSelectedItemId] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState({});
  const open = Boolean(anchorEl);
  const { subscriptionDetails } = useSelector((state) => state?.subscription);
  const { userDetails } = useSelector((state) => state.user);
  const plan = subscriptionDetails?.active_plan;

  const handleClick = (event, id) => {
    setAnchorEl({ ...anchorEl, [id]: event.currentTarget });
  };
  const handleClose = (id) => {
    setAnchorEl({ ...anchorEl, [id]: null });
  };

  const RequestPOD = async (id) => {
    handleClose();
    try {
      const res = await request({
        url: '/artist/sendPodRequests',
        method: 'POST', // Corrected 'POSt' to 'POST'
        data: {
          artwork_id: id,
        },
        token: userDetails?.token,
      });
      if (res?.statusCode === 200) {
        notify(res?.message, 'success');
        getArtwork();
      } else {
        notify(res?.message, 'error');
      }
      // setTotalArtwork(res?.data?.total);
    } catch (error) {
      notify(error?.data?.message, 'error');
    }
  };
  const handlePODRequest = (podId) => {
    RequestPOD(podId);
    handleClose(podId);
  };
  return (
    <div style={{ width: '100%' }}>
      {dataMap?.length ? (
        <TableContainer className="main-table">
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align={tableAlignment}>{}</TableCell>
                <TableCell align={tableAlignment} className="font14pxtable">
                  Artwork Title
                </TableCell>
                <TableCell align={tableAlignment} className="font14pxtable">
                  Visibility
                </TableCell>
                <TableCell align={tableAlignment} className="font14pxtable">
                  Views
                </TableCell>
                <TableCell align={tableAlignment} className="font14pxtable">
                  No. Available
                </TableCell>
                <TableCell align={tableAlignment} className="font14pxtable">
                  Thumbnail
                </TableCell>
                <TableCell align={tableAlignment} className="font14pxtable">
                  Share
                </TableCell>
                <TableCell align={tableAlignment} className="font14pxtable">
                  {}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataMap?.map((item, index) => (
                <TableRow key={index}>
                  <TableCell align={tableAlignment}>
                    {artworks && allowedAccess ? null : (
                      <Checkbox checked={selected.includes(item.id)} onChange={() => handleSelect(item)} />
                    )}
                  </TableCell>
                  <TableCell align={tableAlignment} className="font14pxtable">
                    <TruncatedText text={item?.v_title} maxLength={18} />
                  </TableCell>
                  <TableCell align={tableAlignment} className="font14pxtable">
                    {item?.e_status === 'disabled' ? 'Draft' : 'Public'}
                  </TableCell>
                  <TableCell align={tableAlignment} className="font14pxtable">
                    {allowedViews ? (
                      <p className="blogtitle font14px">{item?.views || '0'}</p>
                    ) : (
                      <Tooltip onClick={() => navigate('/my-subscription')} title="Upgrade" placement="top-start">
                        <Button>
                          <img src="/icons/lock.svg" alt="" width={20} height={20} className="btn-image" />
                        </Button>
                      </Tooltip>
                    )}
                  </TableCell>{' '}
                  <TableCell align={tableAlignment} className="font14pxtable">
                    {item?.quantity}
                  </TableCell>
                  <TableCell align={tableAlignment} className="font14pxtable">
                    <img src={item?.artwork_cover_image?.v_image} alt="test" />
                  </TableCell>
                  <TableCell
                    align={tableAlignment}
                    className="font14pxtable padd_new_top"
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    <InputShare
                      title="test"
                      url={`${process.env.REACT_APP_USER_APP_URL}artwork/${item?.v_slug}/${item?.id}`}
                      status={item?.e_status === 'disabled'}
                      erroMsg="Only publicly visible works can be shared"
                    />
                  </TableCell>
                  <TableCell align={tableAlignment}>
                    {/* <div> */}
                    <MoreVertIcon
                      id="basic-button"
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={(event) => handleClick(event, item.id)} // Pass the item ID when clicking
                      style={{ cursor: 'pointer' }}
                    />

                    <Menu
                      id={`basic-menu-${item.id}`} // Use a unique ID for each menu
                      anchorEl={anchorEl[item.id]} // Get anchorEl based on item ID
                      open={Boolean(anchorEl[item.id])}
                      onClose={() => handleClose(item.id)} // Pass the item ID to handleClose
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      <MenuItem onClick={() => navigate(`/portfolio/artworks/${item.id}`)}>Edit</MenuItem>
                      {item &&
                      item?.is_pod_available === 0 &&
                      item?.artwork_pod_request &&
                      item.artwork_pod_request.e_status === 2 ? (
                        <MenuItem onClick={() => handlePODRequest(item.id)}>Request Print On Demand Again</MenuItem>
                      ) : item?.is_pod_available === 0 &&
                        item.artwork_pod_request === null &&
                        plan?.plan_type === 'paid' ? (
                        <MenuItem onClick={() => handlePODRequest(item.id)}>Request Print on Demand</MenuItem>
                      ) : null}
                    </Menu>
                    {/* </div> */}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div
          style={{
            width: '100%',
            padding: 40,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'white',
            borderRadius: 10,
            boxShadow: '3px 5px 10px rgba(0,0,0, 0.2)',
          }}
        >
          <Typography className="empty-text">No Artwork Found...</Typography>
        </div>
      )}
    </div>
  );
};
