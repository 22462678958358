import tempEmailDomains from './data/temporaryEmails.json';

export const restrictedKeywords = ['cosimo', 'support', 'team', 'cosmo', 'cosim', 'coimo', 'cosio', 'cossimo'];

export function containsRestrictedKeyword(input) {
  const lowercaseInput = input.toLowerCase();

  for (const keyword of restrictedKeywords) {
    const regex = new RegExp(`${keyword}`, 'i');
    if (regex.test(lowercaseInput)) {
      return true;
    }
  }

  return false;
}

export function isTemporaryEmail(email) {
  if (!email) return false;
  const domain = email.split('@')[1]?.toLowerCase();
  return tempEmailDomains?.temporary_emails?.includes(domain);
}
