import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useWindowSize } from '../../hooks/window';
import { MAX_MOBILE_WIDTH } from '../../models/breakpoints.model';
import AxiosAll from '../../services/axiosAll';
import notify from '../../utils/notify';
import { SavedAddressSkeleton } from '../../components/Skeleton/SavedAddressSkeleton';
import { CardContainer } from '../../components/universal/CardContainer';
import sweatAlertBox from '../../components/universal/SweetAlertBox';
import AddCardDailog from '../../components/AddCard';
import { cardTypeImage } from '../../hooks/cardtypes';

const SavedCards = () => {
  const [CardList, setCardList] = useState([]);
  const { userDetails } = useSelector((state) => state.user);
  const [isCardListUpdated, setIsCardListUpdated] = useState(false);
  const [confirmDel, setConfirmDel] = useState(false);
  const [idToDel, setIdToDel] = useState();
  const [loading, setLoading] = useState(true);

  const { width } = useWindowSize();
  const isToSmall = (width || MAX_MOBILE_WIDTH) < 600;

  const getIsCardListUpdated = (val) => {
    setIsCardListUpdated(!val);
  };

  const getCards = async () => {
    try {
      const res = await AxiosAll('get', 'artist/paymentMethods', '', userDetails.token);
      const data = res?.data?.data;
      setCardList(data);
      setIsCardListUpdated(false);
      setLoading(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  useEffect(() => {
    getCards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCardListUpdated]);

  const deleteCard = async (id) => {
    try {
      const res = await AxiosAll('delete', `artist/paymentMethods/${id}`, '', userDetails.token);
      notify(res.data.message, res?.data?.statusCode === 200 ? 'success' : 'error');
      setConfirmDel(false);
      getCards();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };
  useEffect(() => {
    confirmDel && deleteCard(idToDel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmDel]);

  const makeDefault = async (id) => {
    try {
      const res = await AxiosAll('put', `artist/paymentMethods/${id}?`, { is_default: 1 }, userDetails.token);
      notify(res.data.message, 'success');
      getCards();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };
  return (
    <div className="saved-addresses">
      <div className="saved-card">
        <div className="card-text main-heading font25px">saved-cards</div>
        <AddCardDailog add getIsCardListUpdated={getIsCardListUpdated} />
      </div>
      <div className="details">
        {loading ? (
          <SavedAddressSkeleton />
        ) : CardList?.length > 0 ? (
          CardList?.map((card, index) => {
            return (
              <CardContainer key={card} className="bottomMargin">
                <div className="address-details">
                  <div className="address-text">
                    {cardTypeImage(card?.v_card_brand, 30, 60)}
                    <Typography variant="body1" className="font14px">
                      **** **** **** {card?.v_card_last4}
                    </Typography>
                  </div>
                  <div className="delete-section">
                    {card.is_default === 0 && (
                      <div tabIndex={0} role="button" className="markbtn" onClick={() => makeDefault(card?.id)}>
                        <img
                          src={isToSmall ? '/icons/bookmark-black.svg' : '/icons/bookmark-white.svg'}
                          alt=""
                          width={isToSmall ? 24 : 15}
                          height={isToSmall ? 24 : 15}
                        />
                        <Typography variant="body1">Make Default</Typography>
                      </div>
                    )}
                    {CardList?.length > 1 ? (
                      <div
                        tabIndex={0}
                        role="button"
                        className="trashbtn"
                        onClick={() => (
                          sweatAlertBox(
                            'Are You Sure?',
                            'You are about to delete this card. You cannot undo this action once confirmed.',
                            setConfirmDel,
                            'Confirm',
                            'Cancel',
                            // eslint-disable-next-line no-sequences
                          ),
                          setIdToDel(card?.id)
                        )}
                      >
                        <img
                          src={isToSmall ? '/icons/trash-black.svg' : '/icons/trash-white.svg'}
                          alt=""
                          width={isToSmall ? 24 : 15}
                          height={isToSmall ? 24 : 15}
                          className="delete-icon"
                        />
                        <Typography variant="body1">Delete</Typography>
                      </div>
                    ) : null}
                  </div>
                </div>
              </CardContainer>
            );
          })
        ) : (
          <div>No Saved Cards found</div>
        )}
      </div>
    </div>
  );
};

export default SavedCards;
