import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Loader } from '../../components/universal/Loader';
import { UpgradePlanContainer } from '../../components/universal/BlurContainer';

const Academy = () => {
  const [loading, setLoading] = useState(true);

  const { subscriptionDetails } = useSelector((state) => state?.subscription);
  const plan = subscriptionDetails?.active_plan;

  return (
    <div>
      <h2 className="mainheadart font40px">Academy</h2>
      {plan?.plan_type === 'paid' ? (
        <>
          <Loader loading={loading} message="Loading..." />
          <iframe
            onLoad={() => setLoading(false)}
            title="iframe"
            className="airtable-embed"
            src="https://airtable.com/embed/appspdhqz8JjIhP6L/shrrR2PiFTHr6kJAd?backgroundColor=gray&viewControls=on"
            width="100%"
            height="633"
            style={{ background: 'transparent', border: '1px solid #ccc' }}
          />
        </>
      ) : (
        <UpgradePlanContainer
          btnNavigation="/my-subscription"
          btnTitle="Upgrade your plan"
          mainTitle="Upgrade to access academy"
        />
      )}
    </div>
  );
};

export default Academy;
