import { Button } from '@mui/material';

export const CustomBtn = ({
  title,
  type,
  loading,
  onClick,
  loadingText,
  btnRadius,
  bgcolor,
  btnWidth,
  btnHeight,
  btnFontSize,
  fontColor,
  className,
  disabled,
}) => {
  return (
    <Button
      disabled={disabled || loading}
      type={type || 'button'}
      onClick={onClick}
      className={`${className}`}
      style={{
        borderRadius: btnRadius,
        background: bgcolor,
        width: btnWidth,
        height: btnHeight,
        fontSize: btnFontSize,
        color: fontColor,
      }}
    >
      {loading ? loadingText || 'Loading...' : title}
    </Button>
  );
};
