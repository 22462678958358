import axios from 'axios';
import currentURL from '../env';

const AxiosAll = async (method, url, params, token, next) => {
  //   const state = store.getState();
  //   const { guestDetails } = state.guestReducer;
  const baseURL = currentURL.currentEnv;
  return axios({
    method,
    url: `${baseURL}${url}`,
    // deviceType=3&deviceToken=${guestDetails?.token}&platform=${getScreenSize()}&screen_type=${getScreenSizeImagDimention()},
    data: params,
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
    },
  })
    .then(res => {
      if (res.data.statusCode === 401) {
        // store.dispatch(deleteLogin());
      }
      return res;
    })
    .catch(error => {
      // if (
      //     error?.response?.status === 401 ||
      //     error?.response?.data?.error === "Unauthenticated" ||
      //     error?.response?.data?.Comments === "Unauthenticated"
      // ) {
      //     store.dispatch(deleteLogin());
      // }
      return error?.response;
    });
};

export default AxiosAll;
