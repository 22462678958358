import { Navigate, Route, Routes } from 'react-router';
import ForgotPassword from '../pages/forgot-password';
import Login from '../pages/login';
import ResetPassword from '../pages/reset-password';
import Signup from '../pages/signup';
import SiteMap from '../pages/sitemap';
import PrivacyPolicy from '../pages/privacy-policy';
import Terms from '../pages/terms-of-use';
import CookiePolicy from '../pages/cookie-policy';
import ReturnPolicy from '../pages/return-policy';
import FAQ from '../pages/faqs';
import ContactUs from '../pages/contact-us';
import ViewCertificate from '../pages/view-certificate';

const PublicRoutes = () => {
  return (
    <Routes>
      {/* <Route index element={<Navigate to="/login" replace />} /> */}
      <Route path="*" element={<Navigate to="/login" replace />} />
      <Route path="login" element={<Login />} />
      <Route path="signup" element={<Signup />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="reset-password" element={<ResetPassword />} />
      <Route path="sitemap" element={<SiteMap />} />
      <Route path="privacy-policy" element={<PrivacyPolicy />} />
      <Route path="terms-and-conditions" element={<Terms />} />
      <Route path="certificate/:id" element={<ViewCertificate />} />
      <Route path="cookie-policy" element={<CookiePolicy />} />
      <Route path="return-policy" element={<ReturnPolicy />} />
      <Route path="faqs" element={<FAQ />} />
      <Route path="contact-us" element={<ContactUs />} />
    </Routes>
  );
};

export default PublicRoutes;
