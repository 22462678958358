/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { useNavigate } from 'react-router';
import { images } from '../../assets';
import DarkBtn from './Buttons/DarkBtn';
import { NotifyBadge } from '../MyArtworks/NotifyBadge';
import WhiteBtn from './Buttons/whiteBtn';

export const TopBadge = ({ bgcolor, title }) => {
  return (
    <div style={{ background: bgcolor }} className="badge">
      {title}
    </div>
  );
};

const ImageCard = ({
  collections,
  artworks,
  soldOut,
  image,
  alt,
  objectFit,
  height,
  width,
  bgTransparent,
  btnReq,
  notify,
  status,
  onClick,
  onEditClick,
  quantity,
  allowedAccess,
  artWorkStatus,
  collectionStatus,
}) => {
  const navigate = useNavigate();

  return (
    <div className="img_card" style={{ position: 'relative', background: bgTransparent }}>
      <img
        alt={alt}
        src={image || images.imagePlaceholder}
        className="img_card"
        onClick={onClick}
        style={{
          height,
          width,
          objectFit: objectFit || 'cover',
          background: bgTransparent,
        }}
      />
      {btnReq && (
        <>
          {artworks && artWorkStatus !== 'disabled' && allowedAccess ? (
            <DarkBtn title="Edit" onClick={onEditClick} />
          ) : (
            artworks &&
            !allowedAccess && <WhiteBtn title="Upgrade Plan to edit" onClick={() => navigate('/my-subscription')} />
          )}
          {collections && collectionStatus !== 'Deactive' && allowedAccess ? (
            <DarkBtn title="Edit" onClick={onEditClick} />
          ) : (
            collections &&
            !allowedAccess && <WhiteBtn title="Upgrade Plan to edit" onClick={() => navigate('/my-subscription')} />
          )}
        </>
      )}

      {status && <TopBadge title={status} bgcolor="#F5445E" />}
      {notify && <NotifyBadge>!</NotifyBadge>}
      {((collections && soldOut === 1) || (artworks && quantity === 0)) && (
        <img alt="checkicon" src={images.Ellips} className="ellips_icon" />
      )}
    </div>
  );
};

export default ImageCard;
