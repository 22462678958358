import { TextField } from '@mui/material';
import React from 'react';

const TextAreaBoxLabel = ({ value, onChange, error, placeholder, isRequired, ...props }) => {
  return (
    <div className="textareabox">
      <TextField
        id="standard-multiline-static"
        label={placeholder}
        variant="outlined"
        multiline
        value={value}
        onChange={onChange}
        rows={4}
        required={isRequired || false}
        // variant="standard"
        // placeholder={placeholder}
        {...props}
      />
      {error && <div className="error_text">{error}</div>}
    </div>
  );
};

export default TextAreaBoxLabel;
