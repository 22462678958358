import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import * as Yup from 'yup';
import { Typography } from '@mui/material';
import { deleteUser } from '../../../redux/actions/user';
import AxiosAll from '../../../services/axiosAll';
import notify from '../../../utils/notify';
import DarkBtn from '../../universal/Buttons/DarkBtn';
import GrayBtn from '../../universal/Buttons/GrayBtn';
import TextFieldInput from '../../universal/InputTypeText/TextField';
import SimpleSelectBox from '../../universal/InputTypeText/SelectBox';
import TextAreaBox from '../../universal/InputTypeText/TextareaBox';

export const RefundModal = ({ orderData, update }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [amount, setamount] = useState('0');
  const { userDetails } = useSelector((state) => state.user);

  const handleClose = () => {
    setOpen(false);
  };

  const title = 'Mark dispute as reslove from here.';

  const initialValues = {
    resolve_type: '',
    comment: '',
  };

  const validationSchema = Yup.object().shape({
    resolve_type: Yup.string().required('Select a resolve type'),
    comment: Yup.string().required('Dispute resolution summary is required.'),
  });

  const onSubmit = async (values) => {
    setLoading(true);
    const payload = {
      dispute_res_type: values?.resolve_type,
      dispute_res_comment: values?.comment,
    };
    const response = await AxiosAll('post', `order_refund/${orderData?.id}`, payload, userDetails?.token);
    const data = await response?.data;
    if (data?.statusCode === 200) {
      notify(data?.message, 'success');
      handleClose();
      formik.resetForm();
      setLoading(false);
      makeUpdate();
    } else {
      notify(data?.message, 'error');
      setLoading(false);
    }
  };
  const makeUpdate = () => {
    return update();
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const handleClickOpen = () => {
    setOpen(true);
    formik.resetForm();
  };

  const option = [
    { id: 'resolved_no_refund', name: 'resolve without refund ' },
    { id: 'resolved_and_refund', name: 'resolve with refund ' },
  ];
  useEffect(() => {
    setamount(formik.values.resolve_type === 'resolved_and_refund' ? orderData?.f_order_total : 0);
  }, [formik.values.resolve_type, orderData?.f_order_total]);
  return (
    <div className="refund-popup">
      <DarkBtn title="Refund and resolve" onClick={handleClickOpen} />
      <Dialog sx={{ zIndex: 99 }} open={open} onClose={handleClose}>
        <div className="refund-popup-box">
          <DialogTitle align="center" className="main-title">
            {title}
          </DialogTitle>
          <DialogContent className="pop-content">
            <DialogContentText>
              <form className="refund-form" onSubmit={formik?.handleSubmit}>
                <Typography> Select Resolve type:</Typography>
                <SimpleSelectBox
                  name="resolve_type"
                  options={option}
                  onChange={formik.handleChange}
                  value={formik.values.resolve_type || 0}
                  onBlur={formik.handleBlur}
                  error={formik.touched.resolve_type && formik.errors.resolve_type}
                  helperText={formik.touched.resolve_type && formik.errors.resolve_type}
                  defaultOption="Select"
                  defaultValue={0}
                />
                <Typography>Refunding amount:</Typography>
                <TextFieldInput placeholder="amount" disabled type="number" value={amount} />
                <Typography>Dispute Resolution Summary:</Typography>
                <TextAreaBox
                  placeholder="Write here ..."
                  name="comment"
                  value={formik.values.comment}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  // error={formik.touched.comment && Boolean(formik.errors.comment)}
                  // helperText={formik.touched.comment && formik.errors.comment}
                />
                <Typography variant="body2" style={{ color: '#f5445e' }}>
                  {formik.errors.comment}
                </Typography>
                <div className="btns">
                  <DarkBtn loading={loading} type="submit" title="Submit" />
                  <GrayBtn type="button" title="Cancel" onClick={handleClose} />
                </div>
              </form>
            </DialogContentText>
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
};
