import { Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { formats, modules } from '../../core/constant';
import Editor from '../InputTypeText/Editor';
import { CardContainer } from '../universal/CardContainer';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-quill/dist/quill.snow.css';
import { SelectBoxLabel } from '../InputTypeText/SelectBoxLabel';
import TextFieldInputLabel from '../universal/InputTypeText/TextFieldLabel';
import TextAreaBoxLabel from '../universal/InputTypeText/TextareaBoxLabel';

export const AddArtworkDescription = ({
  dropdowns,
  setArtworkDetails,
  artworkDetailsRef,
  fetchedData,
  setGoodToGo,
}) => {
  const initialValues = {
    title: '',
    category: '',
    medium: '',
    style: '',
    subject: '',
    description: '',
    careDetail: '',
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .matches(/^[a-zA-Z0-9 -]*$/, 'Only alphabets, numbers and hyphens are allowed')
      .required('Title is required'),
    category: Yup.string().required('Category is required'),
    medium: Yup.string().required('Medium is required'),
    style: Yup.string().required('Style is required'),
    subject: Yup.string().required('Subject is required'),
    description: Yup.string().required('Description is required'),
    careDetail: Yup.string(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      setGoodToGo((prev) => ({ ...prev, details: true }));
      setArtworkDetails(values);
    },
  });

  useEffect(() => {
    if (fetchedData?.artworkDetail) {
      const data = {
        title: fetchedData?.artworkDetail?.title,
        category: fetchedData?.artworkDetail?.category,
        medium: fetchedData?.artworkDetail?.medium,
        style: fetchedData?.artworkDetail?.style,
        subject: fetchedData?.artworkDetail?.subject,
        description: fetchedData?.artworkDetail?.description,
        careDetail: fetchedData?.artworkDetail?.careDetail || '',
      };
      formik.setValues(data);
      setArtworkDetails(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedData]);

  const error = document?.querySelector('.error_text');

  useEffect(() => {
    error && error.scrollIntoView({ behavior: 'smooth', block: 'center' });
    setGoodToGo((prev) => ({ ...prev, details: false }));
  }, [error, setGoodToGo]);

  return (
    <CardContainer className="addartworkdescription">
      <form onSubmit={formik.handleSubmit} id="artworkDesc">
        <Typography paragraph sx={{ mb: 1.2 }}>
          Description
        </Typography>

        <TextFieldInputLabel
          name="title"
          // isRequired
          placeholder="Artwork Title *"
          value={formik?.values?.title}
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          error={formik?.touched?.title && Boolean(formik?.errors?.title)}
          helperText={formik?.touched?.title && formik?.errors?.title}
        />
        <SelectBoxLabel
          lableName="Main Category *"
          // isRequired
          name="category"
          // required
          options={dropdowns?.categories?.map((cat) => {
            return { id: cat?.id, name: cat?.v_name };
          })}
          value={formik?.values?.category}
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          error={formik?.touched?.category && Boolean(formik?.errors?.category)}
          helperText={formik?.touched?.category && formik?.errors?.category}
          // defaultOption="category"
          // defaultValue={0}
          className="multi-select"
        />
        <SelectBoxLabel
          lableName="Medium *"
          // isRequired
          name="medium"
          // required
          options={dropdowns?.mediums?.map((md) => {
            return { id: md?.id, name: md?.v_name };
          })}
          value={formik?.values?.medium}
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          error={formik?.touched?.medium && Boolean(formik?.errors?.medium)}
          helperText={formik?.touched?.medium && formik?.errors?.medium}
          // defaultOption="Medium"
          // defaultValue={0}
          className="multi-select"
        />
        <SelectBoxLabel
          lableName="Style *"
          // isRequired
          name="style"
          // required
          options={dropdowns?.styles?.map((sty) => {
            return { id: sty?.id, name: sty?.v_name };
          })}
          value={formik?.values?.style}
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          error={formik?.touched?.style && Boolean(formik?.errors?.style)}
          helperText={formik?.touched?.style && formik?.errors?.style}
          // defaultOption="Style"
          // defaultValue={0}
          className="multi-select"
        />
        <SelectBoxLabel
          lableName="Subject *"
          // isRequired
          name="subject"
          // required
          options={dropdowns?.subjects?.map((sub) => {
            return { id: sub?.id, name: sub?.v_name };
          })}
          value={formik?.values?.subject}
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          error={formik?.touched?.subject && Boolean(formik?.errors?.subject)}
          helperText={formik?.touched?.subject && formik?.errors?.subject}
          // defaultOption="Subject"
          // defaultValue={0}
          className="multi-select"
        />

        <Editor
          modules={modules}
          formats={formats}
          theme="snow"
          placeholder="Description*"
          onBlur={formik.handleBlur}
          // name="description"
          value={formik.values.description}
          error={formik.touched.description && formik.errors.description}
          onChange={(e) => {
            formik.setFieldValue('description', e);
          }}
        />
        <TextAreaBoxLabel
          placeholder="Artwork Care Details"
          name="careDetail"
          value={formik?.values?.careDetail}
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          error={formik?.touched?.careDetail && formik?.errors?.careDetail}
        />
        <input hidden type="submit" ref={artworkDetailsRef} />
      </form>
    </CardContainer>
  );
};
