import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useWindowSize } from '../../hooks/window';
import { MAX_MOBILE_WIDTH } from '../../models/breakpoints.model';
import DarkBtn from '../universal/Buttons/DarkBtn';
import WhiteBtn from '../universal/Buttons/whiteBtn';
import notify from '../../utils/notify';
import AxiosAll from '../../services/axiosAll';
import { notifyMessages } from '../../core/constant';

const MsgForm = ({ Back, className, data, getSellerMessages, updateLatestMessage }) => {
  const { width } = useWindowSize();
  const isMobile = (width || 0) < MAX_MOBILE_WIDTH;
  const { userDetails } = useSelector((state) => state.user);

  const [msg, setMsg] = useState('');

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!msg) {
      notify(notifyMessages?.enterMsg, 'error');
      return;
    }
    try {
      const res = await AxiosAll(
        'post',
        'sellerMessages',
        {
          receiver_id: data?.chat?.receiver?.id,
          message: msg,
          user_type: 'seller',
        },
        userDetails?.token,
      );
      if (res?.data?.statusCode === 200) {
        notify(res?.data?.message, 'success');
        getSellerMessages && getSellerMessages(data?.chat?.id);
        updateLatestMessage && updateLatestMessage(res?.data?.data);
        setMsg('');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="form-container">
      {/* <form onSubmit={onSubmit} className={`form-box ${className}`}>
        <textarea
          className="text-area"
          value={msg}
          rows="5"
          placeholder="Message text"
          onChange={(e) => setMsg(e.target.value)}
        />

        {!isMobile ? (
          <DarkBtn title="send" type="submit" />
        ) : (
          <>
            <DarkBtn title="send" type="submit" />
            <WhiteBtn title="cancel" onClick={Back} />
          </>
        )}
      </form> */}
    </div>
  );
};

export default MsgForm;
