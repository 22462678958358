import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import * as React from 'react';

export const ToggleButtons = ({ setplansType }) => {
  const [alignment, setAlignment] = React.useState('yearly');

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
    setplansType(newAlignment);
  };

  return (
    <ToggleButtonGroup value={alignment} exclusive onChange={handleAlignment} aria-label="text alignment">
      <ToggleButton disabled={alignment === 'monthly'} value="monthly" className="left">
        Monthly
      </ToggleButton>
      <ToggleButton disabled={alignment === 'yearly'} value="yearly" className="right">
        Yearly
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
