import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Loader } from '../../components/universal/Loader';
import { UpgradePlanContainer } from '../../components/universal/BlurContainer';

const MeetUp = () => {
  const [loading, setLoading] = useState(true);

  const { subscriptionDetails } = useSelector((state) => state?.subscription);
  const plan = subscriptionDetails?.active_plan;

  return (
    <div>
      <h2 className="mainheadart font40px"> Meet Up</h2>
    </div>
  );
};

export default MeetUp;
