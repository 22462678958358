import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/system';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AxiosAll from '../../services/axiosAll';

const RenderAccordion = ({ title, faqs }) => {
  return (
    <div className="generalfaq">
      <h2 className="font30px allsubhead">{title}</h2>
      {faqs.map(q => {
        return (
          <Accordion key={q.id} className="faqaccord">
            <AccordionSummary expandIcon={<ArrowDropDownIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography className="font25px">{q?.v_title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="font16px">{q?.l_description}</Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

const FAQ = () => {
  const [FAQData, setFAQData] = useState([]);
  const { userDetails } = useSelector(state => state.user);

  const getFAQData = async () => {
    const res = await AxiosAll('get', 'getFaqs', null, userDetails?.token);
    setFAQData(res?.data?.data);
  };

  useEffect(() => {
    getFAQData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="faqpage">
      <Container className="all-screen-container">
        <h2 className="font30px mainheadfaq">FAQs</h2>
        {FAQData.map(faq => {
          return <RenderAccordion key={faq.id} title={faq?.v_name} faqs={faq?.faqs} />;
        })}
      </Container>
    </div>
  );
};

export default FAQ;
