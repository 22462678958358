import { OrderDetail } from './OrderDetail';
import { ShippingDetail } from './ShippingDetail';

export const OrderGrid = ({
  order,
  open,
  openState,
  handleClickOpen,
  handleClose,
  loadingOrder,
  sellerAddress,
  handleDispatch,
  handleMarkAsDelivered,
  RequestShippingLabel,
  downloadShippingLabel,
  ConfirmPickupAddress,
  update,
}) => {
  return (
    <div className="order-grid">
      <OrderDetail loadingOrder={loadingOrder} order={order} />
      <ShippingDetail
        update={update}
        loadingOrder={loadingOrder}
        order={order}
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        sellerAddress={sellerAddress}
        handleDispatch={handleDispatch}
        handleMarkAsDelivered={handleMarkAsDelivered}
        RequestShippingLabel={RequestShippingLabel}
        downloadShippingLabel={downloadShippingLabel}
        ConfirmPickupAddress={ConfirmPickupAddress}
      />
    </div>
  );
};
