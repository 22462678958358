import { Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import DarkBtn from '../Buttons/DarkBtn';

export const UpgradePlanContainer = ({ mainTitle, btnTitle, btnNavigation, disableImage, disableBtn }) => {
  const navigate = useNavigate();
  return (
    <div className="main-container">
      <Typography variant="h5">{mainTitle}</Typography>
      {!disableImage && <img src="/icons/upgrade.svg" alt="" className="svg-img" />}
      {!disableBtn && <DarkBtn title={btnTitle} onClick={() => navigate(btnNavigation)} />}
    </div>
  );
};
