import { Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
import { GreenBtn } from '../universal/Buttons/GreenBtn';
import UnderLineBtn from '../universal/Buttons/UnderLineBtn';

export const OrderShippingLable = ({
  hideAddress,
  message,
  houseNo,
  roadNo,
  UserRegion,
  UserCountry,
  UserEmail,
  onClick,
  btnTitle,
  bottomMessage,
}) => {
  const navigate = useNavigate();
  return (
    <div className="print-box">
      <Typography paragraph className="top-heading" dangerouslySetInnerHTML={{ __html: message }} />
      {!hideAddress && (
        <div className="input">
          <table>
            <tr>
              <td className="mainhead">House No. :</td>
              <td className="right-tr">{houseNo}</td>
            </tr>
            <tr>
              <td className="mainhead">Road :</td>
              <td className="right-tr">{roadNo}</td>
            </tr>
            <tr>
              <td className="mainhead">Region :</td>
              <td className="right-tr">{UserRegion}</td>
            </tr>
            <tr>
              <td className="mainhead">Country :</td>
              <td className="right-tr">{UserCountry}</td>
            </tr>
            <tr>
              <td className="mainhead">Email :</td>
              <td className="right-tr">{UserEmail}</td>
            </tr>
          </table>
          <UnderLineBtn title="Edit" className="underlinebtn" onClick={() => navigate('/sales/shipping')} />
        </div>
      )}
      <Typography paragraph className="description" dangerouslySetInnerHTML={{ __html: bottomMessage }} />
      <GreenBtn title={btnTitle} onClick={onClick} />
    </div>
  );
};
