'use client';

import Slider from 'react-slick';

import ProfileCard from '../ProfileCard';

const ProductSlider = ({
  isInfinite,
  collection,
  data,
  Artists,
  blogs,
  blog,
  slidesCount,
  position,
  ProductCard,
  reviewCard,
  featured,
  blogCollection,
  blogDetailsCollection,
  investorCard,
  sellerName,
  loadingState,
}) => {
  // const navigation = useNavigation();

  const settings = {
    className: data?.length < 4 ? 'left' : 'center',
    centerMode: position || false,
    centerPadding: '80px',
    dots: false,
    infinite: data?.length < 4 ? false : isInfinite || true,
    speed: 500,
    slidesToShow: slidesCount || 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          centerMode: true,
          // centerPadding: '80px',
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: isInfinite || true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          centerMode: true,
          // centerPadding: '80px',
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: isInfinite || true,
        },
      },
      {
        breakpoint: 430,
        settings: {
          centerMode: true,
          // centerPadding: '80px',
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: isInfinite || true,
        },
      },
      {
        breakpoint: 380,
        settings: {
          centerMode: true,
          centerPadding: '70px',
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: isInfinite || true,
        },
      },
      {
        breakpoint: 330,
        settings: {
          centerMode: true,
          centerPadding: '60px',
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: isInfinite || true,
        },
      },
      {
        breakpoint: 280,
        settings: {
          centerMode: true,
          centerPadding: '50px',
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: isInfinite || true,
        },
      },
    ],
  };

  return (
    <div className="">
      <Slider {...settings}>
        {ProductCard &&
          data?.map((item, index) => {
            return <ProfileCard key={item?.id} item={item} sellerName={sellerName} />;
          })}
      </Slider>
    </div>
  );
};

export default ProductSlider;
