import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilitySharpIcon from '@mui/icons-material/VisibilitySharp';
import { TextField } from '@mui/material';
import { useRef } from 'react';

const TextFieldInput = ({
  placeholder,
  name,
  value,
  type,
  onChange,
  onBlur,
  error,
  helperText,
  password,
  className,
  isPasswordShow,
  setIsPasswordShow,
  ...props
}) => {
  const inputRef = useRef(null);

  const handleWheel = (e) => {
    e.preventDefault();
    const inputElement = inputRef.current;
    if (inputElement) {
      inputElement.blur();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault();
    }
  };
  return (
    <div className="textfiledspan">
      <TextField
        id=""
        placeholder={placeholder || 'Placeholder'}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        inputRef={inputRef} // Add inputRef to the TextFieldInput component
        onWheel={(e) => handleWheel(e)}
        onKeyDown={(e) => handleKeyDown(e)}
        error={error}
        type={type || 'text'}
        className={`iconoverlap ${className}`}
        {...props}
      />
      {password && (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <span className="visibility-icon" onClick={() => setIsPasswordShow(!isPasswordShow)}>
          {isPasswordShow ? <VisibilitySharpIcon /> : <VisibilityOffIcon />}
        </span>
      )}
      {error && <div className="error_text">{helperText}</div>}
    </div>
  );
};

export default TextFieldInput;
