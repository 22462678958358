import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import Accordion from '@mui/material/Accordion';
import { useNavigate } from 'react-router';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Button from '@mui/material/Button';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import DarkBtn from '../../components/universal/Buttons/DarkBtn';
import { images } from '../../assets';
import { request } from '../../services/request';

const AcademyNew = () => {
  const navigate = useNavigate();
  const [modules, setModules] = useState([]);

  /* ================================================================= */

  const parentRef = useRef(null);
  const [parentWidth, setParentWidth] = useState(0);
  const [childWidth, setChildWidth] = useState('90%');

  useEffect(() => {
    const updateWidths = () => {
      if (parentRef.current) {
        const width = parentRef.current.offsetWidth;
        setParentWidth(width);
        setChildWidth(width * 0.9);
      }
    };
    updateWidths();
    const handleResize = () => {
      updateWidths();
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  /* ================================================================= */

  const [loading, setLoading] = useState(false);

  const { userDetails } = useSelector((state) => state.user);

  const getAllModule = async () => {
    try {
      setLoading(true);
      const res = await request({
        url: '/artist/academy/modules',
        method: 'GET',

        token: userDetails?.token,
      });
      setModules(res?.data?.modules);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllModule();
  }, []);

  const { subscriptionDetails } = useSelector((state) => state?.subscription);
  const plan = subscriptionDetails?.active_plan;
  return (
    <div className="academy_new_screen">
      <h2 className="mainheadart font20px"> Cosimo Academy</h2>

      <div style={{ display: 'flex', gap: 20, flexDirection: 'column' }}>
        {modules?.map((item, index) => {
          return plan?.plan_type === 'paid' ? (
            <Accordion key={index} className="line-remove">
              <AccordionSummary
                expandIcon={<ArrowBackIosIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                className="font14pxtable overme"
              >
                <div ref={parentRef} style={{ width: '100%' }}>
                  <p style={{ width: `${childWidth}px` }}> {item?.name}</p>
                </div>
              </AccordionSummary>

              <AccordionDetails>
                {!item?.lessons?.length ? (
                  <div>No Lesson</div>
                ) : (
                  item?.lessons?.map((itemDetail, i) => {
                    return (
                      <Stack
                        key={i}
                        direction={{ xs: 'row', sm: 'row' }}
                        spacing={{ xs: 1, sm: 2, md: 4 }}
                        sx={{ mb: 1.2 }}
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography className="font12pxNew" variant="body1">
                          {itemDetail?.title}
                        </Typography>
                        <Stack direction={{ xs: 'row' }} spacing={3} alignItems="center" className="academy_new_btn">
                          {itemDetail?.watched ? (
                            <>
                              <DarkBtn
                                title="View Lesson"
                                onClick={() => navigate(`/academy/video-lesson/${itemDetail?.id}`)}
                              />
                              <img src={images.checkedNew} alt="" className="checkbal" />
                            </>
                          ) : (
                            <>
                              <DarkBtn
                                title="View Lesson"
                                onClick={() => navigate(`/academy/video-lesson/${itemDetail?.id}`)}
                              />
                              <img src={images.pendingNew} alt="" className="checkbal" />
                            </>
                          )}
                        </Stack>
                      </Stack>
                    );
                  })
                )}
              </AccordionDetails>
            </Accordion>
          ) : (
            <>
              {' '}
              {item?.accessibility === 'Free' ? (
                <Accordion key={index} className="line-remove">
                  <AccordionSummary
                    expandIcon={<ArrowBackIosIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="font14pxtable overme"
                  >
                    <p> {item?.name}</p>
                  </AccordionSummary>

                  <AccordionDetails>
                    {!item?.lessons?.length ? (
                      <div>No Lesson</div>
                    ) : (
                      item?.lessons?.map((itemDetail, i) => {
                        return (
                          <Stack
                            key={i}
                            direction={{ xs: 'row', sm: 'row' }}
                            spacing={{ xs: 1, sm: 2, md: 4 }}
                            sx={{ mb: 1.2 }}
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Typography className="font12pxNew" variant="body1">
                              {itemDetail?.title}
                            </Typography>
                            <Stack
                              direction={{ xs: 'row' }}
                              spacing={3}
                              alignItems="center"
                              className="academy_new_btn"
                            >
                              {itemDetail?.accessibility === 'Paid' ? (
                                <Tooltip
                                  onClick={() => navigate('/my-subscription')}
                                  title="Upgrade"
                                  placement="top-start"
                                >
                                  <img src={images.lockBlack} alt="" className="lock_black" />
                                </Tooltip>
                              ) : itemDetail?.watched ? (
                                <>
                                  <DarkBtn
                                    title="View Lesson"
                                    onClick={() => navigate(`/academy/video-lesson/${itemDetail?.id}`)}
                                  />
                                  <img src={images.checkedNew} alt="" className="checkbal" />
                                </>
                              ) : (
                                <>
                                  <DarkBtn
                                    title="View Lesson"
                                    onClick={() => navigate(`/academy/video-lesson/${itemDetail?.id}`)}
                                  />
                                  <img src={images.pendingNew} alt="" className="checkbal" />
                                </>
                              )}
                            </Stack>
                          </Stack>
                        );
                      })
                    )}
                  </AccordionDetails>
                </Accordion>
              ) : (
                <Box className="box_design">
                  <Stack
                    direction={{ xs: 'row', sm: 'row' }}
                    spacing={{ xs: 1, sm: 2, md: 4 }}
                    // sx={{ mb: 1 }}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography className="font14pxtable overme" variant="body1">
                      {item?.name}
                    </Typography>
                    <Tooltip onClick={() => navigate('/my-subscription')} title="Upgrade" placement="top-start">
                      <img src={images.lockBlack} alt="" className="lock_black" />
                    </Tooltip>
                  </Stack>
                </Box>
              )}
            </>
          );
        })}

        {/* <Box className="box_design">
          <Stack
            direction={{ xs: 'row', sm: 'row' }}
            spacing={{ xs: 1, sm: 2, md: 4 }}
            // sx={{ mb: 1 }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography className="font14pxtable overme" variant="body1">
              Module 2 | Back to Basics
            </Typography>
            <img src={images.lockBlack} alt="" className="lock_black" />
          </Stack>
        </Box>
        <Box className="box_design">
          <Stack
            direction={{ xs: 'row', sm: 'row' }}
            spacing={{ xs: 1, sm: 2, md: 4 }}
            // sx={{ mb: 1 }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography className="font14pxtable overme" variant="body1">
              Module 3 | Being an Artist
            </Typography>
            <img src={images.lockBlack} alt="" className="lock_black" />
          </Stack>
        </Box>

        <Box className="box_design">
          <Stack
            direction={{ xs: 'row', sm: 'row' }}
            spacing={{ xs: 1, sm: 2, md: 4 }}
            // sx={{ mb: 1 }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography className="font14pxtable overme" variant="body1">
              Module 4 | Behind the Scenes
            </Typography>
            <img src={images.lockBlack} alt="" className="lock_black" />
          </Stack>
        </Box>

        <Box className="box_design">
          <Stack
            direction={{ xs: 'row', sm: 'row' }}
            spacing={{ xs: 1, sm: 2, md: 4 }}
            // sx={{ mb: 1 }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography className="font14pxtable overme" variant="body1">
              Module 5 | The Business of Art
            </Typography>
            <img src={images.lockBlack} alt="" className="lock_black" />
          </Stack>
        </Box>

        <Box className="box_design">
          <Stack
            direction={{ xs: 'row', sm: 'row' }}
            spacing={{ xs: 1, sm: 2, md: 4 }}
            // sx={{ mb: 1 }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography className="font14pxtable overme" variant="body1">
              Module 6 | Studio Sessions | Interview Series
            </Typography>
            <img src={images.lockBlack} alt="" className="lock_black" />
          </Stack>
        </Box> */}
      </div>
    </div>
  );
};

export default AcademyNew;
