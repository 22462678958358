// eslint-disable-next-line import/no-extraneous-dependencies
import jsPDF from 'jspdf';
// eslint-disable-next-line import/no-extraneous-dependencies
import html2canvas from 'html2canvas';

export const exportAsPdf = (data, PDFname) => {
  // const data = document.getElementById(PdfElementId);
  html2canvas(data, { allowTaint: false, useCORS: true })
    .then((canvas) => {
      const imgWidth = 300;
      // var pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      // var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png');
      // eslint-disable-next-line new-cap
      const pdf = new jsPDF('p', 'mm', 'a4');
      const position = 15;

      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      // eslint-disable-next-line no-undef
      pdf.save(`${PDFname}.pdf`);
    })
    .catch((error) => {
      console.log(error);
    });
};
