import React from 'react';
import { images } from '../../assets';

const LoginRightText = () => {
  return (
    <div className="textsection">
      <h2 className="loginhead mobilehide">
        List Your Art <br /> in Just a Few Clicks
      </h2>
      <ul className="loginlisttext font18px">
        <li className="">
          <img alt="check-icon" src={images.rightIcon} /> No Selling Fees{' '}
        </li>
        <li>
          <img alt="check-icon" src={images.rightIcon} />
          Full Control Over Listings{' '}
        </li>
        <li>
          <img alt="check-icon" src={images.rightIcon} /> Easy to Set Up & Run{' '}
        </li>
        <li>
          <img alt="check-icon" src={images.rightIcon} /> Personal Webpage{' '}
        </li>
        <li>
          <img alt="check-icon" src={images.rightIcon} />
          Analytics Tools{' '}
        </li>
        <li>
          <img alt="check-icon" src={images.rightIcon} />
          We’ll Sort Shipping{' '}
        </li>
        <li>
          <img alt="check-icon" src={images.rightIcon} /> Securely Collect Payments{' '}
        </li>
      </ul>
    </div>
  );
};

export default LoginRightText;
