import { Skeleton } from '@mui/material';

export const SingleMsgCardSkeleton = ({ index }) => {
  return (
    <div className="message-card" key={index}>
      <div className="profile-img">
        <Skeleton variant="circular" width={42} height={42} />
      </div>
      <div className="buyer-details" style={{ width: '100%' }}>
        <div className="buyer">
          <Skeleton />
          <Skeleton />
        </div>
        <p className="msg-date">
          <Skeleton />
        </p>
      </div>
    </div>
  );
};
