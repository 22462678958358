import React from 'react';

export const NotifyBadge = ({ children }) => {
  return (
    <div
      style={{
        position: 'absolute',
        top: 13,
        right: 16,
        width: 40,
        height: 40,
        background: '#F5445E',
        cursor: 'pointer',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#fff',
      }}
    >
      {children}
    </div>
  );
};
