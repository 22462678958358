'use client';

import React from 'react';
import DarkBtn from '../Buttons/DarkBtn';
import ImageCard from '../imageCard';

const ArtistsCard = ({ item, buttonTitle, onClick, className }) => {
  return (
    <div className={`artistscard ${className}`}>
      <div className="image-section ">
        <ImageCard image={item?.v_image} alt={`${item?.v_fname} ${item?.v_lname}`} onClick={onClick} />
        <div
        // className="protextsection"
        >
          <p className="proname">
            {item?.v_fname} {item?.v_lname}
          </p>
          <p className="prolocation">{item?.user_city?.v_title}</p>
        </div>
        {/* <div className="artistbtn">
          <DarkBtn title={buttonTitle || 'See Artwork'} onClick={onClick} />
        </div> */}
      </div>
    </div>
    // <div className="blogcard">
    //   <div className="image-section artistscard">
    //   <ImageCard
    //     image={item?.v_image}
    //     alt={`${item?.v_fname} ${item?.v_lname}`}
    //   />
    //     <div className="blogtitledate">
    //       <p className="proname">{item?.v_fname} {item?.v_lname}</p>
    //       <p className="prolocation">{item?.user_city?.v_title}</p>
    //     </div>
    //     <div className="blogbtn">
    //       {/* //! NEED TO ADD LINK */}
    //       <DartBtn title="View ArtWork" link="/" />
    //     </div>
    //   </div>
    // </div>
  );
};

export default ArtistsCard;
