import { toast } from 'react-hot-toast';

const notify = (message, type) => {
  toast(`${message}`, {
    type: `${type}`,
    position: 'bottom-center',
    borderRadius: '2px',
    style: {
      background: '#fff',
      color: '#333',
      zIndex: 9999999,
      textAlign: 'center',
    },
  });
};

export default notify;
