import { Button } from '@mui/material';

const DarkBtn = ({ title, type, loading, onClick, loadingText, styleBtn, disabled }) => {
  return (
    <Button disabled={disabled || loading} className={`darkbtn ${styleBtn}`} type={type || 'button'} onClick={onClick}>
      {/* Find Out More */}
      {loading ? loadingText || 'Loading...' : title}
    </Button>
  );
};

export default DarkBtn;
