import { Container } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import MsgForm from '../../../components/AllMessages/MsgForm';
import { SigngleMsgView } from '../../../components/AllMessages/SigngleMsgView';
import { SingleBagdeView } from '../../../components/AllMessages/SingleBagdeView';
import { CardContainer } from '../../../components/universal/CardContainer';
import MsgPreviewBox from '../../../components/universal/MsgPreviewBox';
import { useWindowSize } from '../../../hooks/window';
import { MAX_MOBILE_WIDTH } from '../../../models/breakpoints.model';
import AxiosAll from '../../../services/axiosAll';

const MessageDetails = () => {
  const { width } = useWindowSize();
  const isMobile = (width || 0) < MAX_MOBILE_WIDTH;
  const params = useParams();

  const [allChatsData, setAllChatsData] = useState([]);
  const [singelMessageBox, setSingelMessageBox] = useState(true);
  const [sellerMsg, setSellerMsg] = useState([]);

  const { userDetails } = useSelector(state => state.user);
  const navigate = useNavigate();

  const getSellerMessages = useCallback(
    async id => {
      try {
        const res = await AxiosAll('get', `sellerMessages?i_chat_id=${id}`, null, userDetails?.token);
        if (res?.data?.statusCode === 200) {
          setSellerMsg(res?.data?.data);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [userDetails?.token],
  );

  const getAllChats = async () => {
    try {
      const res = await AxiosAll('get', 'chats', null, userDetails?.token);
      if (res?.data?.data?.length > 0) {
        setAllChatsData(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const currentChat = useMemo(() => {
    return allChatsData?.find(item => item?.id == params?.id);
  }, [allChatsData, params?.id]);

  const handleScroll = useCallback(() => {
    const child = document.getElementById('child');

    if (child?.scrollTop > 0) {
      setSingelMessageBox(false);
    } else {
      setSingelMessageBox(true);
    }
  }, []);

  useEffect(() => {
    if (isMobile) {
      const child = document.getElementById('child');
      child?.addEventListener('scroll', handleScroll);
    }
    return () => {
      const child = document.getElementById('child');
      child?.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll, isMobile]);

  useEffect(() => {
    getAllChats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (params?.id) {
      getSellerMessages(params?.id);
    }
  }, [params?.id, getSellerMessages]);

  useEffect(() => {
    const child = document.getElementById('child');
    if (child) {
      child.scrollTo({
        top: child.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [sellerMsg]);

  return (
    <div className="allmessage">
      <Container className="all-screen-container search_result_container_inner">
        <div className="searchbtninput">
          <div className="font25px mobilehide">Your Messages</div>
          <div className="font25px mobileshow">Your Messages</div>
        </div>
        <div className="message-section">
          <CardContainer height={775} gap={25} className="message-card-id">
            {singelMessageBox ? (
              <SigngleMsgView data={currentChat} messageId={params?.id} className="full-width" />
            ) : (
              <SingleBagdeView
                data={currentChat}
                messageId={params?.id}
                handler={() => navigate('/community/messages')}
              />
            )}
            <div className="scroll-dir" id="child">
              <div className="messagesbox">
                {sellerMsg?.messages?.map((msg, i) => {
                  return (
                    <MsgPreviewBox
                      senderId={msg?.i_sender_id}
                      senderName={msg?.sender?.v_fname}
                      senderDescription={msg?.l_message}
                      // sendTime={moment(msg?.created_at).format('HH:mm')}
                      // sendDate={moment(msg?.created_at).format('DD/MM/YY')}
                      key={msg?.id}
                    />
                  );
                })}
              </div>
            </div>
            <MsgForm
              singelMessageBox={singelMessageBox}
              data={sellerMsg}
              getSellerMessages={getSellerMessages}
              className="id-form"
              Back={() => navigate('/community/messages')}
            />
          </CardContainer>
        </div>
      </Container>
    </div>
  );
};

export default MessageDetails;
