import { Typography } from '@mui/material';
import React from 'react';
import { images } from '../../assets';
import { CardContainer } from '../universal/CardContainer';
import { UploadImgCard } from '../upload_collection/UploadImgCard';

export const UploadImgSection = ({
  error,
  primaryImage,
  secondaryImages,
  primaryImageRootProps,
  primaryImageInputProps,
  secondaryImageRootProps,
  secondaryImageInputProps,
  handleRemoveImage,
}) => {
  return (
    <CardContainer className="addartworkcard">
      <Typography variant="h4">Add Images</Typography>
      <div className="add-image-card">
        <div {...primaryImageRootProps()}>
          <UploadImgCard
            status="primary"
            image={primaryImage}
            className="primary-card"
            iconImg={images.addImage}
            mainTitle="Add Primary Image"
            handleRemoveImage={handleRemoveImage}
            description="Drag & Drop or Select from File"
            displayBool
            flexGap
          />
          <input
            hidden
            name="primaryImage"
            type="file"
            accept="image/x-png ,image/jpeg"
            {...primaryImageInputProps()}
          />
          {error.primaryImage && <div className="error_text">{error.primaryImage}</div>}
        </div>
        <div {...secondaryImageRootProps()}>
          <UploadImgCard
            status="secondary"
            className="secondary-card"
            image={secondaryImages}
            iconImg={images.addImage}
            handleRemoveImage={handleRemoveImage}
            mainTitle="Add Secondary Image"
            description="Drag & Drop or Select from File"
            displayBool
            flexGap
          />
          <input
            hidden
            name="secondaryImages"
            type="file"
            multiple
            accept="image/x-png ,image/jpeg"
            {...secondaryImageInputProps()}
          />
        </div>
      </div>
    </CardContainer>
  );
};
