export const OrderDetailTable = ({ order }) => {
  return order?.is_pod_order === 1 ? (
    <div className="order-detail-table">
      <div className="order-items">
        <h4 className="item">Cosimo Commission</h4>
        <h4 className="price">£{order?.f_admin_fee?.toFixed(2)}</h4>
      </div>
      <div className="order-items">
        <h4 className="item">Artist Commission</h4>
        <h4 className="price">£{order?.f_seller_amount?.toFixed(2)}</h4>
      </div>
      {order?.f_discount > 0 && (
        <div className="order-items">
          <h4 className="item">Discount (Applied)</h4>
          <h4 className="price">- £{order?.f_discount?.toFixed(2)}</h4>
        </div>
      )}
      <div className="order-total">
        <h3 className="item">Total</h3>
        <h3 className="price">£{order?.f_order_total?.toFixed(2)}</h3>
      </div>
    </div>
  ) : (
    <div className="order-detail-table">
      <div className="order-items">
        <h4 className="item">Subtotal</h4>
        <h4 className="price">£{order?.sub_total?.toFixed(2)}</h4>
      </div>
      {order?.f_discount > 0 && (
        <div className="order-items">
          <h4 className="item">Discount (Applied)</h4>
          <h4 className="price">- £{order?.f_discount?.toFixed(2)}</h4>
        </div>
      )}
      <div className="order-items">
        <h4 className="item">Est. Delivery</h4>
        <h4 className="price">£{order?.f_cost_of_shipment?.toFixed(2)}</h4>
      </div>
      <div className="order-total">
        <h3 className="item">Total</h3>
        <h3 className="price">£{order?.f_order_total?.toFixed(2)}</h3>
      </div>
    </div>
  );
};
