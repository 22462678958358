import { Typography } from '@mui/material';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { images } from '../../assets';
import DarkBtn from '../../components/universal/Buttons/DarkBtn';
import { CardContainer } from '../../components/universal/CardContainer';
import CustomTable from '../../components/universal/CustomTable';
import PaginationBox from '../../components/universal/PaginationBox';
import { pageLimitOptions } from '../../core/constant';
import { request } from '../../services/request';

const SubscriptionHistory = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const searchParam = new URLSearchParams(useLocation().search);
  const pageParams = searchParam.get('page');

  const { userDetails } = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [Subscriptions, setSubscriptions] = useState([]);
  const [CurrentSubscriptions, setCurrentSubscriptions] = useState({});
  const [page, setPage] = useState(pageParams ? +pageParams : 1);
  const [limit, setLimit] = useState(12);
  const [parameters, setParameters] = useState({});
  const { subscriptionDetails } = useSelector((state) => state?.subscription);
  const planActive = subscriptionDetails?.active_plan;
  const headCells = [
    { label: 'Amount' },
    { label: 'Description' },
    { label: 'Date' },
    { label: 'Status' },
    { label: 'Action' },
  ];

  const isShowPagination = !isLoading && Subscriptions?.total > limit;

  const getSubscriptions = useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await request({
        url: 'artist/customerSubscriptions',
        method: 'GET',
        params: {
          page,
          limit,
        },
        token: userDetails?.token,
      });

      setSubscriptions(res?.data);
      setCurrentSubscriptions(res?.data?.invoices[0]?.subscription_detail);
      setIsLoading(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      setIsLoading(false);
    }
  }, [page, limit, userDetails?.token]);

  const onPageChange = (e, pg) => {
    setPage(pg);
    navigate(`${pathname}?${navigator(parameters, 'page', pg)}`);
  };

  const onPageLimitChange = (e) => {
    setPage(1);
    setLimit(e.target.value);
    navigate(`${pathname}?${navigator(parameters, 'page', 1)}`);
  };

  const calculatePlanDuration = (plan) => {
    const interval = plan?.v_current_period_start;
    const intervalEnd = plan?.v_current_period_end;
    const duration = moment.duration(moment(intervalEnd).diff(moment(interval)));
    const days = duration.asDays();
    const months = duration.asMonths();
    return months >= 12 ? `Yearly` : (days >= 30 || months < 12) && `Monthly`;
  };

  useEffect(() => {
    getSubscriptions();
  }, [getSubscriptions]);
  return (
    <div className="saved-addresses">
      <div className="saved-card">
        <div className="card-text main-heading font25px">Subscription History</div>
        <Typography paragraph>Current Subscription</Typography>
        {/* <Typography variant="h4">Subscription Credits :{Subscriptions?.stripe_customer_balance?.toFixed(2)}</Typography> */}
      </div>
      {/* <div>Invoice Credit Balance :{Math.abs(Subscriptions?.stripe_customer_balance)?.toFixed(2)}</div>
      <div>Current Subscription </div> */}
      <CardContainer className="activity" topMargin="0px" bottomMargin="0px">
        <div className="address-details">
          {Subscriptions?.invoices?.length === 0 ? (
            <div className="address-text">
              <Typography variant="body1" className="font14px">
                Name: {planActive?.v_name}
                <span style={{ color: '#80C86E' }}> (Active) </span>
                Free
              </Typography>
              <Typography>Billing Invoice on : No Future Invoices</Typography>
            </div>
          ) : (
            <div className="address-text">
              <Typography variant="body1" className="font14px">
                Name: {CurrentSubscriptions?.plan_detail?.v_name}
                <span style={{ color: '#80C86E' }}> (Active) </span>
                {calculatePlanDuration(CurrentSubscriptions)}
              </Typography>
              {CurrentSubscriptions?.i_cancel_at_period_end && (
                <Typography>
                  Cancels on : {moment(CurrentSubscriptions?.v_current_period_end)?.format('D MMMM YYYY')}
                </Typography>
              )}
              <Typography>
                Billing Invoice on :{' '}
                {CurrentSubscriptions?.i_cancel_at_period_end
                  ? 'No Future Invoices'
                  : moment(CurrentSubscriptions?.v_current_period_end)?.format('D MMMM YYYY')}
              </Typography>
            </div>
          )}
        </div>
        <DarkBtn
          title={Subscriptions?.invoices?.length == 0 ? 'Upgrade Plan' : 'Change Plan'}
          onClick={() => navigate('/my-subscription')}
        />
      </CardContainer>
      <CustomTable
        isEmpty={Subscriptions?.invoices?.length < 1}
        rows={Subscriptions?.invoices}
        headCells={headCells}
        heading="Payments"
        routePath="/sales/Subscriptions/detail/"
        isButton
        buttonLabel="Invoice"
        isShowHeading
        invoiceText="Credit Balance"
        balanceText={Math.abs(Subscriptions?.stripe_customer_balance?.toFixed(2))}
        additionalText="Current Subscription "
        imgInfo={images.Info}
      />
      {isShowPagination && (
        <PaginationBox
          count={Subscriptions?.last_page}
          page={page}
          onChange={onPageChange}
          value={limit}
          pageLimit={pageLimitOptions}
          onChangePageLimit={onPageLimitChange}
        />
      )}
    </div>
  );
};

export default SubscriptionHistory;
