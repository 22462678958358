import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import * as React from 'react';

const FlatCustomSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 69,
  height: 20,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 0,
    transitionDuration: '300ms',
    color: '#F5445E',
    '&.Mui-checked': {
      transform: 'translateX(23px)',
      color: '#80C86E',
      '& + .MuiSwitch-track': {
        backgroundColor: '#D9D9D9',
        opacity: 1,
        border: 0,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 46,
    height: 20,
    borderRadius: 26 / 2,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#D9D9D9',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

export const CustomizedSwitches = ({ name, checked, onChange }) => {
  return (
    <FormGroup>
      <FormControlLabel
        name={name}
        checked={checked}
        onChange={onChange}
        control={<FlatCustomSwitch sx={{ m: 1 }} />}
      />
    </FormGroup>
  );
};
