import { Checkbox, FormControlLabel, Tooltip, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { SimpleSelectBox } from '../InputTypeText/SelectBox';
import { CardContainer } from '../universal/CardContainer';
import { TagAddInput } from './TagAddInput';
import { SelectBoxLabel } from '../InputTypeText/SelectBoxLabel';
import { images } from '../../assets';

export const ExtraTag = ({
  dropdowns,
  tagKeywords,
  setArtworkTags,
  artworkTagsRef,
  fetchedData,
  setGoodToGo,
  plan,
}) => {
  const initialValues = {
    tags: [],
    is_adult_content: '',
    add_to_collection: '',
    visible_to_public: '',
    colours: [],
  };

  const validationSchema = Yup.object().shape({
    is_adult_content: Yup.string().required('This field is required'),
    tags: Yup.array().of(Yup.string().required('Tag is required')).min(1, 'At least one tag is required'),
    add_to_collection: Yup.string(),
    visible_to_public: Yup.string().required('Visible to public is required'),
    colours: Yup.array().of(Yup.string()),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      setGoodToGo((prev) => ({ ...prev, tags: true }));
      setArtworkTags(values);
    },
  });

  const error = document?.querySelector('.error_text');

  useEffect(() => {
    error && error.scrollIntoView({ behavior: 'smooth', block: 'center' });
    setGoodToGo((prev) => ({ ...prev, tags: false }));
  }, [error, setGoodToGo]);

  useEffect(() => {
    if (fetchedData?.artworkTag) {
      const data = {
        is_adult_content: fetchedData?.artworkTag?.is_adult_content,
        tags: fetchedData?.artworkTag?.tags,
        add_to_collection: fetchedData?.artworkTag?.add_to_collection,
        visible_to_public: fetchedData?.artworkTag?.visible_to_public,
        colours: fetchedData?.artworkTag?.colours,
      };
      formik.setValues(data);
      setArtworkTags(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedData]);
  return (
    <CardContainer className="extra-tag">
      <Typography paragraph>Extras</Typography>
      <div className="presets">
        <Typography className="adult-text-new">
          This artwork contains adult content or nudity that may be unsuitable for some viewers.
        </Typography>
        <div className="tooltip-section">
          <SelectBoxLabel
            lableName="Is Adult Content? *"
            // isRequired
            name="is_adult_content"
            className="public"
            options={[
              { id: '1', name: 'Yes' },
              { id: '0', name: 'No' },
            ]}
            value={formik?.values?.is_adult_content}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            error={formik?.touched?.is_adult_content && formik?.errors?.is_adult_content}
            helperText={formik?.touched?.is_adult_content && formik?.errors?.is_adult_content}
            // defaultOption="Visible to Public?"
            // defaultValue={0}
          />
          <Tooltip
            title="By selecting yes, you are acknowledging that this artwork is intended for mature audiences. Adult content is allowed on the platform but must be marked as such, and must follow our wider community guidelines and content policies. See our Terms & Conditions for more information."
            placement="top"
          >
            <img src={images?.Info} alt="" />
          </Tooltip>
        </div>
        <div className="tags">
          <Typography paragraph>Tags (Add Up To Three) *</Typography>
          <TagAddInput placeholder="text" formik={formik} tagKeywords={tagKeywords} />
        </div>
        {(plan?.v_no_of_sub_collections > 0 || plan?.v_no_of_sub_collections === 'Unlimited') && (
          <SelectBoxLabel
            lableName="Add to Collection"
            name="add_to_collection"
            className="collection"
            options={dropdowns?.my_collections?.map((cat) => {
              return { id: cat?.id, name: cat?.title };
            })}
            value={formik?.values?.add_to_collection}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            error={formik?.touched?.add_to_collection && formik?.errors?.add_to_collection}
            helperText={formik?.touched?.add_to_collection && formik?.errors?.add_to_collection}
            // defaultOption="Add to Collection"
            // defaultValue={0}
          />
        )}
        <SelectBoxLabel
          lableName="Visible to Public? *"
          // isRequired
          name="visible_to_public"
          className="public"
          options={[
            { id: 'enabled', name: 'Yes' },
            { id: 'disabled', name: 'No' },
          ]}
          value={formik?.values?.visible_to_public}
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          error={formik?.touched?.visible_to_public && formik?.errors?.visible_to_public}
          helperText={formik?.touched?.visible_to_public && formik?.errors?.visible_to_public}
          // defaultOption="Visible to Public?"
          // defaultValue={0}
        />
        <div className="color-pick">
          <Typography paragraph>Colour</Typography>
          {dropdowns?.colours?.map((color, index) => {
            return (
              <FormControlLabel
                key={`colours${index}`}
                name="colours"
                style={{
                  background: color?.v_colour_code,
                  border:
                    (color?.v_colour_code === '#fff' || color?.v_colour_code === '#ffffff') && '1px solid #D0CFCF ',
                }}
                className={formik?.values?.colours?.includes(color?.id.toString()) ? null : 'colordiv'}
                control={
                  <Checkbox
                    name="colours"
                    value={color?.id}
                    checked={formik?.values?.colours?.includes(color?.id.toString())}
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    error={formik?.touched?.colours && formik?.errors?.colours}
                    // helperText={formik?.touched?.colours && formik?.errors?.colours}
                  />
                }
              />
            );
          })}
        </div>
      </div>
      <input hidden onClick={formik?.handleSubmit} ref={artworkTagsRef} />
    </CardContainer>
  );
};
