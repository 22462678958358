import { Skeleton } from '@mui/material';

export const ArtistCardSkeleton = ({ imgWidth, imgHeight, hideExtraSkeleton }) => {
  return (
    <div className="artistscard">
      <div className="image-section">
        <div className="imgskeleton">
          <Skeleton variant="rounded" width={imgWidth} height={imgHeight} style={{ borderRadius: '1.6vw' }} />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 12,
            marginTop: 12,
          }}
        >
          {!hideExtraSkeleton && (
            <>
              <Skeleton variant="rounded" />
              <Skeleton variant="rounded" />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
