import { Button } from '@mui/material';

const UnderLineBtn = ({ title, type, loading, onClick, loadingText, styleBtn }) => {
  return (
    <Button disabled={loading} className={`underline ${styleBtn}`} type={type || 'button'} onClick={onClick}>
      {/* Find Out More */}
      {loading ? loadingText || 'Loading...' : title}
    </Button>
  );
};

export default UnderLineBtn;
