import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import * as React from 'react';
import { useNavigate } from 'react-router';
import navigator from '../../../utils/navigator';

export const ReferralToggleButtons = ({ logType, setLogType, setPage, pathname, parameters, setLimit }) => {
  const navigate = useNavigate();

  const handleAlignment = (event, newAlignment) => {
    setLogType(newAlignment);
    setPage(1);
    setLimit(12);
    navigate(`${pathname}?${navigator(parameters, 'page', 1)}`);
  };

  return (
    <ToggleButtonGroup
      value={logType}
      exclusive
      onChange={handleAlignment}
      aria-label="text alignment"
      className="logs-toggle"
    >
      <ToggleButton disabled={logType === 'referral'} value="referral" className="left">
        Referral Logs
      </ToggleButton>
      <ToggleButton disabled={logType === 'credit'} value="credit" className="right">
        Credit Balance Logs
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
