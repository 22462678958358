import { Typography } from '@mui/material';
import { images } from '../../assets';

export const SetList = ({
  accountDetails,
  aboutYourself,
  listedArtwork,
  organiseShipping,
  acceptPayments,
  launchYour,
}) => {
  return (
    <div className="set-list">
      <div className="check-list">
        <img src={accountDetails ? images.checked : images.pending} alt="" />
        <Typography>Account Details</Typography>
      </div>
      <div className="check-list">
        <img src={aboutYourself ? images.checked : images.pending} alt="" />
        <Typography>Tell Us About Yourself</Typography>
      </div>
      <div className="check-list">
        <img src={listedArtwork ? images.checked : images.pending} alt="" />
        <Typography>List Your Work</Typography>
      </div>
      <div className="check-list">
        <img src={organiseShipping ? images.checked : images.pending} alt="" />
        <Typography>Organise Shipping</Typography>
      </div>

      <div className="check-list">
        <img src={acceptPayments ? images.checked : images.pending} alt="" />
        <Typography>Accept Payments</Typography>
      </div>
      <div className="check-list">
        <img src={launchYour ? images.checked : images.pending} alt="" />
        <Typography>Launch Your Page</Typography>
      </div>
    </div>
  );
};
