import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DarkBtn from '../../components/universal/Buttons/DarkBtn';
import { CardContainer } from '../../components/universal/CardContainer';
import TextFieldInput from '../../components/universal/InputTypeText/TextField';
import AxiosAll from '../../services/axiosAll';

const ReferralScheme = () => {
  const [referralLink, setReferralLink] = useState('');
  const [linkCopied, setLinkCopied] = useState(false);
  const { userDetails } = useSelector((state) => state.user);

  const getSupportDropdown = async () => {
    try {
      const res = await AxiosAll('get', 'referralCode', null, userDetails?.token);

      if (res?.data?.statusCode === 200) {
        const referralCode = res?.data?.data?.referral_code;
        setReferralLink(`${process.env.REACT_APP_SELLER_APP_URL}signup?referral_code=${referralCode}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (userDetails.token) {
      getSupportDropdown();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (linkCopied) {
      setTimeout(() => {
        setLinkCopied(false);
      }, 2000);
    }
  }, [linkCopied]);

  return (
    <div className="contactpage referral-contact">
      <div className="loginpage">
        <CardContainer>
          <div className="login_box">
            <h2 className="toptext">Share & Earn With Cosimo!</h2>
            <h3 className="toptext">£10 to Your Friend, £10 to You</h3>
            <p className="toptext font20px">
              For every artist who signs up to a paid plan using your referral link, we’ll not only give that user £10
              credit towards our paid memberships, but we’ll also give you £10 credit too.
            </p>
            <div className="contactfiled">
              <TextFieldInput
                type="text"
                name="referralLink"
                value={referralLink}
                placeholder="Referral Link"
                readOnly
              />

              <DarkBtn
                title={linkCopied ? 'Link Copied' : 'Copy Link'}
                onClick={() => {
                  navigator.clipboard.writeText(referralLink);
                  setLinkCopied(true);
                }}
              />
            </div>
          </div>
        </CardContainer>
      </div>
    </div>
  );
};
export default ReferralScheme;
