'use client';

import { TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';

export const SelectBoxLabel = ({
  options,
  onChange,
  value,
  lableName,
  defaultValue,
  defaultOption,
  error,
  helperText,
  isRequired,
  ...props
}) => {
  return (
    <div className="selectbox">
      <FormControl sx={{ m: 1, minWidth: 80 }} className="selectboxwidth">
        <TextField
          required={isRequired || false}
          id="outlined-select-currency"
          select
          label={lableName}
          className="select-field"
          value={value}
          onChange={onChange}
          {...props}
          //   id="demo-simple-select-autowidth"
        >
          {defaultOption && (
            <MenuItem selected value={defaultValue} disabled className="drop-down-bg">
              {defaultOption}
            </MenuItem>
          )}
          {options?.map((option, index) => {
            return (
              <MenuItem key={index} value={option?.id} className="drop-down-bg">
                {option?.name}
              </MenuItem>
            );
          })}
        </TextField>
        {error && <div className="error_text">{helperText}</div>}
      </FormControl>
    </div>
  );
};
