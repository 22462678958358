import { Typography } from '@mui/material';
import React from 'react';
import { CardContainer } from '../CardContainer';

export const NoDataContainer = ({ cardHeight, msgText, className, gapBtw }) => {
  return (
    <CardContainer height={cardHeight} gap={gapBtw} className={`${className}`}>
      <img alt="" src="/icons/no-data.svg" className="nodataimg" />
      <Typography variant="h3" align="center">
        {msgText}
      </Typography>
    </CardContainer>
  );
};
