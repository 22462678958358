export const FeedbackCard = ({ comment, stars, starWidth, starHeight, condition, delivery, value, quality }) => {
  return (
    <>
      <div className="message-preview-box">
        <h3 className="message-text">Comment :</h3>
        <h3 className="message-text">{comment}</h3>
      </div>
      <div className="message-preview-box">
        <span>Overall: </span>
        <div className="stars">
          {[...Array(stars)].map((i) => (
            <img src="/icons/bigRatingStar.svg" alt="starIcon" width={starWidth} height={starHeight} key={i} />
          ))}
        </div>
      </div>
      <div className="message-preview-box">
        <span>Delivery: </span>
        <div className="stars">
          {[...Array(delivery)].map((i) => (
            <img src="/icons/bigRatingStar.svg" alt="starIcon" width={starWidth} height={starHeight} key={i} />
          ))}
        </div>
      </div>
      <div className="message-preview-box">
        <span>Condition: </span>
        <div className="stars">
          {[...Array(condition)].map((i) => (
            <img src="/icons/bigRatingStar.svg" alt="starIcon" width={starWidth} height={starHeight} key={i} />
          ))}
        </div>
      </div>
      <div className="message-preview-box">
        <span>Value: </span>
        <div className="stars">
          {[...Array(value)].map((i) => (
            <img src="/icons/bigRatingStar.svg" alt="starIcon" width={starWidth} height={starHeight} key={i} />
          ))}
        </div>
      </div>
      <div className="message-preview-box">
        <span>Quality: </span>
        <div className="stars">
          {[...Array(quality)].map((i) => (
            <img src="/icons/bigRatingStar.svg" alt="starIcon" width={starWidth} height={starHeight} key={i} />
          ))}
        </div>
      </div>
    </>
  );
};
