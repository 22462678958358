import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

export const BasicModal = ({ url, open, setOpen }) => {
  const iframeRef = React.useRef(null);
  const stripe3DSecureURL = 'https://hooks.stripe.com/3d_secure/complete/acct_1';

  // Handle the iframe onload event
  const handleOnLoad = () => {
    // Check if the iframe URL matches with the Stripe 3D Secure URL
    if (iframeRef.current?.contentWindow?.location.href?.includes(stripe3DSecureURL)) {
      // Set the shouldClose state to true
      console?.log('link', iframeRef.current?.contentWindow?.location.href);
      setOpen();
      // Navigate to the success page
      window.location.assign('/my-subscription');
    }
  };
  return (
    <Dialog fullScreen open={open} onClose={setOpen}>
      <DialogContent>
        <iframe
          style={{ width: '100%', height: '100%' }}
          src={url}
          onLoad={handleOnLoad}
          ref={iframeRef}
          title="iframe"
        />
      </DialogContent>
    </Dialog>
  );
};
