/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { images } from '../../../assets';
import notify from '../../../utils/notify';
import { notifyMessages } from '../../../core/constant';
import DarkBtn from '../Buttons/DarkBtn';

export const ResponsiveDrawer = ({ headerData }) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const { pathname } = useLocation();

  const { userDetails } = useSelector((userState) => userState.user);

  const searchAllLink = `/search/artworks/search-all?query=search-all`;

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
    setState({
      top: false,
      left: false,
      bottom: false,
      right: false,
    });
  }

  const toggleDrawer = (anchor, open) => (event) => {
    if (pathname.includes('personalize') && !userDetails?.user?.is_completed_seller) {
      notify(notifyMessages?.completePersonalization, 'error');
      return;
    }
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <>
      <Box
        sx={{
          width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 'auto',
        }}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <div style={{ textAlign: 'end', paddingTop: '10px', paddingRight: '10px' }}>
          <CloseIcon />
        </div>
      </Box>
      <Box>
        <div className="mobilemenu">
          {userDetails?.token ? (
            <>
              <Typography className="seller-art-heading">
                <Link href="/dashboard" to="/dashboard" onClick={() => handleDrawerToggle()} className="menumaintext">
                  Dashboard
                </Link>
              </Typography>
              <Typography className="seller-art-heading">
                <Link
                  href={`${process.env.REACT_APP_USER_APP_URL}artist/${userDetails?.user?.v_fname}-${userDetails?.user?.v_lname}/${userDetails?.user?.id}`}
                  to={`${process.env.REACT_APP_USER_APP_URL}artist/${userDetails?.user?.v_fname}-${userDetails?.user?.v_lname}/${userDetails?.user?.id}`}
                  onClick={() => handleDrawerToggle()}
                  className="menumaintext"
                >
                  View Portfolio
                </Link>
              </Typography>
              <Typography className="seller-art-heading">
                <Link
                  href={`${process.env.REACT_APP_USER_APP_URL}match-maker`}
                  to={`${process.env.REACT_APP_USER_APP_URL}match-maker`}
                  onClick={() => handleDrawerToggle()}
                  className="menumaintext"
                >
                  Explore
                </Link>
              </Typography>
            </>
          ) : (
            <div>
              <div>
                {headerData?.categories?.map((item) => (
                  <Typography key={item?.id} value={item?.id} className="seller-art-heading">
                    <Link
                      href={`${process.env.REACT_APP_USER_APP_URL}search/artworks/search-all?main_category=${item?.id}`}
                      to={`${process.env.REACT_APP_USER_APP_URL}search/artworks/search-all?main_category=${item?.id}`}
                      // href={`${process.env.REACT_APP_USER_APP_URL}search/artworks/search-all`}
                      className="menumaintext"
                      key={item?.id}
                      // style={{
                      //   color: active_index === `${item?.id}` ? '#f5445e' : '#000000',
                      // }}
                    >
                      {item?.v_name}
                    </Link>
                  </Typography>
                ))}
              </div>

              <div>
                <Typography className="seller-art-heading">
                  <Link
                    href={`${process.env.REACT_APP_USER_APP_URL}about-us`}
                    to={`${process.env.REACT_APP_USER_APP_URL}about-us`}
                    className="menumaintext"
                    // onClick={handleClickBtn}
                    style={{
                      color: pathname === '/about-us' ? '#000' : '#f5445e',
                    }}
                  >
                    For Artists
                  </Link>
                </Typography>
              </div>
            </div>
          )}
        </div>
      </Box>
    </>
  );

  return (
    <div>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <div
            tabIndex={0}
            role="button"
            onClick={toggleDrawer(anchor, true)}
            style={{ position: 'relative' }}
            className="filter-icon"
          >
            <img src={images.sidebarIcon} aria-label="Open drawer" />
          </div>
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
};
