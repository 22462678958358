import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from '@mui/material';
import AxiosAll from '../../services/axiosAll';
import notify from '../../utils/notify';
import DarkBtn from '../universal/Buttons/DarkBtn';
import { request } from '../../services/request';
import WhiteBtn from '../universal/Buttons/whiteBtn';

const CancelPlanDailog = ({ cid, CancelSubscription, title }) => {
  const [open, setOpen] = React.useState(false);
  const [loading, setloading] = React.useState(true);
  const [selectCancellationType, setSelectCancellationType] = React.useState('later');
  const [Cancelreasons, setCancelreasons] = React.useState('');
  const [error, seterror] = React.useState({ show: false, type: 'no' });
  const [ReasonsList, setReasonsList] = React.useState([]);

  const handleChangeSelect = (event) => {
    setCancelreasons(event.target.value);
  };

  const { userDetails } = useSelector((state) => state.user);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    // setSelectCancellationType('');
    setCancelreasons('');
    setOpen(false);
  };

  const PlanDetails = async (data) => {
    return CancelSubscription(cid, data);
  };

  const handleSubmit = () => {
    const data = { reasons: Cancelreasons, cancelType: selectCancellationType };
    if (data?.reasons && data?.cancelType) {
      seterror({ show: false, type: 'no' });
      PlanDetails(data);
      handleClose();
    } else {
      seterror({ show: true, type: data?.reasons === '' ? 'reason' : 'Cancellation' });
    }
  };

  const handleChange = (event) => {
    setSelectCancellationType(event.target.value);
  };
  const getCanelcationReasons = async () => {
    setloading(true);
    try {
      const res = await request({
        url: '/dropdowns',
        method: 'GET',
        params: {
          type: ['subscription_cancellation_reasons'],
        },
        token: userDetails?.token,
      });

      const data = res?.data;
      setReasonsList(data?.subscription_cancellation_reasons);
      setloading(false);
    } catch (e) {
      console.error('Error fetching dropdowns: ', e);
      setloading(false);
    }
  };

  React.useEffect(() => {
    getCanelcationReasons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ textAlign: 'center' }}>
      <DarkBtn onClick={handleClickOpen} title={title || 'Cancel Subscription'} />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="card-detail"
      >
        <DialogContent className="cancel-plan-popup">
          <div className="redeem-voucher">
            <div className="voucher-text">Cancel Subscription</div>
          </div>
          <DialogContentText id="alert-dialog-description" className="main-pop-up cancel-plan">
            <div className="cancel">
              <Typography variant="h4">Cancel</Typography>
              <RadioGroup aria-label="Cancel" name="Cancel" value={selectCancellationType} onChange={handleChange}>
                {/* <FormControlLabel value="immediately" control={<Radio />} label="Immediately" /> */}
                <FormControlLabel value="later" control={<Radio />} label="End of the current Period" />
                {error?.show && error?.type === 'Cancellation' && (
                  <FormHelperText className="error">{`Select atleast one ${error?.type}`}</FormHelperText>
                )}
              </RadioGroup>
            </div>
            <div className="canel-reason">
              <p className="reason">Reasons</p>
              {loading ? (
                'loading reasons '
              ) : (
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <Select
                    value={Cancelreasons}
                    onChange={handleChangeSelect}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <MenuItem disabled value="">
                      <em>None</em>
                    </MenuItem>
                    {ReasonsList?.map((reason) => {
                      return (
                        <MenuItem key={reason?.id} value={reason?.title}>
                          {reason?.description}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {error?.show && error?.type === 'reason' && (
                    <FormHelperText className="error">{`Select atleast one ${error?.type}`}</FormHelperText>
                  )}
                </FormControl>
              )}
            </div>
            <div className="cancelbtns">
              <WhiteBtn onClick={handleClose} title="Don't cancel" />
              <DarkBtn onClick={handleSubmit} title="Cancel subscription" />
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CancelPlanDailog;
