import React, { useEffect, useState } from 'react';
import { WithContext as ReactTags } from 'react-tag-input';
import DarkBtn from '../universal/Buttons/DarkBtn';
import notify from '../../utils/notify';
import { notifyMessages } from '../../core/constant';

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

export const TagAddInput = ({ tagKeywords, formik }) => {
  const [tags, setTags] = React.useState([]);
  const [tagValue, setTagValue] = useState('');

  useEffect(() => {
    if (tagKeywords) {
      setTags(tagKeywords);
    }
  }, [tagKeywords]);

  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag) => {
    if (tags.length >= 3) {
      return;
    }
    if (tags.includes(tag.text)) {
      notify(notifyMessages?.uniqueTags, 'error');
      return;
    }
    setTags([...tags, tag.text]);
    setTagValue('');
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  useEffect(() => {
    formik.setFieldValue('tags', tags);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags]);

  const handleTagClick = (index) => {};

  return (
    <div className="taginput">
      <div style={{ position: 'relative' }}>
        <ReactTags
          tags={tags.map((tag) => {
            return { id: tag, text: tag };
          })}
          delimiters={delimiters}
          handleDelete={handleDelete}
          handleAddition={handleAddition}
          handleDrag={handleDrag}
          allowDeleteFromEmptyInput={false}
          autofocus={false}
          allowDragDrop={false}
          name="tags"
          handleInputBlur={formik.handleBlur}
          allowAdditionFromPaste={false}
          handleInputChange={(e) => tags.length < 3 && setTagValue(e)}
          handleTagClick={handleTagClick}
          inputFieldPosition="bottom"
          inputValue={tagValue}
          autocomplete
          allowUnique={false}
          placeholder=""
        />
        <DarkBtn
          title="+"
          disabled={tagValue === ''}
          onClick={() => {
            if (tagValue) {
              const tag = {
                id: tagValue,
                text: tagValue,
              };
              if (tags.length < 3 && !tags.includes(tag.text)) {
                handleAddition(tag);
                setTagValue('');
              } else if (tags.includes(tag.text)) {
                notify(notifyMessages?.uniqueTags, 'error');
              }
            }
          }}
        />
      </div>
      {formik?.touched?.tags && formik?.errors?.tags && <div className="error_text">{formik?.errors?.tags}</div>}
    </div>
  );
};
