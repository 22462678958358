import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { request } from '../../services/request';
import { MyCertificate } from '../my-certificate';

const ViewCertificate = () => {
  const [artworkData, setArtworkData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { userDetails } = useSelector((state) => state.user);

  const params = useParams();
  const location = useLocation();

  const getArtwork = async () => {
    setLoading(true);
    try {
      const res = await request({
        url: `/artist/getCertificate/${params.id}}`,
        method: 'GET',

        token: userDetails?.token,
      });
      setArtworkData(res?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (params.id) {
      getArtwork();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MyCertificate artworkData={artworkData} loading={loading} backBtn={location?.pathname.includes('portfolio')} />
  );
};

export default ViewCertificate;
