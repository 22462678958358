import currentURL from '../env';

export const baseURL = currentURL.currentEnv;
export const message = 'welcome to dashboard ';
export const globalWindow = typeof window !== 'undefined' && window;

export const getScreenSize = () => {
  if (globalWindow.innerWidth <= 450) {
    return 'mobile';
  }
  if (globalWindow.innerWidth <= 820) {
    return 'tablet';
  }
  return 'web';
};

export const getScreenSizeImagDimention = () => {
  if (globalWindow.innerWidth <= 450) {
    return '400x400';
  }
  if (globalWindow.innerWidth <= 768) {
    return '400x400';
  }
  return '500x500';
};

export const truncateMessage = (msg, length) => {
  if (msg?.length <= length) {
    return msg;
  }
  const truncated = msg?.slice(0, length);
  // const lastSpaceIndex = truncated?.lastIndexOf(' ');
  // if (lastSpaceIndex !== -1) {
  //   truncated = truncated?.slice(0, lastSpaceIndex);
  // }
  return `${truncated}...`;
};

export const pageLimitOptions = [
  { name: '12 Results Per Page', id: 12 },
  { name: '24 Results Per Page', id: 24 },
  { name: '36 Results Per Page', id: 36 },
];

export const slugify = (str) =>
  str
    ? str
        .toLowerCase()
        .trim()
        .replace(/\//g, '-')
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/^-+|-+$/g, '')
    : 'title';

export const SiteMapLinks = [
  {
    title: 'General',
    links: [
      { title: 'Home', link: '/', external: true },
      { title: 'About Us', link: '/about-us', external: true },
      { title: 'Contact Us', link: '/contact-us', external: true },
      { title: 'Blog', link: '/blog', external: true },
      { title: 'FAQs', link: '/faqs', external: true },
      { title: 'Investors', link: '/investors', external: true },
      // { title: 'Meet The Team', link: '/our-team', external: true },
    ],
  },
  {
    title: 'Search Categories',
    links: [
      { title: 'Matchmaker', link: '/match-maker', external: true },
      { title: 'Painting', link: '/painting', external: true },
      { title: 'Printmaking', link: '/printmaking', external: true },
      { title: 'Drawing', link: '/drawing', external: true },
      { title: 'Photography', link: '/photography', external: true },
      { title: 'Sculpture', link: '/sculpture', external: true },
      { title: 'Discover', link: '/discover', external: true },
    ],
  },
  {
    title: 'User',
    links: [
      { title: 'Sign Up Artist', link: '/signup', restricted: true, external: false },
      { title: 'Sign Up Buyer', link: '/signup', external: true },
      { title: 'Login', link: '/login', restricted: true, external: false },
      { title: 'Terms & Conditions', link: '/terms-and-conditions', external: false },
      { title: 'Blog', link: '/blog', external: true },
      { title: 'Privacy Policy', link: '/privacy-policy', external: false },
      { title: 'Cookie Policy', link: '/cookie-policy', external: false },
      { title: 'Return Policy', link: '/return-policy', external: false },
    ],
  },
];

export const SideOption = (plan, cardList) => {
  const options = [
    {
      title: 'Dashboard',
      slug: '/dashboard',
      allowed: true,
    },
    {
      title: 'My Account',
      slug: '/my-account/bio',
      type: 'accordion',
      subMenu: [
        {
          title: 'My Bio',
          slug: '/my-account/bio',
          allowed: true,
        },
        {
          title: 'Settings',
          slug: '/my-account/settings',
          allowed: true,
        },
        {
          title: 'Subscription',
          slug: '/my-subscription',
          allowed: true,
        },
        {
          title: 'Subscription History',
          slug: '/my-account/subscription-history',
          allowed: true,
        },
      ],
    },
    {
      title: 'Manage Portfolio',
      slug: '/portfolio/artworks',
      type: 'accordion',
      subMenu: [
        {
          title: 'Artworks',
          slug: '/portfolio/artworks',
          allowed: true,
        },
        {
          title: 'Collections',
          slug: '/portfolio/collections',
          allowed: true,
        },
        {
          title: 'Certificates',
          slug: '/portfolio/certificates',
          allowed: plan?.active_plan?.v_certificate_of_autheticity !== 'No',
        },
      ],
    },
    {
      title: 'Sales',
      slug: '/sales',
      type: 'accordion',
      subMenu: [
        {
          title: 'Orders',
          slug: '/sales/orders',
          allowed: true,
        },
        {
          title: 'Shipping',
          slug: '/sales/shipping',
          allowed: true,
        },
        {
          title: 'Payments',
          slug: '/sales/payments',
          allowed: true,
        },
        {
          title: 'Discount',
          slug: '/sales/discount',
          allowed: true,
        },
        {
          title: 'Reviews',
          slug: '/sales/reviews',
          allowed: true,
        },
      ],
    },
    {
      title: 'Academy',
      slug: '/academy',
      type: 'accordion',
      subMenu: [
        {
          title: 'Pricing Tool',
          slug: '/academy/pricing-tool',
          allowed: plan?.active_plan?.v_pricing_calculator !== 'No',
        },
        {
          title: 'Directory',
          slug: '/academy/directory',
          allowed: true,
        },
        {
          title: 'Video Library',
          slug: '/academy/video-library',
          allowed: true,
        },
        // {
        //   title: 'Mentoring',
        //   slug: '/academy/academy',
        //   allowed: true,
        // },
        // {
        //   title: 'Academy',
        //   slug: '/academy/academy',
        //   allowed: plan?.active_plan?.plan_type === 'paid',
        // },
      ],
    },
    {
      title: 'Community',
      slug: '/community',
      type: 'accordion',
      subMenu: [
        // {
        //   title: 'Meet-Up',
        //   slug: '/community/meet-up',
        //   allowed: true,
        // },
        {
          title: 'Inbox',
          slug: '/community/inbox',
          allowed: true,
        },
        {
          title: 'Followers',
          slug: '/community/followers',
          allowed: true,
        },
        {
          title: 'Referral Scheme',
          slug: '/community/referral-scheme',
          allowed: true,
        },
        // {
        //   title: 'Referral Logs',
        //   slug: '/community/referral-logs',
        //   allowed: true,
        // },
      ],
    },

    {
      title: 'Support',
      slug: '/support',
      allowed: true,
    },
  ];
  cardList?.length > 0 &&
    options[1].subMenu.push({
      title: 'Saved Cards',
      slug: '/my-account/my-card',
      allowed: true,
    });
  if (plan === 'free') {
    return options.filter((option) => option.allowed);
  }
  return options;
};

// dev

// export const stripkey =
//   'pk_test_51MyEQbEg8JGrO0uV5U9jRqQ3KXpOOnLSysXn5Xp2CJTRI4Nkb96nJj7t3uAiFs1lG2qoSh5K5IDXSb1SwDw4VqhK00lHK5q4CA';

// Production
export const stripkey =
  'pk_live_51IG3V2DtlMghTdluBg25i1vs1eGSshqV7tirRQ19eQfOecY6rECU3t4zcoIkxlgMRVQbvZJ0DkUW2V8lKyPqgDhL00MklPSiw2';

/*
 * Quill modules to attach to editor
 */
export const modules = {
  toolbar: {
    container: '#toolbar',
  },
  clipboard: {
    matchVisual: false,
  },
};

/*
 * Quill editor formats
 */
export const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
];

export const EuropeanCountries = [
  { id: 'United Kingdom', name: 'United Kingdom' },
  { id: 'England', name: 'England' },
  { id: 'Wales', name: 'Wales' },
  { id: 'Scotland', name: 'Scotland' },
  { id: 'Northern Ireland', name: 'Northern Ireland' },
];

export const HEADINGS = ['Heading 1', 'Heading 2', 'Heading 3', 'Heading 4', 'Heading 5', 'Heading 6'];

export const notifyMessages = {
  uniqueTags: 'Tags must be unique',
  enterMsg: 'Please enter a message',
  loginSuccess: 'User logged in successfully',
  signupSuccess: 'User registered successfully',
  completePersonalization: 'Please complete the personalization process first',
  fileTooLarge: 'File size should be less than 2MB',
  fileFormat: 'File type should be jpeg, jpg or png',
  primaryFileUploadLimit: 'You can only upload 1 primary image',
  secondaryFileUploadLimit: 'You can upload maximum 3 secondary images',
  profileArtworkMsg: 'Please complete your profile to add artwork',
  profileCollectionMsg: 'Please complete your profile to add artwork collection',
  addArtworkMsg: 'Please add artwork first',
  emptyFieldsMsg: 'Please fill all the fields',
  completeProfileMsg: 'Please complete you seller profile first',
  duplicateErrorMsg: 'You can duplicate one artwork at a time.',
};
