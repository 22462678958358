import { Pagination, PaginationItem } from '@mui/material';
import React from 'react';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import SimpleSelectBox from '../InputTypeText/SelectBox';

const PaginationBox = ({ count, onChange, page, pageLimit, onChangePageLimit, value }) => {
  return (
    <div className="paginationselectbox">
      <Pagination
        count={count || 12}
        variant="outlined"
        shape="rounded"
        page={page}
        onChange={onChange}
        renderItem={item => <PaginationItem slots={{ previous: ArrowLeftIcon, next: ArrowRightIcon }} {...item} />}
      />
      <SimpleSelectBox options={pageLimit} onChange={onChangePageLimit} value={value} />
    </div>
  );
};

export default PaginationBox;
