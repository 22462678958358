import { Container, Grid, Link } from '@mui/material';
import { useSelector } from 'react-redux';
import { SiteMapLinks } from '../../core/constant';

const SiteMap = () => {
  const { userDetails } = useSelector((state) => state.user);
  const usersiteBaseUr = process.env.REACT_APP_USER_APP_URL;
  return (
    <div className="sitemap">
      <Container className="all-screen-container">
        <h2 className="font30px ">Sitemap</h2>
        <Grid container spacing={2} className="outer-border">
          {SiteMapLinks?.map((Category, index) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <Grid key={index} item xs={12} sm={4} className="grid-first">
                <h2 className="font25px heading">{Category?.title}</h2>
                <ul>
                  {Category?.links?.map((siteLinks, i) => {
                    return siteLinks?.restricted && userDetails?.token ? null : (
                      // eslint-disable-next-line react/no-array-index-key
                      <li key={i}>
                        {siteLinks?.external ? (
                          <a
                            target="_blank"
                            href={`${usersiteBaseUr}${siteLinks?.link}`}
                            className="font20px"
                            rel="noreferrer"
                          >
                            {siteLinks?.title}
                          </a>
                        ) : (
                          <Link target="_blank" href={siteLinks?.link} className="Link-text">
                            {siteLinks?.title}
                          </Link>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </div>
  );
};

export default SiteMap;
