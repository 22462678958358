import ClearIcon from '@mui/icons-material/Clear';
import { Grid, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useFormik } from 'formik';

import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import * as Yup from 'yup';
import { notifyMessages } from '../../../core/constant';
import AxiosAll from '../../../services/axiosAll';
import notify from '../../../utils/notify';
import DarkBtn from '../../universal/Buttons/DarkBtn';
import WhiteBtn from '../../universal/Buttons/whiteBtn';

export const ContactNameDialog = ({ orderDetails, ArtLoverData, order }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { userDetails } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const handleClickOpen = () => {
    if (!userDetails?.token) {
      notify(notifyMessages?.loginOrSignup, 'error');
    } else if (order?.v_order_accepted === 'Pending') {
      notify('You must first accept the order.', 'error');
    } else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const initialValues = {
    title: '',
    message: '',
  };

  const validationSchema = Yup.object({
    // title: Yup.string().required('Subject title is required'),
    message: Yup.string().required('Message is required'),
  });

  const onSubmit = async (values) => {
    const data = {
      receiver_id: ArtLoverData?.id,
      subject_title: order?.v_order_item_id ? `Order #${order?.v_order_item_id}` : 'Message from Artist',
      message: values?.message,
    };
    setLoading(true);

    const res = await AxiosAll('post', 'sellerMessages', data, userDetails?.token);
    if (res.data.status === 'Success') {
      notify(res.data.message, 'success');
      formik.resetForm();
      setLoading(false);
      setOpen(false);
      navigate(`/community/messages`);
    } else {
      setLoading(false);
      notify(res.data.message, 'error');
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <div className="collection-btn">
      {/* {orderDetails ? (
        <DarkBtn title="Message Art Lover" onClick={handleClickOpen} />
      ) : (
        <WhiteBtn title={`Contact ${ArtLoverData?.v_fname}`} onClick={handleClickOpen} />
      )} */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="contactartist"
      >
        <DialogContent>
          <Grid container spacing={2} className="icon_section">
            <Grid item xs={10}>
              <div className="font25px dialogHeading">Contact Art Lover</div>
            </Grid>
            <Grid item xs={2}>
              <div
                role="button"
                tabIndex={0}
                className="clear-icon"
                style={{ cursor: 'pointer' }}
                onClick={() => setOpen(false)}
              >
                {' '}
                <ClearIcon />
              </div>
            </Grid>
          </Grid>

          <DialogContentText id="alert-dialog-description">
            {/* <Grid container spacing={2} className="profile_section">
              <Grid item xs={3.5}>
                <div className="profile_img" style={{ position: 'relative' }}>
                  <img
                    loading="eager"
                    src={ArtistData?.v_image ? ArtistData?.v_image : images?.imagePlaceholder}
                    alt=" thumbnail"
                  />
                </div>
              </Grid>
              <Grid item xs={6.5}>
                <div className="artist_name_section">
                  <span className="font25px marbot19px titlecol">{`${ArtistData?.full_name}`}</span>
                  {ArtistData?.seller_reviews_count > 0 && (
                    <div className="artist-rating">
                      <div className="rating-value">
                        {calculateRating(Math.round(ArtistData?.overall_rating))}
                        <span className="star_no">( {ArtistData?.seller_reviews_count} )</span>
                      </div>
                    </div>
                  )}
                  <div className="artist-location">
                    <div style={{ position: 'relative' }} className="map_img">
                      <img loading="eager" src={images.map} alt=" thumbnail" className="map_img" />
                    </div>
                    <span className="font4px boldtext">{ArtistData?.user_city?.v_title}</span>
                  </div>
                </div>
              </Grid>
              {ArtistData?.seller_reviews_count > 0 && (
                <Grid item xs={2}>
                  <div className="rating-position">
                    <div style={{ position: 'relative' }} className="rating-img">
                      <img loading="eager" src={images.ratingSlider} alt=" ratingSlider" className="rating-img" />
                    </div>
                    <div className="artist-number">{Math.round(ArtistData?.overall_rating)}</div>
                  </div>
                </Grid>
              )}
            </Grid> */}
            <form onSubmit={formik?.handleSubmit}>
              {/* <div className="font16px title_text">Subject Title</div>
              <TextFieldInput
                name="title"
                placeholder="Subject Title"
                value={formik.values.title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
              /> */}
              <div className="font16px title_text">Message</div>
              <TextField
                id="outlined-multiline-flexible"
                multiline
                rows={4}
                name="message"
                value={formik.values.message}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.message && Boolean(formik.errors.message)}
                helperText={formik.touched.message && formik.errors.message}
              />
              <div className="btn-section">
                <DarkBtn loading={loading} title="Submit" type="submit" />
              </div>
            </form>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};
