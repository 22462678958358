import { Typography } from '@mui/material';
import { useWindowSize } from '../../../hooks/window';
import { MAX_MOBILE_WIDTH } from '../../../models/breakpoints.model';

export const EmptyText = ({ text }) => {
  const { width } = useWindowSize();
  const isMobile = (width || 0) < MAX_MOBILE_WIDTH;
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        minHeight: '500px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography variant="h3" align="center" fontSize={isMobile && 24}>
        No {text} found...
      </Typography>
    </div>
  );
};
