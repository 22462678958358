import { combineReducers } from 'redux';
import userReducer from './user';
import subscriptionReducer from './subscription';

const rootReducer = combineReducers({
  subscription: subscriptionReducer,
  user: userReducer,
});

export default rootReducer;
