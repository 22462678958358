import { images } from '../../assets';

export const SingleReviewView = ({ reviewDetails }) => {
  const active = {
    border: '1px solid #f5445e',
  };
  return (
    <div className="message-card " style={active}>
      <div className="profile-img">
        <img
          src={reviewDetails?.get_artwork?.artwork_cover_image?.v_image || images.imagePlaceholder}
          width={42}
          height={42}
          alt=""
        />
      </div>
      <div className="reviewr-details">
        <div className="reviewr">
          <h3>{reviewDetails?.get_artwork?.v_title}</h3>
          <h3>{reviewDetails?.v_order_item_id}</h3>
        </div>
        {reviewDetails?.e_review_status === '0' && <p className="review-status">Awaiting Review!</p>}
      </div>
    </div>
  );
};
