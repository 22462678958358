import Box from '@mui/material/Box';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 26,
  borderRadius: 10,
  backgroundColor: '#3E3E3E',
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 10,
    backgroundColor: '#80C86E',
  },
}));

export const CustomizedProgressBars = ({ progress }) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <BorderLinearProgress variant="determinate" value={progress} />
    </Box>
  );
};
