import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

import { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { images } from '../../assets';
import { PlanBtn } from './PlanBtn';
import { PlanCard } from './PlanCard';
import DarkBtn from '../universal/Buttons/DarkBtn';

export const SubcriptionTable = ({ data }) => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const rowsListing = [
      ['No. of Artworks', 'v_no_of_artworks'],
      ['No. of Collections', 'v_no_of_sub_collections'],
      ['Message Collectors', 'v_message_collector'],
      ['Integrated Shipping', 'v_integrated_shipping'],
      ['Pricing Calculator', 'v_pricing_calculator'],
      ['Weekly Community Events', 'weekly_community_events'],
      ['Print on Demand', 'v_print_on_demand'],
      ['Cosimo Academy', 'v_cosimo_academy'],
      ['Analytics Reporting', 'v_analytics_tools'],
      ['Advance Listing Options', 'v_list_multiple_sizes'],
      ['121 Mentoring', '121_Mentoring'],
      ['Certificates of Authenticity', 'v_certificate_of_autheticity'],
      ['Support Response Time', 'v_support_response_time'],

      // ['Personal Portfolio', 'v_personal_portfolio'],
      // ['Display Reviews', 'v_display_reviews'],
      // ['Opportunities Newsletter', 'v_community_newsletters'],
      // ['List Multiple Sizes', 'v_list_multiple_sizes'],
      // ['Partner Discounts', 'v_partner_discounts'],
      // ['Display Badges', 'v_display_badges'],
    ].map(([name, prop]) => {
      const dynamicObject = data?.reduce((acc, item, index) => {
        acc[item?.id] = data?.map((plan) => plan[prop])[index];
        return acc;
      }, {});

      return {
        name,
        ...dynamicObject,
      };
    });

    setRows(rowsListing);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <TableContainer className="subscriptiontable">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          {/* <div className="subscriptionplan">
      {data?.map((plan) => {
        return (
          <PlanCard
            key={plan?.id}
            planTitle={plan?.v_name}
            planHeading={plan?.v_amount}
            paid={plan?.v_amount > 0}
            type={plan?.v_recurring_interval}
          />
        );
      })}
    </div> */}
          <TableBody>
            <TableRow>
              <TableCell> </TableCell>
              {data?.map((plan) => {
                return (
                  <PlanCard
                    key={plan?.id}
                    planTitle={plan?.v_name}
                    planHeading={plan?.v_amount}
                    paid={plan?.v_amount > 0}
                    type={plan?.v_recurring_interval}
                  />
                );
              })}
            </TableRow>
            {rows.map(({ name, ...rest }) => (
              <TableRow key={name}>
                <TableCell key={name} component="th" scope="row">
                  {name}
                </TableCell>
                {Object.values(rest).map((value, index) => (
                  <TableCell key={index} align="center">
                    {value === 'Yes' ? (
                      <img src={images.pinktick} alt={images.pinktick} />
                    ) : value === 'No' || value === '0' || !value ? (
                      '-'
                    ) : value === '100000' ? (
                      'Unlimited'
                    ) : value === 0 ? null : (
                      value
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
        justifyContent="flex-end"
        alignItems="center"
        className="table_last_btn"
      >
        <DarkBtn title="Continue" />
        <DarkBtn title="Select Plan" />
      </Stack> */}
    </>
  );
};
