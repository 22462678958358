import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { images } from '../../../assets';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const InputFileUpload = ({ image, pictureRef, alt, onChange, title }) => {
  return (
    <>
      <Button
        onClick={() => pictureRef?.current?.click()}
        component="label"
        variant="outlined"
        sx={{ flexDirection: 'column' }}
        className="upload-file_new"
      >
        <img src={image || images.uploadFile} alt={alt} className={image ? 'upload_full_img' : 'upload_default_img'} />
        {!image && <span className="font18px profile_text">{title || 'Add Picture'}</span>}
      </Button>
      <VisuallyHiddenInput type="file" ref={pictureRef} accept="image/x-png ,image/jpeg" onChange={onChange} />
    </>
  );
};

export default InputFileUpload;
