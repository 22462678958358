// import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
// import VisibilitySharpIcon from '@mui/icons-material/VisibilitySharp';
import { TextField } from '@mui/material';

const TextFieldInput = ({
  placeholder,
  name,
  value,
  type,
  onChange,
  onBlur,
  error,
  helperText,
  password,
  isPasswordShow,
  setIsPasswordShow,
  ...props
}) => {
  return (
    <div className="textfiledspan">
      <TextField
        id=""
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        error={error}
        type={type || 'text'}
        {...props}
        className="iconoverlap"
      />
      {/* {password && (
        <span
          className="visibility-icon"
          onClick={() => setIsPasswordShow(!isPasswordShow)}
        >
          {isPasswordShow ? <VisibilitySharpIcon /> : <VisibilityOffIcon />}
        </span>
      )} */}
      {error && <div className="error_text">{helperText}</div>}
    </div>
  );
};

export default TextFieldInput;
