import { DELETE_USER, SET_USER, UPDATE_USER, UPDATE_USER_DETAIL } from '../constant';

const initialState = {
  userDetails: {
    token: '',
  },
  userTool: '',
};
// eslint-disable-next-line default-param-last
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return { ...state, userDetails: action.data };
    case UPDATE_USER:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          user: action.data,
        },
      };
    case UPDATE_USER_DETAIL:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          userTool: action.data,
        },
      };
    case DELETE_USER:
      return {
        userDetails: {},
      };
    default:
      return state;
  }
};

export default userReducer;
