import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const SimpleSelectBox = ({ options, onChange, value, defaultValue, defaultOption, ...props }) => {
  return (
    <div className="selectbox">
      <FormControl sx={{ m: 1, minWidth: 80 }} className="selectboxwidth">
        {/* <InputLabel id="demo-simple-select-autowidth-label">Location</InputLabel> */}
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={value}
          onChange={onChange}
          // autoWidth
          // label="Location"
          className="select-field"
          {...props}
        >
          {defaultOption && (
            <MenuItem selected value={defaultValue} disabled>
              {defaultOption}
            </MenuItem>
          )}
          {options?.map((option, index) => {
            return (
              <MenuItem key={index} value={option?.id}>
                {option?.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

export default SimpleSelectBox;
