import { images } from '../../assets';

export const ReviewsChannel = ({ border, selectHandler, reviews, activeCard, leftMar }) => {
  return (
    <div
      className="Review-preview"
      style={{
        cursor: 'pointer',
        marginLeft: leftMar,
      }}
    >
      {reviews.map((order, index) => {
        return (
          <div
            tabIndex={index}
            role="button"
            className="review-card"
            style={
              (activeCard === order?.id && border) || {
                border: '1px solid #000',
              }
            }
            key={index}
            onClick={() => selectHandler(order?.id)}
          >
            <div className="profile-img">
              <img
                src={order?.get_artwork?.artwork_cover_image?.v_image || images.imagePlaceholder}
                alt=""
                width={108}
                height={113}
              />
            </div>
            <div className="review-details">
              <p className="title">{order?.get_artwork?.v_title}</p>
              <p className="order">{order?.v_order_item_id}</p>
              {order?.e_review_status === '0' && <p className="review">Awaiting Review!</p>}

              {order?.e_review_status === '1' && (
                <div className="star">
                  {[...Array(order?.review?.f_overall_happiness)].map((i) => (
                    <img src="/icons/Star.svg" alt="" width={20} height={20} key={i} />
                  ))}
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
