import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import React, { useState } from 'react';
import XIcon from '@mui/icons-material/X';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  HatenaIcon,
  HatenaShareButton,
  InstapaperIcon,
  InstapaperShareButton,
  LineIcon,
  LineShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  LivejournalIcon,
  LivejournalShareButton,
  MailruIcon,
  MailruShareButton,
  PocketIcon,
  PocketShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TumblrIcon,
  TumblrShareButton,
  TwitterIcon,
  TwitterShareButton,
  ViberIcon,
  ViberShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  WorkplaceIcon,
  WorkplaceShareButton,
  // XIcon,
} from 'react-share';
import DarkBtn from '../Buttons/DarkBtn';
import { images } from '../../../assets';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '8px',
  p: 4,
};

const Share = ({ title, url, showSendImage, onClick }) => {
  const [text, setText] = useState('Copy');
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      {!showSendImage ? (
        <DarkBtn onClick={() => onClick(handleOpen)} title={title} styleBtn="shareBtn" />
      ) : (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        <img src={images.send} alt="" className="send" onClick={handleOpen} />
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="sharecard">
          <FacebookShareButton url={url} quote={title} className="share-button" style={{ marginRight: '10px' }}>
            <FacebookIcon size={32} round />
          </FacebookShareButton>
          <FacebookMessengerShareButton url={url} className="share-button" style={{ marginRight: '10px' }}>
            <FacebookMessengerIcon size={32} round />
          </FacebookMessengerShareButton>
          <TwitterShareButton url={url} title={title} className="share-button" style={{ marginRight: '10px' }}>
            <XIcon size={32} round className="xbtn" />
          </TwitterShareButton>

          <TelegramShareButton url={url} title={title} className="share-button" style={{ marginRight: '10px' }}>
            <TelegramIcon size={32} round />
          </TelegramShareButton>

          <WhatsappShareButton
            url={url}
            title={title}
            separator=":: "
            className="share-button"
            style={{ marginRight: '10px' }}
          >
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>

          <LinkedinShareButton url={url} className="share-button" style={{ marginRight: '10px' }}>
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>

          <RedditShareButton
            url={url}
            title={title}
            windowWidth={660}
            windowHeight={460}
            className="share-button"
            style={{ marginRight: '10px' }}
          >
            <RedditIcon size={32} round />
          </RedditShareButton>

          <TumblrShareButton url={url} title={title} className="share-button" style={{ marginRight: '10px' }}>
            <TumblrIcon size={32} round />
          </TumblrShareButton>

          <LivejournalShareButton
            url={url}
            title={title}
            description={url}
            className="share-button"
            style={{ marginRight: '10px' }}
          >
            <LivejournalIcon size={32} round />
          </LivejournalShareButton>

          <MailruShareButton url={url} title={title} className="share-button" style={{ marginRight: '10px' }}>
            <MailruIcon size={32} round />
          </MailruShareButton>

          <EmailShareButton
            url={url}
            subject={title}
            body="body"
            className="share-button"
            style={{ marginRight: '10px' }}
          >
            <EmailIcon size={32} round />
          </EmailShareButton>

          <ViberShareButton url={url} title={title} className="share-button" style={{ marginRight: '10px' }}>
            <ViberIcon size={32} round />
          </ViberShareButton>

          <WorkplaceShareButton url={url} quote={title} className="share-button" style={{ marginRight: '10px' }}>
            <WorkplaceIcon size={32} round />
          </WorkplaceShareButton>

          <LineShareButton url={url} title={title} className="share-button" style={{ marginRight: '10px' }}>
            <LineIcon size={32} round />
          </LineShareButton>

          <PocketShareButton url={url} title={title} className="share-button" style={{ marginRight: '10px' }}>
            <PocketIcon size={32} round />
          </PocketShareButton>

          <InstapaperShareButton url={url} title={title} className="share-button" style={{ marginRight: '10px' }}>
            <InstapaperIcon size={32} round />
          </InstapaperShareButton>

          <HatenaShareButton
            url={url}
            title={title}
            windowWidth={660}
            windowHeight={460}
            className="share-button"
            style={{ marginRight: '10px' }}
          >
            <HatenaIcon size={32} round />
          </HatenaShareButton>
          <div className="shareInput">
            <input
              type="text"
              style={{
                width: '78%',
                marginRight: '10px',
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '5px',
              }}
              disabled
              value={url}
            />
            <button
              type="button"
              style={{
                width: '18%',
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '5px',
                backgroundColor: '#949D8C',
                color: '#fff',
                cursor: 'pointer',
                fontWeight: 'bold',
              }}
              onClick={() => {
                navigator.clipboard.writeText(url);
                setText('Copied');
                setTimeout(() => {
                  setText('Copy');
                }, 1000);
              }}
            >
              {text}
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default Share;
