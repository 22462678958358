import { Typography } from '@mui/material';
import React from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useNavigate } from 'react-router';
import { formatDate } from '../../hooks/formatDate';
import notify from '../../utils/notify';
import CancelPlanDailog from '../CancelPlan';
import DarkBtn from '../universal/Buttons/DarkBtn';
import GrayBtn from '../universal/Buttons/GrayBtn';
import { images } from '../../assets';

export const SubcriptionCard = ({
  planId,
  title,
  stripPrice,
  actualPrice,
  paraDetail,
  prime,
  mostPopular,
  btnTitle,
  currency,
  type,
  isCurrentlyActive,
  PurchaseSubscription,
  CancelSubscription,
  IsCardAdd,
  alreadyHaveActivePlan,
  isCancelRequested,
  isOffer,
  offerId,
  offerDescription,
}) => {
  const navigate = useNavigate();
  const filterDiscription = paraDetail.split(/<br\/>\r?\n?/).filter((item) => item.trim() !== '');
  return (
    <div
      className={`plan-card ${mostPopular && 'most-popular'} ${isOffer && 'add-padding '}  ${
        isCurrentlyActive && 'most-popular'
      } 
         ${actualPrice && 'most-popular'} `}
    >
      {/* <div className="popular">
        <Typography paragraph>Most Popular</Typography>
      </div> */}
      {isOffer ? (
        <div className="popular special-offer-text">
          <Typography paragraph className="top-text">
            Special Offer
          </Typography>

          <Typography paragraph className="bottom-text">
            SAVE {`${currency}${((actualPrice || 0) - (stripPrice > 0 ? stripPrice : actualPrice || 0))?.toFixed(2)}`}
          </Typography>
        </div>
      ) : null}
      <Typography variant="h3">{title}</Typography>
      {stripPrice > 0 ? (
        <>
          <Typography className="strip" variant="h4" style={{ textDecoration: 'line-through' }}>
            {`${currency}${actualPrice}`}
          </Typography>
          <Typography variant="h5" className="free_text_new">
            {stripPrice == 0 ? 'FREE' : `${currency}${stripPrice}`}
            <span style={{ paddingLeft: '5px' }}>{actualPrice == 0 ? '' : `/${type}`}</span>
          </Typography>
        </>
      ) : (
        <Typography variant="h5" className="free_text_new">
          {actualPrice === 0 ? 'FREE' : `${currency}${actualPrice}`} <span>{actualPrice == 0 ? '' : `/${type}`}</span>
        </Typography>
      )}
      <Typography variant="body1" className="artwork_text_new save30px">
        {offerDescription}
      </Typography>
      {/* <Typography className="plans-detail" paragraph dangerouslySetInnerHTML={{ __html: paraDetail }} /> */}
      <div className="plans-detail">
        {filterDiscription?.map((dec, i) => {
          return (
            <div className="check-list" key={i}>
              {/* <CheckCircleOutlineIcon /> */}
              <img src={images.blacktick} alt={images.blacktick} className="blacktick" />
              <Typography variant="body1" className="artwork_text_new">
                {dec}
              </Typography>
            </div>
          );
        })}
      </div>

      {isCurrentlyActive ? (
        actualPrice === 0 ? (
          'Default Active Plan'
        ) : isCurrentlyActive?.i_cancel_at_period_end ? (
          <>
            <Typography className="error">Cancellation Requested</Typography>
            <GrayBtn
              onClick={() => {
                notify(
                  `We have received your cancellation request.\n
           Your plan is set to end on ${formatDate(
             isCurrentlyActive?.v_cancel_at,
           )}, and there will be no future invoices.\n\n Thank you for your patronage.`,
                  'error',
                );
              }}
              title="see more..."
            />
          </>
        ) : (
          <>
            <CancelPlanDailog cid={isCurrentlyActive?.id} CancelSubscription={CancelSubscription} />
            <div className="font10px additional_text">*Additional fees may apply for mentoring sessions</div>
          </>
        )
      ) : actualPrice === 0 ? (
        alreadyHaveActivePlan ? (
          isCancelRequested?.i_cancel_at_period_end ? (
            <Typography className="defualt">Once your current plan ends, you'll switch to this plan.</Typography>
          ) : (
            <div className="plan_panding">
              <CancelPlanDailog title="Continue" cid={isCurrentlyActive?.id} CancelSubscription={CancelSubscription} />
            </div>
            // <CancelPlanDailog title="Switch Plan" cid={isCurrentlyActive?.id} CancelSubscription={CancelSubscription} />
          )
        ) : isCancelRequested?.i_cancel_at_period_end ? (
          <Typography className="defualt">Once your current plan ends, you'll switch to this plan. </Typography>
        ) : (
          <Typography className="defualt">Default Plan</Typography>
        )
      ) : (
        <>
          <DarkBtn
            onClick={() => {
              return alreadyHaveActivePlan
                ? PurchaseSubscription(planId, 'upgrade')
                : // : PurchaseSubscription(planId, 'buy')
                  navigate(
                    isOffer
                      ? `/checkout?subscription-plan=${planId}&method=false&price=${
                          stripPrice > 0 ? stripPrice : actualPrice
                        }&type=${type}&name=${title}&offer=${offerId}`
                      : `/checkout?subscription-plan=${planId}&method=false&price=${
                          stripPrice > 0 ? stripPrice : actualPrice
                        }&type=${type}&name=${title}`,
                  );
            }}
            title={actualPrice === 0 ? 'Continue' : alreadyHaveActivePlan ? 'Select Plan' : 'Select Plan'}
          />
          <div className="font10px additional_text">*Additional fees may apply for mentoring sessions</div>
        </>
      )}
    </div>
  );
};
