export const ChartCard = ({
  children,
  height,
  width,
  borderRadius = '1.25rem',
  paddingX = '2rem',
  paddingY = '1.25rem',
  gap = '2rem',
  bottomMargin = '2rem',
  topMargin = '2rem',
  className,
  dis,
}) => {
  return (
    <div
      className={`chart-card ${className}`}
      style={{
        height,
        width,
        borderRadius,
        paddingLeft: paddingX,
        paddingRight: paddingX,
        paddingBottom: paddingY,
        paddingTop: paddingY,
        gap,
        marginBottom: bottomMargin,
        marginTop: topMargin,
      }}
    >
      {children}
    </div>
  );
};
