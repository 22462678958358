import React, { useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import DarkBtn from '../../components/universal/Buttons/DarkBtn';
import { request } from '../../services/request';
import VimeoPlayer from '../../components/VimeoPlayer';

const VideoLibrary = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [nextLesson, setNextLesson] = useState();
  const [previousLesson, setPreviousLesson] = useState();
  const [lessons, setLesson] = useState([]);
  const { subscriptionDetails } = useSelector((state) => state?.subscription);
  const plan = subscriptionDetails?.active_plan;
  const { userDetails } = useSelector((state) => state.user);

  const getLesson = async () => {
    try {
      setLoading(true);
      const res = await request({
        url: `/artist/academy/lessons/${id}`,
        method: 'GET',
        token: userDetails?.token,
      });
      setLesson(res?.data?.lesson);
      setPreviousLesson(res?.data?.previous_lesson);
      setNextLesson(res?.data?.next_lesson);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const VideoEnd = async () => {
    try {
      setLoading(true);
      const res = await request({
        url: `/artist/academy/lessons/${id}`,
        method: 'PUT',

        token: userDetails?.token,
      });
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getLesson();
    }
  }, [id]);

  const handleVideoEnd = () => {
    // Display notification
    VideoEnd();
  };

  return (
    <div>
      <div className="video-library-section">
        <Typography
          variant="body1"
          className="font20pxtypo"
          sx={{ my: 4, textAlign: 'center', cursor: 'pointer', textDecoration: 'underline' }}
          onClick={() => navigate('/academy/video-library')}
        >
          Back to Overview
        </Typography>
        <Stack
          direction={{ xs: 'row', sm: 'row' }}
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
          className="next-previous-text"
        >
          {plan?.plan_type === 'paid' ? (
            <>
              {previousLesson?.id ? (
                <DarkBtn title="Previous" onClick={() => navigate(`/academy/video-lesson/${previousLesson?.id}`)} />
              ) : (
                <div />
              )}

              {nextLesson?.id ? (
                <DarkBtn title="Next" onClick={() => navigate(`/academy/video-lesson/${nextLesson?.id}`)} />
              ) : (
                <div />
              )}
            </>
          ) : (
            <>
              {previousLesson?.id && previousLesson?.accessibility === 'Free' ? (
                <DarkBtn title="Previous" onClick={() => navigate(`/academy/video-lesson/${previousLesson?.id}`)} />
              ) : (
                <div />
              )}
              {nextLesson?.id && nextLesson?.accessibility === 'Free' ? (
                <DarkBtn title="Next" onClick={() => navigate(`/academy/video-lesson/${nextLesson?.id}`)} />
              ) : (
                <div />
              )}
            </>
          )}
        </Stack>
        <Box className="iframe_box last_lessons">
          <VimeoPlayer className="react_play_inner" videoURl={lessons?.vimeo_url} VideoEnd={VideoEnd} />
          <div className="font25px video_title_text">{lessons?.title}</div>
        </Box>
        <Box className="iframe_box">
          <div className="font25px lesson_text">Lesson Overview</div>
          <p className="font16px">{lessons?.description}</p>
        </Box>

        <Box className="iframe_box">
          <div className="font25px ">Resources</div>
          {lessons?.attachments?.map((item, index) => {
            return (
              <Stack
                key={index}
                direction={{ xs: 'row', sm: 'row' }}
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
                className="download_btn_section"
              >
                <p className="font16px">{item?.attachment_title}</p>
                <DarkBtn title="Download" onClick={() => window.open(item?.attachment_url)} />
              </Stack>
            );
          })}
        </Box>
      </div>
    </div>
  );
};

export default VideoLibrary;
